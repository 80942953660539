import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PublicFolder from "../../apis/PublicFolder";

// Icons
import {
  BsThreeDotsVertical,
  BsArrowLeft,
  BsArrowLeftRight,
} from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { FcInspection } from "react-icons/fc";
import { MdChangeCircle, MdOutlineQrCode } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";

// Import your custom components, contexts, etc.
import PopupWeb from "../Popup/PopupNew";
import InventoryFinder from "../../apis/InventoryFinder";
import { ItemPageContext } from "../../context/ItemPageContext";
import { useAuth } from "../../context/AuthContext";
import { usePageTitle } from "../Navbar/PageTitleContext";
import { useMainItemPageData } from "./fetchItemPageData";
import ItemPageInspectionTask from "./ItemPageInspectionTaskNew";
import ItemPageHistory from "./ItemPageHistory";
import ScannerButton from "../Scanner/ScannerButton";

import SelectionModal from "../Popup/SelectionModal";

/**
 * Inline ActionsDropdown component
 */
function ActionsDropdown({
  onChangeStatus,
  onAssign,
  onInspect,
  onUpdate,
  onQR,
  onInspectFreq,
  onDelete,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const toggleDropdown = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  // Closes the dropdown after an action is clicked
  const handleAction = (actionFn) => {
    if (actionFn) actionFn(); // call the passed-in function
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left ">
      <button
        onClick={toggleDropdown}
        className="inline-flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
      >
        Actions
        <BsThreeDotsVertical className="ml-2" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-44 bg-white border border-gray-200 rounded shadow-lg z-5">
          <ul className="py-1 text-sm text-gray-700">
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => handleAction(onChangeStatus)}
              >
                <MdChangeCircle className="inline mr-2" /> Change Status
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => handleAction(onAssign)}
              >
                <BsArrowLeftRight className="inline mr-2" /> Assign/Move
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => handleAction(onInspect)}
              >
                <FcInspection className="inline mr-2" /> Inspect
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => navigate(`/inventory/${id}/itempage/edit`)}
                >
                <BiEdit className="inline mr-2" /> Update
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => handleAction(onQR)}
              >
                <MdOutlineQrCode className="inline mr-2" /> QR
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                onClick={() => handleAction(onInspectFreq)}
              >
                <BiEdit className="inline mr-2" /> Inspect Freq
              </button>
            </li>
            <li>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                onClick={() => handleAction(onDelete)}
              >
                Delete
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

/**
 * Main Page Component
 */
function ItemPageNewLayout() {
  // React Router
  const { id } = useParams();
  const navigate = useNavigate();

  // Page Title
  const { setPageTitle } = usePageTitle();

  // Auth context
  const {
    currentUserId,
    currentUserSiteId,
    currentFirstName,
    currentLastName,
  } = useAuth();

  // Item page context
  const {
    setEventList,
    setItemUsage,
    itemInfo,
    setItemInfo,
    setItemInspection,
    setItemId,
    setItemInspectionAlerts,
    setItemHistory,
    setItemScheduledEvent,
    inspectionOpen,
    setInspectionOpen,
    apparatusList,
    setApparatusList,
    itemTags,
    setItemTags,
    itemPersonal,
    setItemPersonal,
    itemArea,
    setItemArea,
  } = useContext(ItemPageContext);

  // Data fetch
  const { data, refetch } = useMainItemPageData(currentUserSiteId, id);

  // Local states
  const [loading, setLoading] = useState(false);
  const [bags, setBags] = useState([]);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isMemebrsModalOpen, setIsMemebrsModalOpen] = useState(false);
  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);
  const [isBagsModalOpen, setIsBagsModalOpen] = useState(false);
  const [isTagsModalOpenNew, setIsTagsModalOpenNew] = useState(false);
  const [isFreqModalOpenNew, setIsFreqModalOpenNew] = useState(false);

  // Fields
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedIdentificationName, setSelectedIdentificationName] =
    useState("");
  const [newSelectedIdentificationName, setNewSelectedIdentificationName] =
    useState("");
  const [itemName, setItemName] = useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusNew, setSelectedStatusNew] = useState("");

  const [selectedExpirationDate, setSelectedExpirationDate] = useState("");
  const [selectedManufactureDate, setSelectedManufactureDate] = useState("");
  const [selectedHydrostaticDate, setSelectedHydrostaticDate] = useState("");
  const [oldItemAmount, setOldItemAmount] = useState("");
  const [invid, setInvid] = useState("");
  const [itemDesciption, setItemDesciption] = useState("");
  const [selectedFirstUseDate, setSelectedFirstUseDate] = useState();
  const [size, setSize] = useState("");
  const [model, setModel] = useState("");

  const [itemManufcture, setItemManufcture] = useState("");

  const [memberList, setMemberList] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [addItemTags, setAddItemTags] = useState([]);
  const [currentItemTags, setCurrentItemTags] = useState([]);

  const [allFrequency, setAllFrequency] = useState([]);
  const [addFreq, setAddFreq] = useState("");

  // Popups
  const [isOpen3, setIsOpen3] = useState(false); // Edit item
  const [isOpenQR, setIsOpenQR] = useState(false); // QR code
  const [PopupDelete, setPopupDelete] = useState(false); // Delete
  const [itemStatusPopup, setItemStatusPopup] = useState(false); // Change status
  const [isItemMoveOpen, setIsItemMoveOpen] = useState(false); // Move item
  const [isInspectionFreqOpen, setIsInspectionFreqOpen] = useState(false);

  // Move item form states
  const [selectedCompartmentId, setSelectedCompartmentId] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");
  const [selectedApparatusId, setSelectedApparatusId] = useState("");
  const [addMember, setAddMember] = useState("");
  const [errors, setErrors] = useState({});

  const [logText, setLogText] = useState("");

  // On data load
  useEffect(() => {
    if (data) {
      const info = data.data.data.itemInfo;
      const [first] = info;

      // Basic item details
      setItemInfo(info);
      setItemId(id);
      setInvid(first.invid);
      setSelectedAmount(first.amount);
      setOldItemAmount(first.amount);
      setSelectedIdentificationName(first.identificationname);
      setItemDesciption(first.description);
      setNewSelectedIdentificationName(first.identificationname);
      setSelectedSerialNumber(first.serialnumber || "");
      setSelectedStatus(first.status);
      setSelectedStatusNew(first.status);
      setItemName(first.itemname);
      setSize(first.size);
      setModel(first.model);
      setItemManufcture(first.manufacture);
      setEventList(data.data.data.itemEventList);
      setItemHistory(data.data.data.itemHistory);
      setItemScheduledEvent(data.data.data.itemScheduledEvent);
      setItemInspection(data.data.data.itemInspection);
      setItemInspectionAlerts(data.data.data.alertdetailsandcomment);
      setApparatusList(data.data.data.apparatusListNew);
      setItemUsage(data.data.data.itemUsage);
      setMemberList(data.data.data.memberList);
      setBags(data.data.data.bagsListNew);
      setAllTags(data.data.data.allTags);
      setAllFrequency(data.data.data.allFrequency);

      setItemPersonal(data.data.data.itemPersonal);
      setItemArea(data.data.data.itemArea);
      // Tags
      setAddItemTags(data.data.data.itemTags);
      setCurrentItemTags(data.data.data.itemTags);
      // Format dates
      if (first.manufacturedate) {
        setSelectedManufactureDate(
          new Date(first.manufacturedate).toISOString().substring(0, 10)
        );
      }
      if (first.expirationdate) {
        setSelectedExpirationDate(
          new Date(first.expirationdate).toISOString().substring(0, 10)
        );
      }
      if (first.mandatoryinspectiondate) {
        setSelectedHydrostaticDate(
          new Date(first.mandatoryinspectiondate).toISOString().substring(0, 10)
        );
      }
      if (first.dateoffirstuse) {
        setSelectedFirstUseDate(
          new Date(first.dateoffirstuse).toISOString().substring(0, 10)
        );
      }
      // Page title
      setPageTitle(first.identificationname || first.itemname);
    }
    // eslint-disable-next-line
  }, [data]);

  // If your code has "created by" / "modified by" in itemInfo:
  let createdBy = "";
  let createdDate = "";
  let modifiedBy = "";
  let modifiedDate = "";
  if (itemInfo && itemInfo[0]) {
    createdBy = itemInfo[0].createdbyname || "";
    createdDate = itemInfo[0].createdtime || "";
    modifiedBy = itemInfo[0].modifiedbyname || "";
    modifiedDate = itemInfo[0].modifiedtime || "";
  }
  const defaultTagSelection = currentItemTags.map((item) => item.value);
  
  const correctedAddItemTags = addItemTags.map((tag) =>
    typeof tag === "object" ? tag.value : tag
  );

  // Toggle popups
  const editItemPopupClose = () => setIsOpen3(!isOpen3);
  const addPopupQR = () => setIsOpenQR(!isOpenQR);
  const toggleDelete = () => setPopupDelete(!PopupDelete);
  const toggleDelete2 = () => setPopupDelete(!PopupDelete);
  const toggleItemStatus = () => setItemStatusPopup(!itemStatusPopup);
  const toggleItemStatus2 = () => setItemStatusPopup(!itemStatusPopup);
  const toggleItemStatus3 = () => setInspectionOpen(!inspectionOpen);
  const toggleItemMove = () => {
    setIsItemMoveOpen(!isItemMoveOpen);
    setAddMember("");
    setSelectedBagId("");
    setSelectedApparatusId("");
  };

  const toggleItemMove2 = () => {
    setIsItemMoveOpen(!isItemMoveOpen);
    setAddMember("");
    setSelectedBagId("");
    setSelectedApparatusId("");
    setSelectedCompartmentId("");
  };

  const mainInspectionFreqPopup = () =>
    setIsInspectionFreqOpen(!isInspectionFreqOpen);
  const editItemPopup = () => setIsOpen3(!isOpen3);

  // Validate "Move item" form
  const validateForm = () => {
    const newErrors = {};
    if (!addMember && !selectedCompartmentId && !selectedBagId) {
      newErrors.general = "Please select at least one option";
    } else if (
      (addMember && selectedCompartmentId) ||
      (addMember && selectedBagId) ||
      (selectedCompartmentId && selectedBagId)
    ) {
      newErrors.general = "Please select only one option";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearForm = () => setErrors({});

  // Handlers
  const handleAddMemberChange = (e) => {
    setAddMember(e ? e.value : "");
    setSelectedCompartmentId("");
    setSelectedBagId("");
    setErrors({});
  };
  const handleCompartmentChange = (e) => {
    setAddMember("");
    setSelectedCompartmentId(e ? e.value : "");
    setSelectedBagId("");
    setErrors({});
  };
  const handleBagChange = (e) => {
    setAddMember("");
    setSelectedCompartmentId("");
    setSelectedBagId(e ? e.value : "");
    setErrors({});
  };

  // === API calls ===
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await InventoryFinder.post(`/editItem`, {
        itemid: id,
        selectedAmount,
        oldItemAmount,
        invid,
        size,
        model,
        itemManufcture,
        selectedIdentificationName: newSelectedIdentificationName,
        selectedSerialNumber:
          selectedSerialNumber === "" ? null : selectedSerialNumber,
        selectedManufactureDate: selectedManufactureDate || null,
        selectedExpirationDate: selectedExpirationDate || null,
        selectedHydrostaticDate: selectedHydrostaticDate || null,
        selectedFirstUseDate: selectedFirstUseDate || null,

        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addItemTags,
      });
      setLoading(false);

      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      refetch();
      editItemPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      await InventoryFinder.post(`/moveItem`, {
        selectedCompartmentId,
        selectedBagId,
        invid,
        itemid: id,
        currentUserId,
        selectedApparatusId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addMember,
      });
      setLoading(false);
      refetch();
      toggleItemMove();
      clearForm();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await InventoryFinder.post(`/deleteItem`, {
        itemid: id,
        oldItemAmount,
        invid,
        currentUserId,
        currentUserSiteId,
      });
      navigate(`/inventory`);
    } catch (err) {}
  };

  const handleStatusSubmit = async (e) => {
    e.preventDefault();
    try {
      await InventoryFinder.post(`/changeItemStatus`, {
        itemid: id,
        selectedStatusNew,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      refetch();
      toggleItemStatus();
    } catch (err) {}
  };

  const handleOptionChange = (val) => {
    setSelectedStatus(val.target.value);
  };

  const handleUnassign = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await InventoryFinder.post(`/unassignItem`, {
        invid,
        itemid: id,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addMember,
      });
      setLoading(false);
      refetch();
      toggleItemMove();
      clearForm();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFreqSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await InventoryFinder.post(`/updateItemFreq`, {
        isCheck: [id],
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      refetch();
      mainInspectionFreqPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  // Build select options
  const options = memberList.map((d) => ({
    value: d.user_id,
    label: d.firstname + " " + d.lastname,
    id: d.user_id,
  }));

  const optionsApparatus = apparatusList.map((d) => ({
    label: d.name,
    options: d.compartments.map((c) => ({
      value: c.cid,
      label: c.compartmentname,
    })),
  }));
  const flatOptionsApparatus = optionsApparatus.flatMap(
    (group) => group.options
  );
  const flatOptions = optionsApparatus.flatMap((group) =>
    group.options
      .filter((opt) => opt.value !== null && opt.label !== null)
      .map((opt) => ({
        label: `${group.label} - ${opt.label}`, // include group label for clarity
        value: opt.value,
      }))
  );
  const optionsBags = bags.map((b) => ({
    label: b.name,
    options: b.bags.map((b2) => ({
      value: b2.bagid,
      label: b2.bagname,
    })),
  }));
  const flatOptionsBags = optionsBags.flatMap((group) => group.options);

  const flatOptionsBags2 = optionsBags.flatMap((group) =>
    group.options
      .filter((opt) => opt.value !== null && opt.label !== null)
      .map((opt) => ({
        label: `${group.label} - ${opt.label}`, // include group label for clarity
        value: opt.value,
      }))
  );

  const optionsAllTags = allTags.map((t) => ({
    value: t.categoryid,
    label: t.name,
  }));
  const optionsTags = (itemTags || []).map((t) => ({
    value: t.value,
    label: t.label,
  }));

  const optionsFreq = allFrequency.map((f) => ({
    value: f.id,
    label: f.name,
  }));

  // For the example, let's assume a priority level = 2
  const priorityLevel = 2;
  const statusLabel = selectedStatus || "N/A";

  const handleSaveLog = async () => {
    if (!logText.trim()) return; // avoid saving empty logs
    setLoading(true);
    try {
      await InventoryFinder.post(`/itemLogUpdate`, {
        invid,
        itemid: id,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        comment: logText,
        type: "Log", // or any type/category your backend expects
      });

      // Optionally, update your log history by triggering a refresh
      setLogText(""); // clear textarea after saving
      refetch();
    } catch (error) {
      console.error("Failed to save log:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-2 mx-auto">
      {/* TOP BAR */}
      <div className="flex items-center justify-between mb-4">
        <button
          className="flex items-center text-gray-600 hover:text-gray-800"
          onClick={() => navigate("/inventory")}
          >
          <BsArrowLeft className="mr-2" />
          Back
        </button>

        {/* Actions Dropdown */}
        <ActionsDropdown
          onChangeStatus={toggleItemStatus2}
          onAssign={toggleItemMove2}
          onInspect={toggleItemStatus3}
          onUpdate={editItemPopup}
          onQR={addPopupQR}
          onInspectFreq={mainInspectionFreqPopup}
          onDelete={toggleDelete}
        />
      </div>

      {/* HEADER INFO */}
      <div className="border-b pb-4 mb-4 text-center">
        {/* Image on top, centered */}
        {itemInfo &&
          itemInfo[0] &&
          itemInfo[0].image &&
          itemInfo[0].image !== "0" && (
            <img
              src={PublicFolder.baseURL + "preview_" + itemInfo[0].image}
              alt={itemInfo[0].itemname}
              className="mx-auto w-48 h-48 object-cover rounded-md shadow-md mb-4"
            />
          )}

        {/* Item name and subheading */}
        <h1 className="text-2xl font-bold">
          {itemName}
          {selectedIdentificationName && (
            <span className="text-gray-500 text-lg ml-2">
              {selectedIdentificationName}
            </span>
          )}
        </h1>
        <span className="text-gray-500 text-sm">
          {itemManufcture}
          {itemDesciption ? " - " + itemDesciption : ""}
        </span>

        {/* Status, Created by, Modified by */}
        <div className="flex flex-wrap justify-center items-center gap-4 mt-2">
          <div className="flex items-center">
            <span className="mr-2 font-semibold">Status</span>
            <span
              className={`inline-block px-2 py-1 rounded text-sm ${
                statusLabel === "Safe to use" || statusLabel === "Online"
                  ? "bg-green-100 text-green-800"
                  : statusLabel === "Not safe to use"
                  ? "bg-red-100 text-red-800"
                  : statusLabel === "Monitor"
                  ? "bg-yellow-100 text-yellow-800"
                  : "bg-gray-100 text-gray-600"
              }`}
            >
              {statusLabel}
            </span>
          </div>

          <div className="text-sm text-gray-500">
            <span className="mr-4">
              Created by {createdBy} : {createdDate}
            </span>
            <br className="block sm:hidden" />
            <span>
              Modified by {modifiedBy} : {modifiedDate}
            </span>
          </div>
        </div>
      </div>
      {/* TABS 
      <Tabs>
        <TabList className="flex space-x-8 border-b mb-4 text-gray-600">
          <Tab className="cursor-pointer pb-2 hover:text-gray-800 focus:outline-none">
            General
          </Tab>
          <Tab className="cursor-pointer pb-2 hover:text-gray-800 focus:outline-none">
            History
          </Tab>
        </TabList>

        <TabPanel>*/}
      {/* Full-Width Layout (no side column) */}
      <div className="space-y-4">
        {/* General Info */}
        <div className="p-4 bg-white rounded-md shadow">
          <h2 className="text-lg font-semibold mb-2">General Info</h2>
          {itemInfo && itemInfo[0] && (
            <div>
              <p>Serial: {itemInfo[0].serialnumber}</p>
              <p>Amount: {itemInfo[0].amount}</p>
              <p>Model: {itemInfo[0].model}</p>
              <p>Size: {itemInfo[0].size}</p>
              <p>Manufacturer Date: {itemInfo[0].manufacturedate}</p>
              <p>Date of First Use: {itemInfo[0].dateoffirstuse}</p>

              <p>Expiration Date: {itemInfo[0].expirationdate}</p>
              <p>Mandatory Service: {itemInfo[0].mandatoryinspectiondate}</p>
              <p>Inspection Result: {itemInfo[0].inspectionresult}</p>
              <p>Inspection Date: {itemInfo[0].inspectiondate}</p>

              <p>Next Inspection date: {itemInfo[0].nextinspectiondate}</p>
              <p>Inspection Frequency: {itemInfo[0].inspectionfreq}</p>
            </div>
          )}
        </div>

        {/* Assigned To */}
        <div className="p-4 bg-white rounded-md shadow">
          <h2 className="text-lg font-semibold mb-2">Assigned To</h2>

          {itemArea?.length > 0 || itemPersonal?.length > 0 ? (
            <ul className="list-disc pl-6">
              {itemArea.map((a, idx) => (
                <li key={idx}>
                  {a.apparatusname} {a.name ? `- ${a.name}` : ""}
                  {a.bagname ? ` - ${a.bagname}` : ""}
                </li>
              ))}
              {itemPersonal.map((p, idx) => (
                <li key={idx}>
                  {p.firstname} {p.lastname}
                </li>
              ))}
            </ul>
          ) : (
            <p>Not Assigned</p>
          )}
        </div>

        {/* Tags */}
        <div className="p-4 bg-white rounded-md shadow">
          <h2 className="text-lg font-semibold mb-2">Tags</h2>
          {currentItemTags && currentItemTags.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {currentItemTags.map((tag, i) => {
                const label = typeof tag === "string" ? tag : tag.label;
                return (
                  <span
                    key={i}
                    className="px-2 py-1 rounded bg-gray-200 text-sm"
                  >
                    {label}
                  </span>
                );
              })}
            </div>
          ) : (
            <p>No tags</p>
          )}
        </div>

        {/* Log Section */}
        <div className="bg-white p-6 rounded-md shadow mt-4">
          <h2 className="text-xl font-semibold border-b pb-2 mb-4">Log</h2>
          <textarea
            className="w-full border p-2 text-sm rounded"
            placeholder="Add a comment..."
            value={logText}
            onChange={(e) => setLogText(e.target.value)}
          />
          <button
            onClick={handleSaveLog}
            disabled={loading}
            className="px-3 py-1 bg-blue-600 text-white rounded mt-2"
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <ItemPageHistory />
        </div>
      </div>
      {/* </TabPanel>

       History Tab 
        <TabPanel>
          <ItemPageHistory />
        </TabPanel>
      </Tabs>*/}

      {/* POPUPS */}
      {/* EDIT ITEM */}
      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdate}>
                <div className="popupContainer">
                  {itemInfo &&
                    itemInfo.map((data, i) => {
                      return (
                        <div key={i} className="popupContainer space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Tags
                            </label>
                            <div
                              onClick={() => {
                                setIsTagsModalOpenNew(true);
                                setIsOpen3(false);
                              }}
                              className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                            >
                              {correctedAddItemTags.length
                                ? correctedAddItemTags
                                    .map((val) => {
                                      const foundOption = optionsAllTags.find(
                                        (o) => String(o.value) === String(val)
                                      );
                                      return foundOption
                                        ? foundOption.label
                                        : val;
                                    })
                                    .join(", ")
                                : "Select Tags"}
                            </div>
                          </div>
                          {/* Identification Name */}
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Identification Name
                            </label>
                            <div className="relative mt-1">
                              <input
                                type="text"
                                value={selectedIdentificationName}
                                onChange={(e) =>
                                  setSelectedIdentificationName(e.target.value)
                                }
                                className="w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                              />
                              {/* <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ScannerButton
                                  onScan={(value) =>
                                    setSelectedIdentificationName(value)
                                  }
                                />
                              </div>*/}
                            </div>
                          </div>

                          {/* Amount */}
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Amount
                            </label>
                            <input
                              type="number"
                              value={selectedAmount}
                              onChange={(e) =>
                                setSelectedAmount(e.target.value)
                              }
                              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Serial Number
                            </label>
                            <div className="relative mt-1">
                              <input
                                type="text"
                                value={selectedSerialNumber}
                                onChange={(e) =>
                                  setSelectedSerialNumber(e.target.value)
                                }
                                className="w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                              />
                              {/*    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ScannerButton
                                  onScan={(value) =>
                                    setSelectedSerialNumber(value)
                                  }
                                />
                              </div>*/}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Size
                            </label>
                            <input
                              type="text"
                              value={size}
                              onChange={(e) => setSize(e.target.value)}
                              placeholder="Enter Size"
                              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                            />
                          </div>

                          {/* Manufacture & Date Fields */}
                          {[
                            {
                              label: "Manufacture",
                              state: itemManufcture,
                              setter: setItemManufcture,
                            },
                            {
                              label: "Model",
                              state: model,
                              setter: setModel,
                            },
                            {
                              label: "Manufacture Date",
                              state: selectedManufactureDate,
                              setter: setSelectedManufactureDate,
                              type: "date",
                            },
                            {
                              label: "Date of First Use",
                              state: selectedFirstUseDate,
                              setter: setSelectedFirstUseDate,
                              type: "date",
                            },
                            {
                              label: "Mandatory Service Date",
                              state: selectedHydrostaticDate,
                              setter: setSelectedHydrostaticDate,
                              type: "date",
                            },
                            {
                              label: "Retirement Date",
                              state: selectedExpirationDate,
                              setter: setSelectedExpirationDate,
                              type: "date",
                            },
                          ].map(({ label, state, setter, type = "text" }) => (
                            <div key={label} className="mb-4">
                              <label className="block text-sm font-medium text-gray-700">
                                {label}
                              </label>
                              <input
                                type={type}
                                value={state}
                                onChange={(e) => setter(e.target.value)}
                                placeholder={
                                  type === "date" ? "Select date" : ""
                                }
                                className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                              />
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
              </form>
            </>
          }
          handleClose={editItemPopupClose}
          isOpen={isOpen3}
          handleSubmit={handleUpdate}
          showSubmitButton={true}
          headerText={<b>Edit Item Details</b>}
          submitButtonText={loading ? "Loading..." : "Update"}
        />
      )}

      <SelectionModal
        isOpen={isTagsModalOpenNew}
        onClose={() => {
          setIsTagsModalOpenNew(false);
          setIsOpen3(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setAddItemTags(tags);
          setIsTagsModalOpenNew(false);
         // setIsOpen3(true); // Reopen the parent popup
        }}
        options={optionsAllTags.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Tags"
        multiple
        defaultValue={defaultTagSelection}
      />

      {/* QR POPUP */}
      {isOpenQR && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn">
                    {/*`${process.env.REACT_APP_Site_URL}/equipment?itemId=${id}`*/}
                    {selectedSerialNumber}
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={addPopupQR}
          isOpen={isOpenQR}
          showSubmitButton={false}
          headerText="QR Code Link"
        />
      )}

      {PopupDelete && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>This item will no longer be available</b>
              </div>
              <div className="deleteInfoApparatusContainer">
                <b>
                  THIS WILL PERMANENTLY DELETE THE ITEM AND REMOVE ALL HISTORY
                </b>
              </div>
            </>
          }
          handleClose={toggleDelete2}
          isOpen={PopupDelete}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={<b>Delete - {itemName} </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} // Optionally change the text to indicate loading
        />
      )}

      {/* STATUS POPUP */}
      {itemStatusPopup && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleStatusSubmit}>
                <div className="uniquePopupContainer">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Status
                    </label>
                    <div
                      onClick={() => {
                        setIsStatusModalOpen(true);
                        setItemStatusPopup(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {selectedStatusNew || "Select Status"}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={toggleItemStatus}
          isOpen={itemStatusPopup}
          handleSubmit={handleStatusSubmit}
          showSubmitButton={true}
          headerText={<b>Set Status</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        />
      )}

      <SelectionModal
        isOpen={isStatusModalOpen}
        onClose={() => {
          setIsStatusModalOpen(false);
          setItemStatusPopup(true); // Reopen the parent popup
        }}
        onConfirm={(selection) => {
          setSelectedStatusNew(
            typeof selection === "string" ? selection : selection.label
          );
          setIsStatusModalOpen(false);
        //  setItemStatusPopup(true); // Reopen the parent popup
        }}
        options={[
          "Safe to use",
          "Monitor",
          "Not safe to use",
          "Missing",
          "Retired",
        ]}
        label="Select Status"
        defaultValue={selectedStatusNew}
      />

      {/* MOVE ITEM POPUP */}
      {isItemMoveOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>
                <div className="popupContainer space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Members
                    </label>
                    <div
                      onClick={() => {
                        setIsMemebrsModalOpen(true);
                        setIsItemMoveOpen(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {addMember
                        ? options.find((opt) => opt.value === addMember)
                            ?.label || "Add Member"
                        : "Add Member"}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <div
                      onClick={() => {
                        setIsLocationsModalOpen(true);
                        setIsItemMoveOpen(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {selectedCompartmentId
                        ? flatOptionsApparatus.find(
                            (opt) => opt.value === selectedCompartmentId
                          )?.label || "Add Location"
                        : "Add Location"}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Bags
                    </label>
                    <div
                      onClick={() => {
                        setIsBagsModalOpen(true);
                        setIsItemMoveOpen(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {selectedBagId
                        ? flatOptionsBags.find(
                            (opt) => opt.value === selectedBagId
                          )?.label || "Add Location"
                        : "Add Location"}
                    </div>
                  </div>
                </div>

                {errors.general && (
                  <div className="errorInputLabel">{errors.general}</div>
                )}

                <div className="popup-unassign-container mt-4">
                  <button className="unassign-button" onClick={handleUnassign}>
                    Unassign
                  </button>
                </div>
              </form>
            </>
          }
          handleClose={toggleItemMove}
          isOpen={isItemMoveOpen}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b>Reassign Item</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
          loading={loading}
        />
      )}

      <SelectionModal
        isOpen={isMemebrsModalOpen}
        onClose={() => {
          setIsMemebrsModalOpen(false);
          setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setAddMember(tags);
          setSelectedBagId("");
          setSelectedCompartmentId("");

          setIsMemebrsModalOpen(false);
         // setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Member"
        defaultValue={addMember}
      />

      <SelectionModal
        isOpen={isLocationsModalOpen}
        onClose={() => {
          setIsLocationsModalOpen(false);
          setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setSelectedCompartmentId(tags);
          setSelectedBagId("");
          setAddMember("");
          setIsLocationsModalOpen(false);
        //  setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        options={flatOptions}
        label="Select Location"
        defaultValue={selectedCompartmentId}
      />

      <SelectionModal
        isOpen={isBagsModalOpen}
        onClose={() => {
          setIsBagsModalOpen(false);
          setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setSelectedBagId(tags);
          setSelectedCompartmentId("");
          setAddMember("");

          setIsBagsModalOpen(false);
      //    setIsItemMoveOpen(true); // Reopen the parent popup
        }}
        options={flatOptionsBags2}
        label="Select Bag"
        defaultValue={selectedBagId}
      />

      {/* INSPECTION FREQ POPUP */}
      {isInspectionFreqOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleFreqSubmit}>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Inspection Frequency
                  </label>
                  <div
                    onClick={() => {
                      setIsFreqModalOpenNew(true);
                      setIsInspectionFreqOpen(false);
                    }}
                    className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                  >
                    {addFreq
                      ? optionsFreq.find((opt) => opt.value === addFreq)
                          ?.label || "Add Location"
                      : "Add Frequency"}
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={mainInspectionFreqPopup}
          isOpen={isInspectionFreqOpen}
          handleSubmit={handleFreqSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        />
      )}

      <SelectionModal
        isOpen={isFreqModalOpenNew}
        onClose={() => {
          setIsFreqModalOpenNew(false);
          setIsInspectionFreqOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setAddFreq(tags);
          setIsFreqModalOpenNew(false);
      //    setIsInspectionFreqOpen(true); // Reopen the parent popup
        }}
        options={optionsFreq.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Inspection Frequency"
        defaultValue={addFreq}
      />

      {/* INSPECTION TASK DRAWER */}
      {inspectionOpen && <ItemPageInspectionTask />}
    </div>
  );
}

export default ItemPageNewLayout;
