import React, { useRef, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

import UserFinder from "../../apis/UserFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";
import PublicFolder from "../../apis/PublicFolder";

import { useNavigate } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";

import ClipLoader from "react-spinners/ClipLoader";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

import { BsThreeDotsVertical } from "react-icons/bs";
const DefaultItem = () => {
  //Popup
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);

  const [defaultList, setDefaultList] = useState([]);

  const headers = {
    "content-type": "multipart/form-data",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/getDefaultListItems`);
        setDefaultList(response.data.data.defaultItemList);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useNavigate();

  //
  //
  const [error, setError] = useState("");
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };
  //
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState();

  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();

      formData.set("name", name);
      formData.set("manufacture", manufacture);
      formData.set("description", description);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }

      const response = await UserFinder.post(
        "/newDefaultInventory",
        formData,
        headers
      );

      setLoading(false);
      setError("");

      const response2 = await UserFinder.get(`/getDefaultListItems`);
      setDefaultList(response2.data.data.defaultItemList);
      //  // console.log(response.data.data.checklist);
      togglePopup();
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };

  const handleUpdateChecklistSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("manufacture", manufacture);
      formData.append("description", description);
      formData.append("id", upadteId);
  
      // Check if the user removed the image.
      if (selectedImage === null) {
        // Append a flag to indicate removal.
        formData.append("removeImage", "true");
      } else if (selectedImage instanceof File) {
        // A new image is provided.
        formData.append("file", selectedImage);
      }
      
      await UserFinder.post("/updateDefaultInventory", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      setLoading(false);
      const response = await UserFinder.get(`/getDefaultListItems`);
      setDefaultList(response.data.data.defaultItemList);
      togglePopup5();
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("Failed");
    }
  };
  
  //const handleNewChecklistPush = (id) => {
  //   history(`/editchecklist/${id}`);
  // };

  const [name, setDefaultListName] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [description, setDescription] = useState("");
  const [upadteId, setUpadteId] = useState("");
  const [image, setImage] = useState("");

  const fetchData3 = async (id, name, image, manufacture, description) => {
    try {
      console.log(image);
      togglePopup5();
      setDefaultListName(name);
      setUpadteId(id);
      setManufacture(manufacture);
      setDescription(description);
      // Use the image passed as a parameter
      setSelectedImage(image);
    } catch (err) {
      // Handle error if necessary
    }
  };

  const sideBar = document.getElementById("sidebar");

  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setDefaultListName("");
    setManufacture("");
    setDescription("");    setSelectedImage("");

  };

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
  };

  const [deleteFrequencyId, setDelteFrequencytId] = useState("");

  const deleteChecklist = async (e) => {
    try {
      // eslint-disable-next-line
      const respon32se = await UserFinder.post("/delteDefaultInventory", {
        id: deleteFrequencyId,
        image
      });

      const response = await UserFinder.get(`/getDefaultListItems`);
      setDefaultList(response.data.data.defaultItemList);
      togglePopup222();
    } catch (err) {
      //   console.log(err);
    }
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = (clid, image) => {
    setIsOpen2(true);
    setIsOpen(false);
    setDelteFrequencytId(clid);
    setImage(image)
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
    setDelteFrequencytId("");
  };
  const togglePopup222 = () => {
    setIsOpen2(false);
  };

  return (
    <>
   
      <button className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"  onClick={togglePopup}>
              
              Add Item 
              </button>

      <div className="checklistListHolder">
        <div className="inventoryList">
          <div className="ReportResultsContainer">
            <div className="reportsPopupContainer">
              {defaultList ? (
                <>
                  <table id="reportsTable">
                    <tbody>
                      <tr>
                        <th>Image</th>

                        <th>Name</th>
                        <th>Manufacture</th>
                        <th>Description</th>
                        <th></th>
                      </tr>
                      {defaultList.map((data, i) => {
                        console.log(data);
                        return (
                          <tr key={i}>
                            <td>
                              <div className="min-max-width">
                                <img
                                  src={
                                    PublicFolder.baseURL +
                                    "preview_" +
                                    data.image
                                  }
                                  alt="Did Not Load"
                                  className="InventoryTableImg"
                                />
                              </div>
                            </td>

                            <td
                              onClick={() =>
                                fetchData3(
                                  data.id,
                                  data.name,
                                  data.image,
                                  data.manufacture,
                                  data.description
                                )
                              }
                            >
                              {data.name}
                            </td>
                            <td>{data.manufacture}</td>
                            <td>{data.description}</td>
                            <td>
                              <div onClick={() => togglePopup2(data.id, data.image)}>
                                <BsThreeDotsVertical />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewChecklistSubmit}>


                <div>
                  <div>
                    {error && <div className="alert">{error}</div>}

                    <div className="popupContainer">
                      <label className="NewInputLabel" data-domain="Name">
                        <input
                          className="NewInput"
                          type="text"
                          placeholder=""
                          value={name}
                          onChange={(e) => setDefaultListName(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        className="NewInputLabel"
                        data-domain="manufacture"
                      >
                        <input
                          className="NewInput"
                          type="text"
                          placeholder=""
                          value={manufacture}
                          onChange={(e) => setManufacture(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        className="NewInputLabel"
                        data-domain="description"
                      >
                        <input
                          className="NewInput"
                          type="text"
                          placeholder=""
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                      </label>

                      <div className="NewInputLabel" data-domain="Add Image">
                        <input
                          ref={fileRef}
                          onChange={handleFileInput}
                          type="file"
                          accept="image/*"
                          hidden
                        />
                        <div
                          onClick={() => fileRef.current.click()}
                          className="NewInputImage"
                        >
                          <RiImageAddFill size={30} />
                        </div>
                        <div className="imagePreviewContainer">
                          {selectedImage && (
                            <div className="imageHolder">
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="imageDidNotLoad"
                                className="addImage"
                              />
                              <button
                                type="button"
                                className="removeImageButton"
                                onClick={removehandleFileInput}
                              >
                                <RiCloseFill size={20} />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleNewChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>New Item</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdateChecklistSubmit}>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={name}
                        onChange={(e) => setDefaultListName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="manufacture">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={manufacture}
                        onChange={(e) => setManufacture(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="description">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </label>

                    <div className="NewInputLabel" data-domain="Add Image">
                      <input
                        ref={fileRef}
                        onChange={handleFileInput}
                        type="file"
                        accept="image/*"
                        hidden
                      />
                      <div
                        onClick={() => fileRef.current.click()}
                        className="NewInputImage"
                      >
                        <RiImageAddFill size={30} />
                      </div>
                      <div className="imagePreviewContainer">
  {selectedImage && (
    <div className="imageHolder">
      <img
        src={
          typeof selectedImage === "object"
            ? URL.createObjectURL(selectedImage)
            : PublicFolder.baseURL + "preview_" + selectedImage
        }
        alt="imageDidNotLoad"
        className="addImage"
      />
      <button
        type="button"
        className="removeImageButton"
        onClick={removehandleFileInput}
      >
        <RiCloseFill size={20} />
      </button>
    </div>
  )}
</div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={handleUpdateChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>Update Item</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete this Frequency?</b>
              </div>

              {/*<div className="deleteInfoApparatusContainer">
                <b>
                  THIS CURRENTLY WILL ONLY DELETE THE location AND NOT
                  COMPARTMENTS OR ITEMS
                </b>
          </div>*/}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup222}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={deleteChecklist}
                //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup22}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={<b>Delete Frequency </b>}
        />
      )}
    </>
  );
};

export default DefaultItem;
