import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import ChecklistFinder from "../../apis/ChecklistFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import Select from "react-select";

import ClipLoader from "react-spinners/ClipLoader";
import { AiOutlineMenu } from "react-icons/ai";

import { Reorder } from "framer-motion";
import { BsThreeDotsVertical } from "react-icons/bs";

import ToastNotifier, { notify } from "../Popup/ToastNotifier";

const ChecklistMainPage = () => {
  //Popup
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);

  //
  //
  const [tags, setTags] = useState([]);

  const [checklist, setChecklist] = useState([]);
  const [selectedItemTags, setSelectedItemTags] = useState("");
  const [selectedItemTagId, setSelectedItemTagId] = useState("");
  const [selectedItemTagName, setSelectedItemTagName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get(`/allChecklist`, {
          params: {
            currentUserSiteId,
          },
        });
        setTags(response.data.data.tags);
        setChecklist(response.data.data.checklist);
      } catch (err) {
        // // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  //
  const [error, setError] = useState("");

  //
  const [checklistName, setChecklistName] = useState("");
  const [appliesTo, setAppliesTo] = useState("");

  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/addchecklist", {
        checklistName,
        selectedApparatus: id,
        currentUserSiteId,
        inputFields,
        appliesTo,
        selectedItemTags: selectedItemTags.value,
      });
      setLoading(false);

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
      togglePopup();
      notify("Checklist created successfully!", "success"); // Show success notification
    } catch (err) {
      // // console.log(err);
      setLoading(false);
      notify("Failed to created Checklist. Please try again.", "error"); // Show error notification
    }
  };

  const handleUpdateChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/updatechecklist", {
        checklistName,
        selectedApparatus: id,
        currentUserSiteId,
        inputFields,
        clid: updateClid,
        appliesTo,
        selectedItemTags: selectedItemTags.value,
      });
      setLoading(false);

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
      togglePopup5();
      notify("Checklist updated successfully!", "success"); // Show success notification
    } catch (err) {
      // // console.log(err);
      setLoading(false);
      notify("Failed to updated Checklist. Please try again.", "error"); // Show error notification
    }
  };

  // Polyfill for generating UUID
  function generateUUID() {
    if (
      typeof crypto !== "undefined" &&
      typeof crypto.randomUUID === "function"
    ) {
      return crypto.randomUUID();
    } else if (
      typeof crypto !== "undefined" &&
      typeof crypto.getRandomValues === "function"
    ) {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    } else {
      // Fallback for environments without crypto API
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ ((Math.random() * 16) >> (c / 4))).toString(16)
      );
    }
  }

  const sideBar = document.getElementById("sidebar");

  const [inputFields, setInputFields] = useState([
    { Title: "", Desc: "", id: generateUUID() },
  ]);

  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setInputFields([{ Title: "", Desc: "", id: generateUUID() }]);
    setChecklistName("");
    setAppliesTo("");
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { Title: "", Desc: "", id: generateUUID() };

    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const [updateClid, setUpadteClid] = useState(false);

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
  };

  const fetchData3 = async (clid, name, tagid, tagname) => {
    try {
      // // // console.log(apparatusId);
      const response = await ChecklistFinder.get("/checklistGetSteps", {
        params: {
          clid,
          currentUserSiteId,
        },
      });

      setInputFields(
        response.data.data.checklistSteps.map(function (e) {
          return {
            Title: e.taskname,
            Desc: e.taskdescription,
            id: generateUUID(),
          };
        })
      );
      // console.log(tagid);
      togglePopup5();
      setChecklistName(name);
      setUpadteClid(clid);
      setSelectedItemTagId(tagid);
      setSelectedItemTagName(tagname);
      tagid === null
        ? setAppliesTo({ value: "Any Item", label: "Any Item" })
        : setAppliesTo({ value: "Tag", label: "Tag" });
    } catch (err) {
      // // console.log(err);
    }
  };

  let taglist = tags.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
    };
  });

  const options = [
    { value: "Any Item", label: "Any Item" },
    { value: "Tag", label: "Tag" },
  ];

  const [deleteChecklistId, setDelteChecklistId] = useState("");

  const deleteChecklist = async (e) => {
    try {
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/delteChecklist", {
        clid: deleteChecklistId,
        currentUserSiteId,
      });

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
      togglePopup222();
      notify("Checklist deleted successfully!", "success"); // Show success notification
    } catch (err) {
      // // console.log(err);
      setLoading(false);
      notify("Failed to delete Checklist. Please try again.", "error"); // Show error notification
    }
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = (clid) => {
    setIsOpen2(true);
    setIsOpen(false);
    setDelteChecklistId(clid);
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
    setDelteChecklistId("");
  };
  const togglePopup222 = () => {
    setIsOpen2(false);
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  return (
    <>
      <div className=" top-[64px] bg-white border-b shadow-sm">
        <div className="filter-search-toggle-container">
          <div className={`filter-search-container`}>
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopup}
            >
              New Checklist
            </button>
          </div>
        </div>
      </div>

      <div className="detailsPopupContainer222"></div>

      <div className="checklistListHolder">
        <div className="inventoryList">
          <div className="ReportResultsContainer">
            <div className="reportsPopupContainer">
              {checklist ? (
                <>
                  <table id="reportsTable">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Any Item</th>
                        <th>Tags</th>
                        <th></th>
                      </tr>
                      {checklist.map((data, i) => {
                        // console.log(data)
                        return (
                          <tr key={i}>
                            <td
                              onClick={() =>
                                fetchData3(
                                  data.clid,
                                  data.checklistname,
                                  data.itemtagid,
                                  data.tagname
                                )
                              }
                            >
                              {data.checklistname}
                            </td>
                            <td>{data.allitems === true ? "True" : ""}</td>
                            <td>{data.tagname}</td>
                            <td>
                              <div onClick={() => togglePopup2(data.clid)}>
                                <BsThreeDotsVertical />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewChecklistSubmit}>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={checklistName}
                        onChange={(e) => setChecklistName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Added To">
                      <Select
                        options={options}
                        onChange={setAppliesTo}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        required
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>

                    {appliesTo.value === "Tag" ? (
                      <label className="NewInputLabel" data-domain="Tags">
                        <Select
                          //   isMulti
                          options={taglist}
                          onChange={setSelectedItemTags}
                          name="addMembers"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </label>
                    ) : (
                      ""
                    )}
                    <Reorder.Group
                      values={inputFields}
                      onReorder={setInputFields}
                    >
                      {inputFields.map((item, i) => (
                        // Change the li to Reorder.Item and add value prop
                        <Reorder.Item key={item.id} value={item}>
                          <div className="ChecklistStepHolder">
                            <div className="ChecklistStepContainer">
                              <div className="ChecklistStepOrder">
                                {" "}
                                Step #{i + 1}{" "}
                              </div>
                              <div
                                className="ChecklistStepOrderRemove"
                                onClick={() => removeFields(item.id)}
                              >
                                Remove
                              </div>
                            </div>

                            <div key={i}>
                              <label
                                className="NewInputLabel"
                                data-domain="Title"
                              >
                                <input
                                  className="NewInput"
                                  type="input"
                                  placeholder="Title"
                                  name="Title"
                                  value={item.Title}
                                  required
                                  onChange={(event) =>
                                    handleFormChange(i, event)
                                  }
                                />
                              </label>
                              <label
                                className="NewInputLabel"
                                data-domain="Description"
                              >
                                <input
                                  className="NewInput"
                                  type="input"
                                  placeholder="Description"
                                  name="Desc"
                                  value={item.Desc}
                                  onChange={(event) =>
                                    handleFormChange(i, event)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </Reorder.Item>
                      ))}
                    </Reorder.Group>

                    <div className="ChecklistAddStep" onClick={addFields}>
                      + Add Step
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleNewChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>New Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading
        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdateChecklistSubmit}>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={checklistName}
                        onChange={(e) => setChecklistName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Added To">
                      <Select
                        required
                        options={options}
                        onChange={setAppliesTo}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={
                          selectedItemTagName === null
                            ? {
                                label: "Any Item",
                                value: "Any Item",
                              }
                            : {
                                label: "Tag",
                                value: "Tag",
                              }
                        }
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>
                    {appliesTo.value === "Tag" ? (
                      <label className="NewInputLabel" data-domain="Tags">
                        <Select
                          //   isMulti
                          options={taglist}
                          onChange={setSelectedItemTags}
                          name="addMembers"
                          className="basic-multi-select"
                          required
                          classNamePrefix="select"
                          defaultValue={{
                            label: selectedItemTagName,
                            value: selectedItemTagId,
                          }}
                        />
                      </label>
                    ) : (
                      ""
                    )}
                    <Reorder.Group
                      values={inputFields}
                      onReorder={setInputFields}
                    >
                      {inputFields.map((item, i) => (
                        // Change the li to Reorder.Item and add value prop
                        <Reorder.Item key={item.id} value={item}>
                          <div className="ChecklistStepHolder">
                            <div className="ChecklistStepContainer">
                              <div className="ChecklistStepOrder">
                                {" "}
                                Step #{i + 1}{" "}
                              </div>
                              <div
                                className="ChecklistStepOrderRemove"
                                onClick={() => removeFields(item.id)}
                              >
                                Remove
                              </div>
                            </div>
                            <div key={i}>
                              <label
                                className="NewInputLabel"
                                data-domain="Title"
                              >
                                <input
                                  className="NewInput"
                                  type="input"
                                  placeholder="Title"
                                  name="Title"
                                  value={item.Title}
                                  required
                                  onChange={(event) =>
                                    handleFormChange(i, event)
                                  }
                                />
                              </label>
                              <label
                                className="NewInputLabel"
                                data-domain="Description"
                              >
                                <input
                                  className="NewInput"
                                  type="input"
                                  placeholder="Description"
                                  name="Desc"
                                  value={item.Desc}
                                  onChange={(event) =>
                                    handleFormChange(i, event)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </Reorder.Item>
                      ))}
                    </Reorder.Group>

                    <div className="ChecklistAddStep" onClick={addFields}>
                      + Add Step
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={handleUpdateChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>Update Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Save"} // Optionally change the text to indicate loading
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete this checklist?</b>
              </div>

              {/*<div className="deleteInfoApparatusContainer">
                <b>
                  THIS CURRENTLY WILL ONLY DELETE THE location AND NOT
                  COMPARTMENTS OR ITEMS
                </b>
          </div>*/}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup222}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={deleteChecklist}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup22}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={<b>Delete Checklist </b>}
        />
      )}

      <ToastNotifier />
    </>
  );
};

export default ChecklistMainPage;
