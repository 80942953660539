// src/pages/NewEventPage.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import TrainingFinder from "../../apis/TrainingFinder";
import DynamicForm from "../Popup/DynamicForm";
import SelectionModal from "../Popup/SelectionModal";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import ClipLoader from "react-spinners/ClipLoader";
import { usePageTitle } from "../Navbar/PageTitleContext";
import { useAuth } from "../../context/AuthContext";

const NewEventPage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { currentUserSiteId } = useAuth();
  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Set page title
  const { setPageTitle } = usePageTitle("");
  setPageTitle("Create New Event");

  // State to store fetched category options (transformed into {value, label})
  const [categoryOptions, setCategoryOptions] = useState([]);

  // Static modal options for status.
  const modalOptions = {
    status: [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
    ],
  };

  // Fully controlled form data.
  // For category, we store the label in formData and keep the full object in selectedCategory.
  const [formData, setFormData] = useState({
    eventName: "",
    eventDescription: "",
    eventAddress: "",
    category: "", // will display the label from selectedCategory
    status: "",
    newDate: "",
  });

  // State to store the entire category object.
  const [selectedCategory, setSelectedCategory] = useState(null);

  // When selectedCategory changes, update formData.category with its label.
  useEffect(() => {
    if (selectedCategory) {
      setFormData((prev) => ({ ...prev, category: selectedCategory.label }));
    }
  }, [selectedCategory]);

  // activeModal holds the field name currently using a modal (e.g., "category" or "status")
  const [activeModal, setActiveModal] = useState(null);

  // Field configuration for DynamicForm.
  const fields = [
    { name: "eventName", label: "Event Name", required: true,  placeholder: "Enter event name" },
    { name: "eventDescription", label: "Event Description", inputType: "textarea", placeholder: "Enter event description" },
    { name: "eventAddress", label: "Address", placeholder: "Enter address" },
    { name: "category", label: "Category", isModal: true, required: true, placeholder: "Select Category" },
    { name: "status", label: "Status", isModal: true, required: true, placeholder: "Select Status" },
    { name: "newDate", label: "Date", inputType: "date", required: true, placeholder: "Select date" },
  ];

  // Fetch category options from your API.
  useEffect(() => {
    const fetchEventInfo = async () => {
      setLoading(true);
      try {
        const response = await TrainingFinder.get(`/newEventInfo`, {
          params: { currentUserSiteId },
        });
        if (!response.data.data.categoryResults) {
          navigate("/notFound");
          return;
        }
        // Transform each category into { value, label } using categoryid and name.
        const transformedCategories = response.data.data.categoryResults.map((cat) => ({
          value: cat.categoryid,
          label: cat.name,
        }));
        setCategoryOptions(transformedCategories);
      } catch (err) {
        notify("Failed to fetch event info", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchEventInfo();
  }, [navigate, currentUserSiteId]);

  // Callback for non-modal fields.
  const onChangeField = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  // Callback when a modal field is clicked.
  const onOpenModal = (fieldName) => {
   // console.log("Opening modal for:", fieldName);
    setActiveModal(fieldName);
  };

  // When the user selects an option from the modal.
  const handleModalConfirm = (selected) => {
    console.log("selected",selected)
    if (activeModal === "category") {
      // If only a value is returned, look it up in categoryOptions.
      if (typeof selected === "string") {
        const foundCategory = categoryOptions.find(
          (cat) => String(cat.value) === String(selected)
        );

        console.log("foundCategory",foundCategory)

        setSelectedCategory(foundCategory);
      } else {
        setSelectedCategory(selected);
      }
    } else {
      // For other modal fields, update formData directly.
      const value = typeof selected === "string" ? selected : selected.label;
      setFormData((prev) => ({ ...prev, [activeModal]: value }));
    }
    setActiveModal(null);
  };

  // Handle form submission.
  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    try {
      if (!formData.eventName) {
        setErrors((prev) => ({ ...prev, eventName: "Event name is required" }));
        setLoading(false);
        return;
      }
      if (!formData.category) {
        setErrors((prev) => ({ ...prev, category: "Category is required" }));
        setLoading(false);
        return;
      }
      if (!formData.status) {
        setErrors((prev) => ({ ...prev, status: "Status is required" }));
        setLoading(false);
        return;
      }
      if (!formData.newDate) {
        setErrors((prev) => ({ ...prev, newDate: "Date is required" }));
        setLoading(false);
        return;
      }
    //  await TrainingFinder.post("/newtraingevent", dataForm);
    const response = await TrainingFinder.post("/newtraingevent", {
        eventName: formData.eventName,
        eventDescription: formData.eventDescription,
        eventAddress: formData.eventAddress,
        category: selectedCategory ? selectedCategory.value : "",
        date: formData.newDate,
        status: formData.status,
        currentUserSiteId,
      });
      notify("Event created successfully!", "success");
      navigate(`/eventinfo/${response.data.data.training.id}`);
    } catch (err) {
      notify("Failed to create event. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/events");
  };

  return (
    <div className="p-4">
      <ToastNotifier />
      <div className="border p-4 rounded">
        <DynamicForm
          fields={fields}
          formData={formData}
          onChangeField={onChangeField}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          onOpenModal={onOpenModal}
          loading={loading}
          fullScreen={isMobile}
        />
      </div>
      {activeModal && (
        <SelectionModal
          isOpen={true}
          onClose={() => setActiveModal(null)}
          onConfirm={handleModalConfirm}
          options={
            activeModal === "category"
              ? categoryOptions
              : modalOptions[activeModal] || []
          }
          label={`Select ${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`}
        />
      )}

<div className="sticky bottom-0 pb-5 bg-white border-t p-4 flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={handleSubmit}
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
    </div>
  );
};

export default NewEventPage;
