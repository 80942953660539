import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ApparatusChecklist from "../../apis/ApparatusChecklist";
import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import { IoMdArrowRoundBack } from "react-icons/io";
import { usePageTitle } from "../Navbar/PageTitleContext";

const ApparatusChecklistHeader = () => {
  const { currentUserSiteId, currentUserRole } = useAuth();

  const { id } = useParams();
  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => setIsOpen(!isOpen1);

  const [checklist, setChecklist] = useState("");
  const [checklistInProgress, setChecklistInProgress] = useState("");
  const { setPageTitle } = usePageTitle();

  const [name, setName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApparatusChecklist.get(`/all${id}`, {
          params: {
            currentUserSiteId,
          },
        });
        setChecklist(response.data.data.checklistInfo);
        setChecklistInProgress(response.data.data.checklistInProgress);
        setName(response.data.data.apparatusInfo[0].name);

        setPageTitle(response.data.data.apparatusInfo[0].name + " / Checklist");
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewChecklist2 = async (clid, checklistname) => {
    const response2 = await ApparatusChecklist.post(`/start`, {
      lclid: clid,
      checklistname,
      apparatusId: id,
      currentUserSiteId,
    });
    handleChecklistInProgress(clid, response2.data.data.checklistinprogressid);
  };

  const handleChecklistInProgress = (clid, checklistinprogressid) => {
    history({
      pathname: `/location/${checklistinprogressid}/checklistinprogress`,
      state: {
        checklistInProgress: checklistinprogressid,
        apparatusid: id,
        currentUserSiteId,
      },
    });
  };

  const handleSubmitedChecklist = () => {
    history({
      pathname: `/location/${id}/submitedchecklist`,
    });
  };

  const handleBack = () => {
    history(`/location`);
  };

  const handleEditChecklist = () => {
    history(`/checklist/${id}`);
  };

  let history = useNavigate();

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      {/* <div className="apparatusMenu">
        <div
          className="apparatusInventoryLink"
          onClick={() => handleApparatus()}
        >
          Inventory
        </div>

        <div
          className="apparatusChecklistLink"
          onClick={() => handleApparatusChecklist()}
        >
          Checklist
        </div>
      </div>
  */}

      <div className="silderHolder">
        <div>
          <div className="apparatusChecklistpageHeader">
            <b>Scheduled Checklist</b>
          </div>
          <div className="inProgressContainerHead">
            {checklistInProgress.length > 0 ? (
              checklistInProgress.map((data, i) => {
                //  console.log(data)
                return (
                  <div key={i}>
                    <div className="inProgressContainer">
                      <div
                        className="inProgressName"
                        onClick={() => {
                          handleChecklistInProgress(
                            data.lclid,
                            data.submitedchecklistid
                          );
                        }}
                      >
                        <div className="inProgressChecklistNameHolder">
                          <div className="inProgressChecklistName">
                            {data.checklistname}
                          </div>
                          <div className="inProgressChecklistDate">
                            {data.startdate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="trainingEmptyContainer">
                  <div className="trainingEmptyContainer2">
                    <b>No scheduled checks at this time</b>
                  </div>     <div className="inProgressContainer">
              <div
                className="inProgressName"
                onClick={() => {
                  togglePopup();
                }}
              >
                <div className="inProgressChecklistNameHolder">
                  <div className="inProgressChecklistName">
                    Start New Checklist
                  </div>
                </div>
              </div>
            </div>
                </div>
              </>
            )}
            <div className="inProgressContainer">
              <div
                className="inProgressName"
                onClick={() => {
                  togglePopup();
                }}
              >
                <div className="inProgressChecklistNameHolder">
                  <div className="inProgressChecklistName">
                    Start New Checklist
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <button
                type="submit"
                className="submitItemBtn2"
                onClick={() => {
                  handleSubmitedChecklist();
                }}
              >
                History
              </button>

              <div className="popupBtnContainers">
                {checklist &&
                  checklist.map((data, i) => {
                    return (
                      <div key={i}>
                        <div
                          className="upcomingEventContainer21"
                          onClick={() => {
                            handleNewChecklist2(data.lclid, data.checklistname);
                          }}
                        >
                          <div className="upcomingEventDate21">
                            <b> {data.checklistname}</b>
                          </div>
                          <div className="upcomingEventName21">
                            <b>{data.scheduleddate}</b>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          headerText={<b>Start A New Checklist</b>}
          showSubmitButton={false}
        />
      )}
    </>
  );
};

export default ApparatusChecklistHeader;
