// ScannerButton.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import ScannerFinder from "../../apis/ScannerFinder";

const ScannerButton = ({ onScan, type = 'QR' }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const scannerRef = useRef(null);

  // Configuration for QR vs. barcodes
  const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
  const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopScanner();
    };
  }, []);

  // Start/stop scanner when modal toggles
  useEffect(() => {
    if (showScanner && !isScanning) {
      startScanner();
    } else if (!showScanner && isScanning) {
      stopScanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScanner]);

  const startScanner = async () => {
    if (isScanning) return; // Prevent double-start

    if (!scannerRef.current) {
      // Initialize the scanner on the "reader" div
      scannerRef.current = new Html5Qrcode('reader');
    }

    try {
      const config = type === 'QR' ? qrConfig : brConfig;
  //    console.log('Starting scanner...');
      await scannerRef.current.start(
        { facingMode: 'environment' },
        config,
        async (decodedText) => {
   //       console.log('Scanned:', decodedText);

          // Stop scanning right away to avoid multiple scans of the same code
          await stopScanner();

          // Pass the scanned value back to the parent
          if (onScan) onScan(decodedText);

          // Close the modal
          handleClose();
        }
      );
//      console.log('Scanner started.');
      setIsScanning(true);
    } catch (err) {
 //     console.error('Error starting scanner:', err);
    }
  };

  const stopScanner = async () => {
    if (!isScanning || !scannerRef.current) return;

    try {
      console.log('Stopping scanner...');
      await scannerRef.current.stop();
      await scannerRef.current.clear();
      console.log('Scanner stopped and cleared.');
    } catch (err) {
      console.error('Error stopping scanner:', err);
    }

    setIsScanning(false);
  };

  const handleClose = () => {
    setShowScanner(false);
  };



  
  return (
    <div className="inline-block">
      {/* The main button to open the scanner modal */}
      <button
  type="button"
  onClick={() => setShowScanner(true)}
  className="
    bg-blue-600 hover:bg-blue-700
    text-white text-sm
    px-3 py-1
    rounded
    focus:outline-none focus:ring-1 focus:ring-blue-500
  "
>
  Scan
</button>

      {/* Modal Overlay */}
      {showScanner && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="relative bg-white rounded-lg shadow-lg max-w-sm w-full p-6">
            {/* Close (X) button in the top-right corner */}
            <button
              onClick={handleClose}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Title */}
            <h2 className="text-xl font-bold mb-2 text-center">Scan Code</h2>

            {/* Optional instructions */}
            <p className="text-sm text-gray-600 mb-4 text-center">
              Position the QR or barcode within the frame below.
            </p>

            {/* Scanner container */}
            <div
              id="reader"
              className="mx-auto border-2 border-gray-300 w-[300px] h-[300px] rounded-md overflow-hidden"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScannerButton;
