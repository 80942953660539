import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ChecklistFinder from "../../apis/ChecklistFinder";
import PopupWeb from "../Popup/PopupNew";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPencilAlt, FaRegListAlt } from "react-icons/fa";

const ChecklistLocationPage = () => {
  // -------------------
  // Basic Setup & State
  // -------------------
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();
  const history = useNavigate();
  const [compartments, setCompartments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [checklist, setChecklist] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [checklistName, setChecklistName] = useState("");
  const [appliesTo, setAppliesTo] = useState("");

  const [inventoryInfo, setInventoryInfo] = useState([]);

  const [selectedSelectedLocatioName, setSelectedSelectedLocatioName] =
    useState("");
  const [selectedSelectedLocatioId, setSelectedSelectedLocatioId] =
    useState("");

  // For new checklist steps
  const [inputFields, setInputFields] = useState([
    { Title: "", Desc: "", id: generateUUID() },
  ]);

  // For editing checklist
  const [updateClid, setUpadteClid] = useState("");

  // For Task Groups & Tasks
  const [addTaskGroupName, setTaskGroupName] = useState("");
  const [addTaskGroupId, setTaskGroupId] = useState("");
  const [editAllItems, setEditAllItems] = useState("");
  const [addTaskGroupdDisplayOrder, setTaskGroupDisplayOrder] = useState("");
  const [linkedCompartment, setLinkedCompartment] = useState("");
  const [taskGroup, setTaskGroup] = useState([]);
  const [task, setTask] = useState([]);

  // For tasks (both adding and editing)
  const [addTaskName, setTaskName] = useState("");
  const [taskDesciption, setTaskDesciption] = useState("");
  const [currentTaskGroupId, setCurrentTaskGroupId] = useState("");
  const [currentCid, setCurrentCid] = useState("");
  const [itemid, setItemId] = useState();
  const [bagid, setBagId] = useState();

  // For editing an existing task, track the task ID and type
  const [taskId, setTaskId] = useState("");
  const [isItemTask, setIsItemTask] = useState(false);

  // -------------------
  // Popup Visibility Toggles
  // -------------------
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setInputFields([{ Title: "", Desc: "", id: generateUUID() }]);
    setChecklistName("");
    setAppliesTo("");
    setSelectedSelectedLocatioId("");
    setSelectedSelectedLocatioName("");
  };

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => setIsOpen5(!isOpen5);

  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setTaskGroupName("");
    setTaskGroupDisplayOrder("");
    setEditAllItems("");
    setIsOpen5(!isOpen5);
    setIsOpen3(!isOpen3);
  };

  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = (tgid, cid) => {
    setIsOpen2(!isOpen2);
    setCurrentTaskGroupId(tgid);
    setCurrentCid(cid);
    setIsOpen5(!isOpen5);
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setIsOpen2(!isOpen2);
    setTaskDesciption("");
    setTaskName("");
  };
  const togglePopup44 = () => setIsOpen4(!isOpen4);

  const [isOpen6, setIsOpen6] = useState(false);
  const togglePopup6 = () => {
    setIsOpen6(!isOpen6);
    setIsOpen2(!isOpen2);
  };

  const [isOpen7, setIsOpen7] = useState(false);
  const togglePopup7 = () => {
    setIsOpen7(!isOpen7);
    setIsOpen5(!isOpen5);
  };

  // Popup for deleting a task group
  const [isOpen66, setIsOpen66] = useState(false);
  const togglePopup66 = () => {
    setIsOpen66(!isOpen66);
    setIsOpen7(!isOpen7);
  };

  // Popup for editing an existing task
  const [isOpen44, setIsOpen44] = useState(false);
  const togglePopup444 = () => {
    setIsOpen44(!isOpen44);
    setIsOpen5(!isOpen5);
  };

  // Popup for deleting a task
  const [isOpen444, setIsOpen444] = useState(false);
  const togglePopup4444 = () => {
    setIsOpen444(!isOpen444);
    setIsOpen44(false);
  };

  // Popup for selected item events (if needed)
  const [selectedItemEvents, setSelectedItemEvents] = useState([]);
  const [selectedItemOpen, setSelectedItemOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");

  // Popup for deleting a checklist
  const [isOpen22, setIsOpen22] = useState(false);
  const [deleteFrequencyId, setDelteFrequencytId] = useState("");
  const togglePopup22 = (clid) => {
    setIsOpen22(!isOpen22);
    setDelteFrequencytId(clid);
  };

  // -------------------
  // Lifecycle: Fetch Checklists on Mount
  // -------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get(`/allLocationChecklist`, {
          params: { currentUserSiteId },
        });
        setChecklist(response.data.data.checklist);
        setLocationList(response.data.data.locations);
      } catch (err) {
        // console.log(err);
      }
    };
    if (currentUserSiteId) fetchData();
  }, [currentUserSiteId]);

  // -------------------
  // Utility: UUID Generator
  // -------------------
  function generateUUID() {
    if (
      typeof crypto !== "undefined" &&
      typeof crypto.randomUUID === "function"
    ) {
      return crypto.randomUUID();
    } else if (
      typeof crypto !== "undefined" &&
      typeof crypto.getRandomValues === "function"
    ) {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    } else {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ ((Math.random() * 16) >> (c / 4))).toString(16)
      );
    }
  }

  // -------------------
  // Mappers for React Select
  // -------------------
  let options = locationList.map((data) => ({
    value: data.id,
    label: data.name,
  }));

  let ItemChecklist = inventoryInfo.map((data) => ({
    value: data.clid,
    label: data.checklistname,
  }));

  // -------------------
  // Fetchers
  // -------------------
  const fetchData3 = async (clid, name, apparatusid, locationName) => {
    try {
      const response = await ChecklistFinder.get("/locationChecklistGetSteps", {
        params: { lclid: clid, currentUserSiteId },
      });

      setInputFields(
        response.data.data.checklistSteps.map((e) => ({
          Title: e.taskname,
          Desc: e.taskdescription,
          id: crypto.randomUUID(),
        }))
      );

      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);
      setCompartments(response.data.data.comartments);
      setInventoryInfo(response.data.data.inventoryInfo);

      togglePopup5();
      setChecklistName(name);
      setUpadteClid(clid);
      setAppliesTo(apparatusid);
      setSelectedSelectedLocatioId(apparatusid);
      setSelectedSelectedLocatioName(locationName);
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchTaskInformation = async (clid) => {
    try {
      const response = await ChecklistFinder.get(
        "/locationChecklistGetStepsUpdate",
        {
          params: { lclid: clid, currentUserSiteId },
        }
      );
      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchSelectedItemTask = async (invid, name) => {
    try {
      const response = await ChecklistFinder.get("/getSelectedItemChecklsit", {
        params: { invid, currentUserSiteId },
      });
      setSelectedItemEvents(response.data.data.itemevent);
      setSelectedItemName(name);
      // toggle popup for selected item events if needed
    } catch (err) {
      // console.log(err);
    }
  };

  // -------------------
  // CRUD Handlers
  // -------------------
  const handleNewChecklistSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      await ChecklistFinder.post("/newLocationChecklist", {
        checklistName,
        currentUserSiteId,
        appliesTo,
      });
      setLoading(false);

      const response = await ChecklistFinder.get(`/allLocationChecklist`, {
        params: { currentUserSiteId },
      });
      setChecklist(response.data.data.checklist);
      setLocationList(response.data.data.locations);

      togglePopup();
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };

  const handleUpdateChecklistSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      await ChecklistFinder.post("/updateLocationchecklist", {
        checklistName,
        selectedApparatus: appliesTo,
        currentUserSiteId,
        inputFields,
        clid: updateClid,
      });
      setLoading(false);

      const response = await ChecklistFinder.get(`/allLocationChecklist`, {
        params: { currentUserSiteId },
      });
      setChecklist(response.data.data.checklist);
      setLocationList(response.data.data.locations);
      togglePopup5()
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };

  const handleNewTaskGroup = async (e) => {
    e.preventDefault();
    let holder = linkedCompartment === "" ? null : linkedCompartment;
    try {
      setLoading(true);
      await ChecklistFinder.post("/taskgroup", {
        addTaskGroupName,
        clid: updateClid,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setTaskGroupName("");
      togglePopup3();
    } catch (err) {
      setLoading(false);
    }
  };

  // Create new custom task
  const handleNewTask = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: updateClid,
        bagid,
        itemid,
        taskDesciption,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setTaskName("");
      setBagId();
      setItemId();
      setCurrentTaskGroupId("");
      setTaskDesciption("");
      togglePopup44();
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  // Create new item task
  const handleNewInventory = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: updateClid,
        bagid,
        itemid,
        taskDesciption,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  // Unified editTask function – invoked when user clicks Edit on a task.
  const editTask = (taskData) => {
    setTaskId(taskData.ltclid);
    setTaskName(taskData.name);
    setIsItemTask(taskData.isItemTask || false);
    // Open the edit task popup
    togglePopup444();
  };

  // Submit edited task. Adjust payload based on task type.
  const handleEditTask = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await ChecklistFinder.post("/editTask", {
        taskId,
        addTaskName,
        isItemTask, // New flag to indicate task type
        clid: updateClid,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setBagId();
      setItemId();
      setCurrentTaskGroupId("");
      setTaskDesciption("");
      togglePopup444();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteTask = async (e) => {
    e.preventDefault();
    try {
      await ChecklistFinder.post("/deleteTask", {
        taskId,
        clid: updateClid,
        currentUserSiteId,
      });
      fetchTaskInformation(updateClid);
      setIsOpen444(false);
      setIsOpen5(!isOpen5);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleEditTaskGroup = async (e) => {
    e.preventDefault();
    let holder = linkedCompartment === "" ? null : linkedCompartment;
    try {
      setLoading(true);
      await ChecklistFinder.post("/editTaskgroup", {
        addTaskGroupId,
        addTaskGroupName,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        clid: updateClid,
        allItems:
          editAllItems === true || editAllItems === "true" ? "true" : null,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      togglePopup7();
    } catch (err) {
      setLoading(false);
    }
  };

  // Delete Task Group
  const handleDeleteTaskGroup = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await ChecklistFinder.post("/deleteTaskgroup", {
        addTaskGroupId,
        addTaskGroupName,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setIsOpen66(!isOpen66);
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  // Helper to confirm deletion of a task group.
  const confirmDeleteTaskGroup = () => {
    togglePopup66();
  };

  const addItemEvent = async (clid) => {
    try {
      await ChecklistFinder.post("/selectedItemAddEvent", {
        clid,
        currentUserSiteId,
        tgid: currentTaskGroupId,
      });
      fetchTaskInformation(updateClid);
      setIsOpen6(!isOpen6);
      setIsOpen5(!isOpen5);
    } catch (err) {
      // console.log(err);
    }
  };

  const editTaskGroup = async (
    tgid,
    taskgroupname,
    cid,
    name,
    allitems,
    displayorder
  ) => {
    setTaskGroupId(tgid);
    setTaskGroupName(taskgroupname);
    setLinkedCompartment(cid);
    setEditAllItems(allitems);
    setTaskGroupDisplayOrder(displayorder);
    togglePopup7();
  };

  const togglePopupSelectedItem = () => {
    setSelectedItemOpen(!selectedItemOpen);
    setIsOpen6(!isOpen6);
  };

  const deleteChecklist = async () => {
    try {
      await ChecklistFinder.post("/deleteLocationChecklist", {
        clid: deleteFrequencyId,
        currentUserSiteId,
      });
      const response = await ChecklistFinder.get(`/allLocationChecklist`, {
        params: { currentUserSiteId },
      });
      setChecklist(response.data.data.checklist);
      setLocationList(response.data.data.locations);
      setIsOpen22(!isOpen22);
    } catch (err) {
      // console.log(err);
    }
  };

  // -------------------
  // Render
  // -------------------
  return (
    <>
      {/* Top Bar */}
      <div className="top-[64px] bg-white border-b shadow-sm">
        <div className="filter-search-toggle-container">
          <div className="filter-search-container">
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopup}
            >
              New Location
            </button>
          </div>
        </div>
      </div>

      {/* Checklist List */}
      <div className="detailsPopupContainer222"></div>
      <div className="checklistListHolder">
        <div className="inventoryList">
          <div className="ReportResultsContainer">
            <div className="reportsPopupContainer">
              {checklist ? (
                <table id="reportsTable">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                      <th></th>
                    </tr>
                    {checklist.map((data, i) => (
                      <tr key={i}>
                        <td
                          onClick={() =>
                            fetchData3(
                              data.lclid,
                              data.checklistname,
                              data.apparatusid,
                              data.name
                            )
                          }
                        >
                          {data.checklistname}
                        </td>
                        <td>
                      {data.name}
                        </td>
                        <td>
                          <div onClick={() => togglePopup22(data.lclid)}>
                            <BsThreeDotsVertical />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* Popup: New Location Checklist */}
      {isOpen1 && (
        <PopupWeb
          content={
            <form onSubmit={handleNewChecklistSubmit}>
              <div>
                {error && <div className="alert">{error}</div>}
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Checklist Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={checklistName}
                      onChange={(e) => setChecklistName(e.target.value)}
                      required
                    />
                  </label>
                  <label className="NewInputLabel" data-domain="Added To">
                    <Select
                      options={options}
                      onChange={setAppliesTo}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      maxMenuHeight={150}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </label>
          
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleNewChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>New Location Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Popup: Update Checklist */}
      {isOpen5 && (
        <PopupWeb
          content={
            <form onSubmit={handleUpdateChecklistSubmit}>
              <div>
                {error && <div className="alert">{error}</div>}
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Checklist Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={checklistName}
                      onChange={(e) => setChecklistName(e.target.value)}
                      required
                    />
                  </label>
                  <label className="NewInputLabel" data-domain="Assign To">
                    {appliesTo.value !== "" ? (
                      <Select
                        options={options}
                        onChange={(e) => setAppliesTo(e.value)}

                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={{
                          label: selectedSelectedLocatioName,
                          value: selectedSelectedLocatioId,
                        }}
                        maxMenuHeight={150}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        options={options}
                        onChange={setAppliesTo}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight={150}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                  </label>

                  {taskGroup &&
                    taskGroup.map((group, i) => (
                      <div
                        key={i}
                        className="mb-4 border border-gray-200 rounded shadow-sm bg-white"
                      >
                        {/* Task Group Header */}
                        <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                          <div className="font-bold text-sm sm:text-base">
                            {group.taskgroupname}
                          </div>
                          <div
                            className="text-blue-600 text-sm cursor-pointer hover:underline"
                            onClick={() =>
                              editTaskGroup(
                                group.ltgid,
                                group.taskgroupname,
                                group.cid,
                                group.name,
                                group.allitems,
                                group.displayorder
                              )
                            }
                          >
                            Edit
                          </div>
                        </div>

                        {/* Task Group Body */}
                        <div className="px-4 py-3">
                          {task &&
                            task.map((taskItem, ii) => {
                              if (taskItem.ltgid === group.ltgid) {
                                const _isItemTask =
                                  taskItem.checklistname != null;
                                return (
                                  <div
                                    key={ii}
                                    className="flex justify-between items-center border-b border-gray-200 py-2"
                                  >
                                    <div className="flex items-center space-x-2">
                                      {_isItemTask ? (
                                        <FaRegListAlt
                                          className="text-gray-500"
                                          size={16}
                                        />
                                      ) : (
                                        <FaPencilAlt
                                          className="text-gray-500"
                                          size={16}
                                        />
                                      )}
                                      <span className="text-gray-800">
                                        {_isItemTask
                                          ? taskItem.checklistname
                                          : taskItem.taskname}
                                      </span>
                                    </div>
                                    <div
                                      className="text-blue-600 text-sm cursor-pointer hover:underline"
                                      onClick={() =>
                                        editTask({
                                          ltclid: taskItem.ltclid,
                                          name: _isItemTask
                                            ? taskItem.checklistname
                                            : taskItem.taskname,
                                          isItemTask: _isItemTask,
                                        })
                                      }
                                    >
                                      Edit
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          {/* "Add Task" link */}
                          <div
                            className="text-blue-500 cursor-pointer hover:underline text-sm mt-2"
                            onClick={() => togglePopup2(group.ltgid, group.cid)}
                          >
                            + Add Task
                          </div>
                        </div>
                      </div>
                    ))}

                  <div
                    className="ChecklistAddStep cursor-pointer text-sm text-blue-500 mt-4"
                    onClick={togglePopup3}
                  >
                    + Add Task Group
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={handleUpdateChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>Update Location Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Update"}
        />
      )}

      {/* Popup: New Task Group */}
      {isOpen3 && (
        <PopupWeb
          content={
            <form onSubmit={handleNewTaskGroup}>
              <div className="popupContainer">
                <label className="NewInputLabel" data-domain="Add Name">
                  <input
                    className="NewInput"
                    type="text"
                    placeholder=""
                    value={addTaskGroupName}
                    required
                    onChange={(e) => setTaskGroupName(e.target.value)}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Compartment Link">
                  <select
                    className="NewInput"
                    onChange={(event) =>
                      setLinkedCompartment(event.target.value)
                    }
                    required
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Compartment Link
                    </option>
                    <option value="">None</option>
                    {compartments.map((e, i) => (
                      <option key={i} value={e.cid}>
                        {e.compartmentname}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="NewInputLabel" data-domain="Display Order">
                  <input
                    className="NewInput"
                    type="number"
                    placeholder=""
                    value={
                      addTaskGroupdDisplayOrder === null
                        ? ""
                        : addTaskGroupdDisplayOrder
                    }
                    onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                  />
                </label>
              </div>
            </form>
          }
          handleClose={togglePopup3}
          isOpen={isOpen3}
          handleSubmit={handleNewTaskGroup}
          showSubmitButton={true}
          headerText={<b>New Task Group</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Popup: Choose New Task Type */}
      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                <div className="btnDiv">
                  <div className="addTaskTypeBtn" onClick={togglePopup4}>
                    <div className="addTaskTypeBtn2">Add Custom Task</div>
                  </div>
                  {currentCid ? (
                    <div className="addTaskTypeBtn" onClick={togglePopup6}>
                      <div className="addTaskTypeBtn2">Add Item Task</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          }
          handleClose={togglePopup2}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={<b>New Task Type</b>}
        />
      )}

      {/* Popup: New Custom Task */}
      {isOpen4 && (
        <PopupWeb
          content={
            <form onSubmit={handleNewTask}>
              <div className="popupContainer">
                <label className="NewInputLabel" data-domain="Task Description">
                  <input
                    className="NewInput"
                    type="text"
                    placeholder=""
                    value={taskDesciption}
                    onChange={(e) => setTaskDesciption(e.target.value)}
                    required
                  />
                </label>
              </div>
            </form>
          }
          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleNewTask}
          showSubmitButton={true}
          headerText={<b>New Custom Task</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Popup: New Item Task */}
      {isOpen6 && (
        <PopupWeb
          content={
            <form onSubmit={handleNewInventory}>
              <label className="NewInputLabel" data-domain="Select Checklist">
                <Select
                  options={ItemChecklist}
                  onChange={(selectedOption) =>
                    addItemEvent(selectedOption.value)
                  }
                  name="checklist"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  maxMenuHeight={150}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
            </form>
          }
          handleClose={togglePopup6}
          isOpen={isOpen6}
          handleSubmit={handleNewInventory}
          showSubmitButton={true}
          headerText={<b>Select Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Popup: Selected Item Events */}
      {selectedItemOpen && (
        <PopupWeb
          content={
            <>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                {selectedItemEvents.length > 0 ? (
                  selectedItemEvents.map((data, i) => (
                    <div key={i}>
                      <div className="itemListTaskDescContainer">
                        <div className="itemListTaskDesc">{data.taskname}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="apparatusEmptyHolder">
                    <div className="apparatusEmptyName">
                      No Saved Tasks For This Item
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          handleClose={togglePopupSelectedItem}
          isOpen={selectedItemOpen}
          handleSubmit={handleNewInventory}
          showSubmitButton={true}
          headerText={<b>{selectedItemName} Saved Events</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Popup: Edit Task (Custom vs. Item Task) */}
      {isOpen44 && (
        <PopupWeb
          content={
            <form onSubmit={handleEditTask}>
              <div className="popupContainer">
                {isItemTask ? (
                  // For item tasks, show a React Select to choose a different item checklist.
                  <label
                    className="NewInputLabel"
                    data-domain="Select Checklist"
                  >
                    <Select
                      options={ItemChecklist}
                      onChange={(selectedOption) =>
                        setTaskName(selectedOption.value)
                      }
                      name="checklist"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      maxMenuHeight={150}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      defaultValue={ItemChecklist.find(
                        (o) => o.label === addTaskName
                      )}
                    />
                  </label>
                ) : (
                  // For custom tasks, show a text input for the description.
                  <label
                    className="NewInputLabel"
                    data-domain="Task Description"
                  >
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      required
                    />
                  </label>
                )}
                <div className="editApparatusBtnContainer">
                  <div className="editApparatusBtn" onClick={togglePopup4444}>
                    <b>Delete</b>
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup444}
          isOpen={isOpen44}
          handleSubmit={handleEditTask}
          showSubmitButton={true}
          headerText={<b>Edit Task</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        />
      )}

      {/* Popup: Delete Task */}
      {isOpen444 && (
        <PopupWeb
          content={
            <>
              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup4444}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTask}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup4444}
          isOpen={isOpen444}
          handleSubmit={handleEditTask}
          showSubmitButton={false}
          headerText={
            addTaskName ? (
              <div className="popupTitle">
                <b>Delete Task - {addTaskName}</b>
              </div>
            ) : (
              ""
            )
          }
        />
      )}

      {/* Popup: Edit Task Group */}
      {isOpen7 && (
        <PopupWeb
          content={
            <form onSubmit={handleEditTaskGroup}>
              <div className="popupContainer">
                <label className="NewInputLabel" data-domain="Add Name">
                  <input
                    className="NewInput"
                    type="text"
                    placeholder=""
                    value={addTaskGroupName}
                    required
                    onChange={(e) => setTaskGroupName(e.target.value)}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Compartment Link">
                  <select
                    className="NewInput"
                    onChange={(event) =>
                      setLinkedCompartment(event.target.value)
                    }
                    required
                    defaultValue={linkedCompartment}
                  >
                    <option value="" disabled>
                      Compartment Link
                    </option>
                    <option value="">None</option>
                    {compartments.map((e, i) => (
                      <option key={i} value={e.cid}>
                        {e.compartmentname}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="NewInputLabel" data-domain="Display Order">
                  <input
                    className="NewInput"
                    type="number"
                    placeholder=""
                    value={
                      addTaskGroupdDisplayOrder === null
                        ? ""
                        : addTaskGroupdDisplayOrder
                    }
                    onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                  />
                </label>

                <div className="editApparatusBtnContainer">
                  <div
                    className="editApparatusBtn"
                    onClick={confirmDeleteTaskGroup}
                  >
                    <b>Delete</b>
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup7}
          isOpen={isOpen7}
          handleSubmit={handleEditTaskGroup}
          showSubmitButton={true}
          headerText={<b>Edit Task Group</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        />
      )}

      {/* Popup: Delete Task Group */}
      {isOpen66 && (
        <PopupWeb
          content={
            <>
              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup66}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTaskGroup}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup66}
          isOpen={isOpen66}
          handleSubmit={handleDeleteTaskGroup}
          showSubmitButton={false}
          headerText={<b>Delete Task Group - {addTaskGroupName}</b>}
        />
      )}

      {/* Popup: Delete Location Checklist */}
      {isOpen22 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete Location Checklist</b>
              </div>
              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete this location checklist?</b>
              </div>
              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup22}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={deleteChecklist}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup22}
          isOpen={isOpen22}
          showSubmitButton={false}
          headerText={<b>Delete Location Checklist</b>}
        />
      )}
    </>
  );
};

export default ChecklistLocationPage;
