// src/pages/EditItemDetailsPage.jsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import InventoryFinder from "../../apis/InventoryFinder";
import { useAuth } from "../../context/AuthContext";
import DynamicForm from "../Popup/DynamicForm";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import ClipLoader from "react-spinners/ClipLoader";
import SelectionModal from "../Popup/SelectionModal";
import { usePageTitle } from "../Navbar/PageTitleContext";
import useIsMobile from "../../hooks/useIsMobile";
import PublicFolder from "../../apis/PublicFolder";

const EditItemDetailsPage = () => {
  const { id } = useParams(); // item id from URL
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { currentUserId, currentUserSiteId, currentFirstName, currentLastName } = useAuth();

  // Set page title
  const { setPageTitle } = usePageTitle("");
  setPageTitle("Edit Item Details");

  // Overall loading and error state.
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Form state (default values for amount and status are set here)
  const [commonFormData, setCommonFormData] = useState({
    tags: [], // will be an array of tag objects
    amount: "1", // default amount
    identificationName: "",
    serialNumber: "",
    size: "",
    status: "Safe to use", // default status
    manufacturer: "",
    model: "",
    manufactureDate: "",
    firstUseDate: "",
    mandatoryServiceDate: "",
    retirementDate: "",
  });

  // For file input
  const [selectedImage, setSelectedImage] = useState(null);

  // Fetched tags options for multi-select tags field
  const [allTags, setAllTags] = useState([]);

  // For modal fields (e.g., status or tags)
  const [activeModal, setActiveModal] = useState(null);
  const modalOptions = {
    // For non-tags fields; tags will use allTags.
    status: [
      { value: "Safe to use", label: "Safe to use" },
      { value: "Monitor", label: "Monitor" },
      { value: "Not safe to use", label: "Not safe to use" },
      { value: "Missing", label: "Missing" },
      { value: "Retired", label: "Retired" },
    ],
  };

  // Fetch item details from backend on mount
  useEffect(() => {
    const fetchItemDetails = async () => {
      setLoading(true);
      try {
        const response = await InventoryFinder.get(`/editselecteditem/${id}`, {
          params: { currentUserSiteId },
        });
  
        // If itemInfo is an array, extract the first element.
        const items = response.data.data.itemInfo;
        if (!items || items.length === 0) {
          return;
        }
        const itemTags = response.data.data.itemTags;

        const item = items[0];

        const transformedTags = response.data.data.allTags.map((tag) => ({
            value: tag.categoryid,
            label: tag.name,
          }));
          setAllTags(transformedTags);

        setCommonFormData({
          tags: itemTags,
          amount: item.amount ? String(item.amount) : "1",
          identificationName: item.identificationname || "",
          serialNumber: item.serialnumber || "",
          size: item.size || "",
          status: item.status || "Safe to use",
          manufacturer: item.manufacture || "",
          model: item.model || "",
          manufactureDate: item.manufacturedate
            ? new Date(item.manufacturedate).toISOString().substring(0, 10)
            : "",
          firstUseDate: item.dateoffirstuse
            ? new Date(item.dateoffirstuse).toISOString().substring(0, 10)
            : "",
          mandatoryServiceDate: item.mandatoryinspectiondate
            ? new Date(item.mandatoryinspectiondate).toISOString().substring(0, 10)
            : "",
          retirementDate: item.expirationdate
            ? new Date(item.expirationdate).toISOString().substring(0, 10)
            : "",
        });
      } catch (err) {
        console.error("Error fetching item details:", err);
        notify("Failed to fetch item details", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchItemDetails();
  }, [id, currentUserSiteId]);
  
  


  // Define the fields for DynamicForm (excluding tags, which is handled separately)
  const commonFields = [
    {
        name: "tags",
        label: "Tags",
        isModal: true,
        required: false,
        placeholder: "Select Tags",
      },{
      name: "amount",
      label: "Amount",
      inputType: "number",
      required: true,
      placeholder: "Enter amount",
    },
    {
      name: "identificationName",
      label: "Identification Name",
      required: false,
      placeholder: "Enter identification name",
    },
    {
      name: "serialNumber",
      label: "Serial Number",
      required: false,
      placeholder: "Enter serial number",
    },
    {
      name: "size",
      label: "Size",
      required: false,
      placeholder: "Enter size",
    },

    {
      name: "manufacturer",
      label: "Manufacturer",
      required: false,
      placeholder: "Enter manufacturer",
    },
    {
      name: "model",
      label: "Model",
      required: false,
      placeholder: "Enter model",
    },
    {
      name: "manufactureDate",
      label: "Manufacture Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "firstUseDate",
      label: "Date of First Use",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "mandatoryServiceDate",
      label: "Mandatory Service Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "retirementDate",
      label: "Retirement Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
  ];

  // Modal confirm handler
  const handleModalConfirm = (selected) => {
    if (activeModal === "tags") {
      // For tags, we expect multiple selection.
      const updatedTags = Array.isArray(selected)
        ? selected
            .map((tag) =>
              typeof tag === "string"
                ? allTags.find((t) => String(t.value) === String(tag))
                : tag
            )
            .filter((tag) => tag != null) // Remove any null/undefined values
        : [];
      setCommonFormData((prev) => ({ ...prev, tags: updatedTags }));
    } else {
      const value = typeof selected === "string" ? selected : selected.label;
      setCommonFormData((prev) => ({ ...prev, [activeModal]: value }));
    }
    setActiveModal(null);
  };

  const onChangeField = (fieldName, value) => {
    setCommonFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onOpenModal = (fieldName) => {
    setActiveModal(fieldName);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
console.log("commonFormData",commonFormData)
  // Submission handler for editing item details
  const handleUpdate = async (e) => {
    e.preventDefault();
    // Optional: Add validation logic here
    setLoading(true);
    try {
      // Build a payload object for JSON submission
      const payload = {
        itemid: id,
        selectedAmount: commonFormData.amount,
        selectedIdentificationName: commonFormData.identificationName,
        selectedSerialNumber: commonFormData.serialNumber === "" ? null : commonFormData.serialNumber,
        selectedManufactureDate: commonFormData.manufactureDate,
        // Map your "retirementDate" field to selectedExpirationDate.
        selectedExpirationDate: commonFormData.retirementDate,
        selectedHydrostaticDate: commonFormData.mandatoryServiceDate,
        size: commonFormData.size,
        itemManufcture: commonFormData.manufacturer,
        selectedFirstUseDate: commonFormData.firstUseDate,
        model: commonFormData.model,
        // For tags, send an array of tag IDs
        selectedItemTags: commonFormData.tags.map(tag => tag.value),
        currentUserId: currentUserId,
        currentUserSiteId: currentUserSiteId,
        currentFirstName: currentFirstName,
        currentLastName: currentLastName,
      };
      // Handle image if updated (this example sends just the file name)
      if (selectedImage) {
        payload.image = selectedImage.name;
      } else {
        payload.image = "";
      }

      await InventoryFinder.post("/editItem", payload);
      notify("Item updated successfully!", "success");
      setLoading(false);
      navigate(`/inventory/${id}/itempage`);
    } catch (err) {
      console.error(err);
      notify("Failed to update item details", "error");
      setLoading(false);
    }
  };

  // Prepare a display string for tags
  const displayTags =
    Array.isArray(commonFormData.tags) && commonFormData.tags.length > 0
      ? commonFormData.tags.map((tag) => tag.label).join(", ")
      : "";


      const defaultTagSelection = commonFormData.tags.map((item) => item.value);



  return (
    <div className="p-4  mx-auto">
      <ToastNotifier />
      <form onSubmit={handleUpdate} className="space-y-6">
        <div className="border p-4 rounded space-y-4">
          {/* Tags Field */}
       
          {/* Other Fields Rendered via DynamicForm */}
          <DynamicForm
            fields={commonFields}
            formData={commonFormData}
            onChangeField={onChangeField}
            onOpenModal={onOpenModal}
            errors={errors}
            loading={loading}
            fullScreen={isMobile}
          />
          {/* File Input for Image
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Item Image</label>
            <input
              type="file"
              onChange={handleFileInput}
              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm"
            />
          </div> */}
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() =>navigate(`/inventory/${id}/itempage`)        }
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
          >
            {loading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>

      {/* Modal for Editing Tags */}
      {activeModal && (
  <SelectionModal
    isOpen={true}
    onClose={() => setActiveModal(null)}
    onConfirm={handleModalConfirm}
    options={activeModal === "tags" ? allTags : modalOptions[activeModal] || []}
    label={`Select ${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`}
    {...(activeModal === "tags"
      ? { multiple: true, defaultValue: defaultTagSelection || [] }
      : {})}
  />
)}
    </div>
  );
};

export default EditItemDetailsPage;
