import React, { useState, useEffect, useMemo } from "react";
import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PDFReportTemplate = ({
  data = [],
  title,
  reportDateRange,
  category,
  filters,
  members,
  logoUrl,
  pdfColumns = [],
  includeDateRange = false,
  includeCategory = false,
  includeMembers = false,
  includeFilters = false,
}) => {
  const [base64IMG, setBase64IMG] = useState("");

  useEffect(() => {
    const imagePath = logoUrl || LogonLogo;
    imageToBase64(imagePath)
      .then((response) => setBase64IMG(response))
      .catch((err) => console.error("Error converting image:", err));
  }, [logoUrl]);

  // Memoize the table body data so that it only recomputes when `data` or `pdfColumns` change.
  const tableBody = useMemo(() => {
    if (!pdfColumns || pdfColumns.length === 0) return [];
    const heading = pdfColumns.map((col) => ({
      text: col.header,
      bold: true,
      fontSize: 10,
    }));
    const bodyRows = data.map((row) =>
      pdfColumns.map((col) => ({
        text: col.accessor ? col.accessor(row) : row[col.field] || "",
        fontSize: 8,
      }))
    );
    return [heading, ...bodyRows];
  }, [data, pdfColumns]);

  const generatePdf = () => {
    if (pdfColumns.length === 0) {
      console.error("No PDF columns defined");
      return;
    }
    const numColumns = pdfColumns.length;
    const widths = Array(numColumns).fill(`${100 / numColumns}%`);

    const filterContent =
      includeFilters && filters && filters.length > 0
        ? {
            text: `Filters: ${filters
              .filter((f) => f != null)
              .map((filter) => filter.label)
              .join(", ")}`,
            fontSize: 8,
            margin: [20, 0, 0, 10],
          }
        : null;

    const content = [
      ...(includeDateRange
        ? [{ text: `Date range: ${reportDateRange}`, fontSize: 10, margin: [20, 0, 0, 10] }]
        : []),
      ...(includeCategory
        ? [{ text: `Category: ${category}`, fontSize: 10, margin: [20, 0, 0, 10] }]
        : []),
      ...(includeMembers
        ? [{ text: `Members: ${members.join(", ")}`, fontSize: 10, margin: [20, 0, 0, 10] }]
        : []),
      ...(filterContent ? [filterContent] : []),
      { width: "*", text: "", margin: [0, 0, 0, 10] },
      {
        text: `Number of records: ${data.length}`,
        fontSize: 10,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      {
        style: "tableStyle",
        table: {
          headerRows: 1,
          widths: widths,
          body: tableBody,
        },
      },
    ];

    const docDefinition = {
      pageMargins: [20, 70, 20, 25],
      pageOrientation: "landscape",
      info: {
        title: title,
      },
      footer: function (currentPage, pageCount) {
        return {
          text: `${currentPage} of ${pageCount}`,
          alignment: "center",
        };
      },
      header: () => ({
        columns: [
          {
            text: title,
            bold: true,
            fontSize: 26,
            margin: [20, 20, 0, 0],
          },
          {
            image: base64IMG ? `data:image/png;base64,${base64IMG}` : "",
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      }),
      content: content,
      styles: {
        tableStyle: {
          fontSize: 10,
        },
      },
    };

    const fileName = `${title.replace(/\s+/g, "_")}_${new Date()
      .toISOString()
      .slice(0, 10)}.pdf`;

    pdfMake.createPdf(docDefinition).download(fileName);
  };

  return (
    <button
      className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={generatePdf}
    >
      Generate PDF
    </button>
  );
};

export default PDFReportTemplate;
