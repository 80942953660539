import React, { useState, createContext } from "react";

export const ItemPageContext = createContext();

export const ItemPageContextProvider = (props) => {
  const [itemHistory, setItemHistory] = useState([]);
  const [itemUsage, setItemUsage] = useState([]);
  const [itemInspection, setItemInspection] = useState([]);
  const [itemInspectionAlerts, setItemInspectionAlerts] = useState([]);
  const [itemId, setItemId] = useState([]);

  const [itemScheduledEvent, setItemScheduledEvent] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [apparatusList, setApparatusList] = useState([]);
  const [itemInfo, setItemInfo] = useState([]);
  const [inspectionOpen, setInspectionOpen] = useState(false);

  const [itemArea, setItemArea] = useState([]);
  const [itemPersonal, setItemPersonal] = useState([]);
  const [itemTags, setItemTags] = useState([]);


  return (
    <ItemPageContext.Provider
      value={{
        itemId, setItemId,
        itemHistory,
        setItemHistory,
        itemScheduledEvent,
        setItemScheduledEvent,
        eventList,
        setEventList,
        apparatusList,
        setApparatusList,
        itemUsage,
        setItemUsage,
        itemInspection,
        setItemInspection,
        itemInfo,
        setItemInfo,
        setItemInspectionAlerts,
        itemInspectionAlerts,inspectionOpen, setInspectionOpen,itemTags, setItemTags,itemPersonal, setItemPersonal,itemArea, setItemArea
      }}
    >
      {props.children}
    </ItemPageContext.Provider>
  );
};
