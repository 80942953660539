import React, { useContext, useEffect, useRef, useState } from "react";
import InspectionsFinder from "../../apis/InspectionsFinder";
import { useAuth } from "../../context/AuthContext";
import PopupWeb from "../Popup/PopupNew";
import ClipLoader from "react-spinners/ClipLoader";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";
import { ItemPageContext } from "../../context/ItemPageContext";
import { useMainItemPageData } from "./fetchItemPageData";
import InventoryFinder from "../../apis/InventoryFinder";
import Select from "react-select";

// Helper component for "no checklist" scenario using checkboxes styled as buttons
const RenderNoChecklist = ({ setInspectionStatus, inspectionStatusObject }) => {
  const taskId = "0";
  const currentStatuses = inspectionStatusObject[taskId] || [];
  const handleChange = (status, checked) => {
    const current = inspectionStatusObject[taskId] || [];
    if (checked) {
      setInspectionStatus([...current, status], taskId);
    } else {
      setInspectionStatus(current.filter((s) => s !== status), taskId);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex items-center gap-2">
        <label className="cursor-pointer flex items-center">
          <input
            type="checkbox"
            className="hidden"
            value="Pass"
            checked={currentStatuses.includes("Pass")}
            onChange={(e) => handleChange("Pass", e.target.checked)}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatuses.includes("Pass")
                ? "bg-green-500 text-white border-green-500"
                : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
            }`}
          >
            Pass
          </span>
        </label>
        <label className="cursor-pointer flex items-center">
          <input
            type="checkbox"
            className="hidden"
            value="Monitor"
            checked={currentStatuses.includes("Monitor")}
            onChange={(e) => handleChange("Monitor", e.target.checked)}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatuses.includes("Monitor")
                ? "bg-yellow-400 text-white border-yellow-400"
                : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
            }`}
          >
            Monitor
          </span>
        </label>
        <label className="cursor-pointer flex items-center">
          <input
            type="checkbox"
            className="hidden"
            value="Fail"
            checked={currentStatuses.includes("Fail")}
            onChange={(e) => handleChange("Fail", e.target.checked)}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatuses.includes("Fail")
                ? "bg-red-500 text-white border-red-500"
                : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
            }`}
          >
            Fail
          </span>
        </label>
      </div>
      {/* Hidden input submits the statuses as comma-separated */}
      <input type="hidden" name={`status_${taskId}`} value={currentStatuses.join(",")} />
    </div>
  );
};

// Helper component for rendering checklist tasks using checkboxes styled as buttons

const RenderChecklist = ({
  ChecklistData,
  dropdownQuery,
  setInspectionStatus,
  inspectionStatusObject,
}) => {
  const filteredData = ChecklistData.filter((data) => {
    if (!dropdownQuery.value || dropdownQuery.value === "None") return true;
    return data.clid === dropdownQuery.value;
  });

  if (filteredData.length === 0) {
    return <div>No tasks found for this checklist.</div>;
  }

  return (
    <>
      {filteredData.map((data) => (
        <div key={data.clid}>
          {Array.isArray(data.tasklist) && data.tasklist.length > 0 ? (
            data.tasklist.map((task) => {
              const currentStatus = inspectionStatusObject[task.tid] || "";
              const handleChange = (status) => {
                setInspectionStatus(status, task.tid);
              };
              return (
                <div key={task.tid} className="mb-4">
                  <div className="taskNameHolder mb-1">
                    <div className="taskName2 font-bold">{task.taskname}</div>
                    <div className="taskDesc2 text-sm text-gray-600">
                      {task.taskdescription}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Pass"
                        checked={currentStatus === "Pass"}
                        onChange={() => handleChange("Pass")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Pass"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
                        }`}
                      >
                        Pass
                      </span>
                    </label>
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Monitor"
                        checked={currentStatus === "Monitor"}
                        onChange={() => handleChange("Monitor")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Monitor"
                            ? "bg-yellow-400 text-white border-yellow-400"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
                        }`}
                      >
                        Monitor
                      </span>
                    </label>
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Fail"
                        checked={currentStatus === "Fail"}
                        onChange={() => handleChange("Fail")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Fail"
                            ? "bg-red-500 text-white border-red-500"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
                        }`}
                      >
                        Fail
                      </span>
                    </label>
                  </div>
                  <input
                    type="hidden"
                    name={`status_${task.tid}`}
                    value={currentStatus}
                  />
                  <input
                    name={`tid_${task.tid}`}
                    type="hidden"
                    value={task.tid}
                  />
                  <input
                    name={`taskname_${task.tid}`}
                    type="hidden"
                    value={task.taskname}
                  />
                  <input
                    name={`taskdesc_${task.tid}`}
                    type="hidden"
                    value={task.taskdescription}
                  />
                  <input
                    name={`clid_${task.tid}`}
                    type="hidden"
                    value={data.clid}
                  />
                </div>
              );
            })
          ) : (
            <div>No tasks available in this checklist.</div>
          )}
        </div>
      ))}
    </>
  );
};


const ItemPageInspectionTask = () => {
  const { currentUserSiteId, currentUserId, currentLastName, currentFirstName } = useAuth();
  const { inspectionOpen, setInspectionOpen, itemId } = useContext(ItemPageContext);
  const { refetch } = useMainItemPageData(currentUserSiteId, itemId);

  // Local state variables
  const [inspectionOpenMain, setInspectionOpenMain] = useState(true);
  const [loading, setLoading] = useState(false);
  const [inspectionPhotos, setInspectionPhotos] = useState([]);
  const [inspectionNotes, setInspectionNotes] = useState("");
  const [ChecklistData, setChecklistData] = useState([]);
  const [dropdownQuery, setDropdownQuery] = useState({ value: "None", label: "None" });
  // inspectionStatusObject stores an array of statuses per task id.
  const [inspectionStatusObject, setInspectionStatusObject] = useState({});

  const fileRef = useRef();

  // Fetch checklist data for the inspection.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await InventoryFinder.get("/getInventoryInspection", {
          params: { currentUserSiteId, isCheck: [itemId] },
        });
        setChecklistData(response.data.data.inspectionData);
      } catch (err) {
        console.error("Error fetching checklist data", err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId, itemId]);

  // Update the inspection status for a given task.
  const setInspectionStatus = (valueArray, taskId) => {
    setInspectionStatusObject((prev) => ({
      ...prev,
      [taskId]: valueArray,
    }));
  };

  // Handle image file selection.
  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  // Remove a selected image.
  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prev) => prev.filter((_, i) => i !== index));
  };

  // Submit inspection data to the backend.
  const handleInspectionSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const myForm = document.getElementById("inspectionForm");
      const formData = new FormData(myForm);

      // Append images to the form data.
      inspectionPhotos.forEach((image) => {
        formData.append("file", image);
      });

      // Combine overall inspection statuses into a comma-separated string.
      const inspectionStatusString = Object.values(inspectionStatusObject)
        .flat()
        .join(",");
      formData.set("isCheck", itemId);
      formData.set("inspectionStatus", inspectionStatusString);
      formData.set("inspectionNotes", inspectionNotes);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentUserId", currentUserId);
      formData.set("selectedChecklistClid", dropdownQuery.value);
      formData.set("selectedChecklistName", dropdownQuery.label);

      await InventoryFinder.post(`/postInspection`, formData);

          // After successful submission, reset the inspection statuses:
    setInspectionStatusObject({});
    // Optionally reset the dropdown as well:
    setDropdownQuery({ value: "None", label: "None" });
    
    // Optionally reset images/notes if needed:
    setInspectionPhotos([]);
    setInspectionNotes("");
    
      togglePopup();
      setLoading(false);
      refetch();
    } catch (err) {
      console.error("Error submitting inspection:", err);
      setLoading(false);
    }
  };

  // Toggle the popup display.
  const togglePopup = () => {
    setInspectionOpen(!inspectionOpen);
    setInspectionOpenMain(!inspectionOpenMain);
  };

  // Build options for the checklist dropdown.
  const ChecklistOptionsNone = [{ value: "None", label: "None" }];
  const ChecklistOptionsNew = ChecklistData.map((e) => ({
    value: e.clid,
    label: e.checklistname,
  }));
  const ChecklistOptions = [...ChecklistOptionsNone, ...ChecklistOptionsNew];

  return (
    <div className="sliderHolder">
      {inspectionOpenMain && (
        <PopupWeb
          content={
            <form id="inspectionForm" className="formClass">
              <div className="popupContainer space-y-4">
                {/* Checklist Section */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
                  <label className="NewInputLabel" data-domain="Checklist">
                    <Select
                      defaultValue={{ value: "None", label: "None" }}
                      options={ChecklistOptions}
                      onChange={(e) => {
                        const { value, label } = e;
                        setDropdownQuery({ value, label });
                      }}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      maxMenuHeight={150}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </label>
                  <div className="PassFailTaskContainer mt-4">
                    {dropdownQuery.value === "None" ? (
                      <RenderNoChecklist
                        setInspectionStatus={setInspectionStatus}
                        inspectionStatusObject={inspectionStatusObject}
                      />
                    ) : (
                      <RenderChecklist
                        ChecklistData={ChecklistData}
                        dropdownQuery={dropdownQuery}
                        setInspectionStatus={setInspectionStatus}
                        inspectionStatusObject={inspectionStatusObject}
                      />
                    )}
                  </div>
                </div>

                {/* Image Upload and Notes Section */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
                  <div className="mb-4">
                    <span className="text-sm font-semibold">Add Image</span>
                    <div className="flex items-center mt-2">
                      <input
                        ref={fileRef}
                        onChange={handleFileInputInspectionPhotos}
                        multiple
                        type="file"
                        accept="image/*"
                        hidden
                      />
                      <div
                        onClick={() => fileRef.current.click()}
                        className="w-12 h-12 border flex items-center justify-center cursor-pointer mr-4"
                      >
                        <RiImageAddFill size={30} />
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {inspectionPhotos.map((image, index) => (
                          <div key={index} className="relative w-16 h-16">
                            <img
                              src={URL.createObjectURL(image)}
                              alt="preview"
                              className="object-cover w-full h-full rounded"
                            />
                            <button
                              type="button"
                              className="absolute top-0 right-0 bg-white rounded-full p-1 text-red-600"
                              onClick={() => removeInspectionPhotos(index)}
                            >
                              <RiCloseFill size={20} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm font-semibold block mb-1">
                      Notes
                    </label>
                    <textarea
                      onChange={(e) => setInspectionNotes(e.target.value)}
                      className="w-full border p-2 text-sm rounded"
                      placeholder="Note..."
                    />
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup}
          isOpen={inspectionOpenMain}
          handleSubmit={handleInspectionSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}
        >
          <div className="text-center p-3">
            <ClipLoader loading={loading} className="override" size={35} />
          </div>
        </PopupWeb>
      )}
    </div>
  );
};

export default ItemPageInspectionTask;
