import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import {
  CameraIcon,
  ChatBubbleOvalLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

import ApparatusChecklist from "../../apis/ApparatusChecklist";
import { useAuth } from "../../context/AuthContext";
import { usePageTitle } from "../Navbar/PageTitleContext";

const ChecklistPage = () => {
  const [checklist, setChecklist] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState({});
  const [taskNotes, setTaskNotes] = useState({});
  const [showNoteArea, setShowNoteArea] = useState({});
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [collapsedBags, setCollapsedBags] = useState({});
  const [finalized, setFinalized] = useState(false);
  const { setPageTitle } = usePageTitle();

  const { currentUserSiteId, currentUserId, currentFirstName, currentLastName } = useAuth();
  const { id } = useParams();

  const socketRef = useRef(null);
  const checklistRef = useRef([]);

  // Helper: Group items by bag
  const groupItemsByBag = (items) => {
    return items.reduce((acc, item) => {
      // If itemid is missing, skip it from bag grouping
      if (!item.itemid) return acc;

      const bagKey = item.bagid && item.bagid !== "" ? item.bagid : "No Bag";
      if (!acc[bagKey]) {
        acc[bagKey] = {
          bagname: item.bagname || "No Bag",
          items: [],
        };
      }
      acc[bagKey].items.push(item);
      return acc;
    }, {});
  };

  // Fetch checklist data from the backend
  const fetchData = async () => {
    try {
      const response = await ApparatusChecklist.get(`/inprogressChecklist`, {
        params: { checklistinprogress: id, currentUserSiteId },
      });
      const data = response.data.data.taskInfo || [];
      setChecklist(data);
      checklistRef.current = data;

      const initialStatuses = {};
      const initialNotes = {};
      data.forEach((item) => {
        item.tasklist.forEach((task) => {
          if (task.taskstatus === "Pass") initialStatuses[task.taskinprogressid] = "Pass";
          else if (task.taskstatus === "Fail") initialStatuses[task.taskinprogressid] = "Fail";
          else if (task.taskstatus === "Monitor") initialStatuses[task.taskinprogressid] = "Monitor";
          else initialStatuses[task.taskinprogressid] = null;
          initialNotes[task.taskinprogressid] = task.tasknote || "";
        });
      });
      setTaskStatuses(initialStatuses);
      setTaskNotes(initialNotes);

      if (data.length > 0) {
        const [first] = data;
        setPageTitle(first.checklistname || "Checklist");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, currentUserSiteId]);

  useEffect(() => {
    checklistRef.current = checklist;
  }, [checklist]);

  // Socket connection for real-time updates
  useEffect(() => {
    const socketEndpoint = process.env.REACT_APP_Site_URL || "http://localhost:3001";
    socketRef.current = io(socketEndpoint);
    socketRef.current.emit("joinChecklist", id);

    socketRef.current.on(`checklistUpdate:${id}`, (data) => {
      //console.log("Received real-time update:", data);
      updateLocalChecklist(data.taskInfo);
    });

    socketRef.current.on(`checklistClosed:${id}`, (data) => {
      alert("This checklist has been finalized and closed for all users.");
      setFinalized(true);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [id]);

  // Group checklist items by taskgroupname
  const groupedChecklist = useMemo(() => {
    return checklist.reduce((acc, item) => {
      const group = item.taskgroupname || "No Group";
      if (!acc[group]) acc[group] = [];
      acc[group].push(item);
      return acc;
    }, {});
  }, [checklist]);

  // Toggle collapsed state for a group
  const toggleGroup = (groupName) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  // Toggle collapsed state for a bag
  const toggleBag = (groupName, bagKey) => {
    const uniqueBagKey = `${groupName}-${bagKey}`;
    setCollapsedBags((prev) => ({
      ...prev,
      [uniqueBagKey]: !prev[uniqueBagKey],
    }));
  };

  // Merge real-time socket updates into local checklist state
  const updateLocalChecklist = (updatedTasks) => {
    if (!checklistRef.current || checklistRef.current.length === 0) {
      console.warn("Checklist is empty, re-fetching data.");
      fetchData();
      return;
    }
    const updated = checklistRef.current.map((item) => ({
      ...item,
      tasklist: item.tasklist.map((task) => {
        const updatedTask = updatedTasks.find(
          (u) => u.taskinprogressid === task.taskinprogressid
        );
        return updatedTask
          ? {
              ...task,
              taskstatus: updatedTask.taskstatus,
              updatedBy: updatedTask.username,
              tasknote: updatedTask.tasknote || "",
            }
          : task;
      }),
    }));
    setChecklist(updated);

    const newStatuses = { ...taskStatuses };
    const newNotes = { ...taskNotes };
    updatedTasks.forEach((row) => {
      if (row.taskstatus === "Pass") newStatuses[row.taskinprogressid] = "Pass";
      else if (row.taskstatus === "Fail") newStatuses[row.taskinprogressid] = "Fail";
      else if (row.taskstatus === "Monitor") newStatuses[row.taskinprogressid] = "Monitor";
      else newStatuses[row.taskinprogressid] = null;
      newNotes[row.taskinprogressid] = row.tasknote || "";
    });
    setTaskStatuses(newStatuses);
    setTaskNotes(newNotes);
  };

  // Update task status (Pass/Monitor/Fail) and save update
  const handleToggleStatus = async (taskId, newStatus) => {
    setTaskStatuses((prev) => ({ ...prev, [taskId]: newStatus }));

    const updatedChecklist = checklist.map((item) => ({
      ...item,
      tasklist: item.tasklist.map((t) =>
        t.taskinprogressid === taskId
          ? { ...t, taskstatus: newStatus, updatedBy: `${currentFirstName} ${currentLastName}` }
          : t
      ),
    }));
    setChecklist(updatedChecklist);
    checklistRef.current = updatedChecklist;

    try {
      const formData = new FormData();
      formData.append("taskinprogressid", taskId);
      formData.append("taskstatus", newStatus);
      formData.append("tasknote", taskNotes[taskId] || "");
      formData.append("currentUserId", currentUserId);
      formData.append("currentUserSiteId", currentUserSiteId);
      formData.append("currentFirstName", currentFirstName);
      formData.append("currentLastName", currentLastName);
      formData.append("checklistInProgressId", id);
      await ApparatusChecklist.post(`/inprogressTaskUpdate`, formData);
    } catch (err) {
      console.error("Error updating task:", err);
    }
  };

  // Toggle note text area visibility for a task
  const handleToggleNoteArea = (taskId) => {
    setShowNoteArea((prev) => ({ ...prev, [taskId]: !prev[taskId] }));
  };

  // Update note value as user types
  const handleNoteChange = (taskId, value) => {
    setTaskNotes((prev) => ({ ...prev, [taskId]: value }));
  };

  // Save note to the server
  const handleSaveNote = async (taskId) => {
    try {
      const currentStatus = taskStatuses[taskId] || "";
      const noteText = taskNotes[taskId] || "";
      const formData = new FormData();
      formData.append("taskinprogressid", taskId);
      formData.append("taskstatus", currentStatus);
      formData.append("tasknote", noteText);
      formData.append("currentUserId", currentUserId);
      formData.append("currentUserSiteId", currentUserSiteId);
      formData.append("currentFirstName", currentFirstName);
      formData.append("currentLastName", currentLastName);
      formData.append("checklistInProgressId", id);
      await ApparatusChecklist.post(`/inprogressTaskUpdate`, formData);
      setShowNoteArea((prev) => ({ ...prev, [taskId]: false }));
    } catch (err) {
      console.error("Error saving note:", err);
    }
  };

  // Finalize the checklist (submit it) if all tasks are completed
  const handleFinalize = async () => {
    const incomplete = Object.values(taskStatuses).some(
      (status) => status === null || status === ""
    );
    if (incomplete) {
      alert("Please complete all tasks before finalizing the checklist.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("checklistInProgressId", id);
      formData.append("currentUserSiteId", currentUserSiteId);
      const response = await ApparatusChecklist.post(`/submitchecklistNew`, formData);
      if (response.data.status === "success") {
        alert("Checklist finalized successfully.");
        setFinalized(true);
      }
    } catch (err) {
      console.error("Error finalizing checklist:", err);
      alert("Error finalizing checklist.");
    }
  };

  if (!checklist) {
    return <div className="p-4">Loading checklist...</div>;
  }

  return (
    <div className=" mx-auto p-4">
      {/* Page Header
      <div className="mb-4">
        <h1 className="text-2xl font-bold">Shift Checks Inc</h1>
        <p className="text-gray-500">Rescue 1 Truck Checks</p>
      </div>
 */}

      {Object.entries(groupedChecklist).map(([groupName, items]) => {
        // 1. Separate "custom tasks" (no itemid) from real items
        const realItems = items.filter((itm) => itm.itemid);
        const customTasks = items.filter((itm) => !itm.itemid);

        return (
          <div key={groupName} className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
            {/* Group Header with collapsible chevron */}
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleGroup(groupName)}
            >
              <h2 className="text-xl font-semibold">{groupName}</h2>
              {collapsedGroups[groupName] ? (
                <ChevronDownIcon className="h-5 w-5 text-gray-600" />
              ) : (
                <ChevronUpIcon className="h-5 w-5 text-gray-600" />
              )}
            </div>
            <hr className="my-2" />

            {!collapsedGroups[groupName] && (
              <>
                {/* Show bag-grouped items first */}
                {Object.entries(groupItemsByBag(realItems)).map(([bagKey, bagGroup]) => {
                  const uniqueBagKey = `${groupName}-${bagKey}`;
                  const isBagCollapsed = collapsedBags[uniqueBagKey];
                  return (
                    <div key={uniqueBagKey} className="mb-6">
                      {/* Bag header with collapsible chevron */}
                      <div
                        className="flex justify-between items-center cursor-pointer mb-2"
                        onClick={() => toggleBag(groupName, bagKey)}
                      >
                        <h3 className="font-semibold text-base">{bagGroup.bagname}</h3>
                        {isBagCollapsed ? (
                          <ChevronDownIcon className="h-4 w-4 text-gray-600" />
                        ) : (
                          <ChevronUpIcon className="h-4 w-4 text-gray-600" />
                        )}
                      </div>

                      {/* If the bag is not collapsed, render the items/tasks */}
                      {!isBagCollapsed && (
                        <>
                          {bagGroup.items.map((item) => (
                            <div key={item.itemid} className="mb-4">
                              {item.name && (
                                <p className="font-medium mb-2 text-lg">
                                  {item.name} {item.amount ? `(x${item.amount})` : ""}
                                </p>
                              )}
                              {item.tasklist.map((task) => {
                                const taskId = task.taskinprogressid;
                                const userStatus = taskStatuses[taskId];
                                const noteVisible = showNoteArea[taskId] || false;
                                return (
                                  <div key={taskId} className="border-b last:border-b-0 py-3 pl-6">
                                    {/* Top row: Task details and icons */}
                                    <div className="flex justify-between items-start mb-2">
                                      <div>
                                        <p className="font-semibold">{task.taskname}</p>
                                        {task.taskdescription && (
                                          <p className="text-sm text-gray-500">
                                            {task.taskdescription}
                                          </p>
                                        )}
                                        {task.updatedBy && (
                                          <p className="text-xs text-red-500 italic mt-1">
                                            Last updated by: {task.updatedBy}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex items-center space-x-2 border border-gray-300 rounded-full px-3 py-1">
                                        <button onClick={() => handleToggleNoteArea(taskId)}>
                                          <ChatBubbleOvalLeftIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
                                        </button>
                                        <button onClick={() => console.log("Open camera for", taskId)}>
                                          <CameraIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
                                        </button>
                                      </div>
                                    </div>

                                    {/* Note area */}
                                    {noteVisible && (
                                      <div className="mt-2">
                                        <textarea
                                          className="w-full border rounded p-1 text-sm"
                                          rows={3}
                                          placeholder="Type your note here..."
                                          value={taskNotes[taskId] || ""}
                                          onChange={(e) => handleNoteChange(taskId, e.target.value)}
                                        />
                                        <button
                                          className="mt-1 px-3 py-1 text-sm font-semibold rounded bg-blue-500 text-white hover:bg-blue-600"
                                          onClick={() => handleSaveNote(taskId)}
                                        >
                                          Save Note
                                        </button>
                                      </div>
                                    )}

                                    {/* Bottom row: Pass / Monitor / Fail buttons */}
                                    <div className="mt-3 flex gap-2">
                                      <button
                                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                          userStatus === "Pass"
                                            ? "bg-green-500 text-white border-green-500"
                                            : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
                                        }`}
                                        onClick={() => handleToggleStatus(taskId, "Pass")}
                                      >
                                        Pass
                                      </button>
                                      <button
                                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                          userStatus === "Monitor"
                                            ? "bg-yellow-400 text-white border-yellow-400"
                                            : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
                                        }`}
                                        onClick={() => handleToggleStatus(taskId, "Monitor")}
                                      >
                                        Monitor
                                      </button>
                                      <button
                                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                          userStatus === "Fail"
                                            ? "bg-red-500 text-white border-red-500"
                                            : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
                                        }`}
                                        onClick={() => handleToggleStatus(taskId, "Fail")}
                                      >
                                        Fail
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  );
                })}

                {/* Now handle "custom tasks" that have no itemid */}
                {customTasks.length > 0 && (
                  <div className="mt-6">
                    <h3 className="font-semibold text-base mb-2">Custom Tasks</h3>
                    {customTasks.map((customItem) => {
                      // Each customItem has a tasklist with tasks
                      // We'll just render them similarly
                      return customItem.tasklist.map((task) => {
                        const taskId = task.taskinprogressid;
                        const userStatus = taskStatuses[taskId];
                        const noteVisible = showNoteArea[taskId] || false;
                        return (
                          <div key={taskId} className="border-b last:border-b-0 py-3 pl-6">
                            <div className="flex justify-between items-start mb-2">
                              <div>
                                <p className="font-semibold">{task.taskname}</p>
                                {task.taskdescription && (
                                  <p className="text-sm text-gray-500">{task.taskdescription}</p>
                                )}
                                {task.updatedBy && (
                                  <p className="text-xs text-red-500 italic mt-1">
                                    Last updated by: {task.updatedBy}
                                  </p>
                                )}
                              </div>
                              <div className="flex items-center space-x-2 border border-gray-300 rounded-full px-3 py-1">
                                <button onClick={() => handleToggleNoteArea(taskId)}>
                                  <ChatBubbleOvalLeftIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
                                </button>
                                <button onClick={() => console.log("Open camera for", taskId)}>
                                  <CameraIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
                                </button>
                              </div>
                            </div>

                            {noteVisible && (
                              <div className="mt-2">
                                <textarea
                                  className="w-full border rounded p-1 text-sm"
                                  rows={3}
                                  placeholder="Type your note here..."
                                  value={taskNotes[taskId] || ""}
                                  onChange={(e) => handleNoteChange(taskId, e.target.value)}
                                />
                                <button
                                  className="mt-1 px-3 py-1 text-sm font-semibold rounded bg-blue-500 text-white hover:bg-blue-600"
                                  onClick={() => handleSaveNote(taskId)}
                                >
                                  Save Note
                                </button>
                              </div>
                            )}

                            <div className="mt-3 flex gap-2">
                              <button
                                className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                  userStatus === "Pass"
                                    ? "bg-green-500 text-white border-green-500"
                                    : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
                                }`}
                                onClick={() => handleToggleStatus(taskId, "Pass")}
                              >
                                Pass
                              </button>
                              <button
                                className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                  userStatus === "Monitor"
                                    ? "bg-yellow-400 text-white border-yellow-400"
                                    : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
                                }`}
                                onClick={() => handleToggleStatus(taskId, "Monitor")}
                              >
                                Monitor
                              </button>
                              <button
                                className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 focus:outline-none ${
                                  userStatus === "Fail"
                                    ? "bg-red-500 text-white border-red-500"
                                    : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
                                }`}
                                onClick={() => handleToggleStatus(taskId, "Fail")}
                              >
                                Fail
                              </button>
                            </div>
                          </div>
                        );
                      });
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}

      {!finalized ? (
        <button
          className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4"
          onClick={handleFinalize}
        >
          Finalize
        </button>
      ) : (
        <div className="p-4 text-center text-lg font-semibold text-green-600">
          Checklist Finalized!
        </div>
      )}
    </div>
  );
};

export default ChecklistPage;
