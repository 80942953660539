import React, { useEffect } from "react";
import "./PopupNew.css";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../tailwind/dialog";

const PopupNew = ({
  isOpen,
  handleClose,
  content, // Dynamic Content (Can be anything)
  handleSubmit,
  showSubmitButton,
  headerText,
  submitButtonText,
  loading,
}) => {
  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      // Lock background scroll when open
      document.body.style.overflow = "hidden";
    } else {
      // Wait 300ms for closing animation to finish before resetting
      timeoutId = setTimeout(() => {
        document.body.style.overflow = "";
      }, 300);
    }
    // Cleanup if component unmounts or isOpen changes
    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="dialog-no-dark flex flex-col z-40 max-h-[calc(100vh-4rem)]"
    >
      <DialogTitle className="shrink-0">{headerText}</DialogTitle>
      <DialogBody className="mt-0 max-h-[calc(100vh-10rem)] overflow-y-auto">
        {content}
      </DialogBody>
      {showSubmitButton && (
        <DialogActions className="sticky bottom-0 bg-white p-3 flex justify-end">
          <button
            className="rounded-md bg-gray-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-gray-600"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="ml-2 rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-custom-blue-hover"
          >
            {submitButtonText}
          </button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupNew;
