import { useQuery } from "@tanstack/react-query";
import InventoryFinder from "../../apis/InventoryFinder";

const fetchMainItemPage = ({ queryKey }) => {
    const currentUserSiteId = queryKey[1];
    const id = queryKey[2];
  return InventoryFinder.get(`/selecteditem${id}`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useMainItemPageData = (currentUserSiteId,id) => {
  return useQuery(["mainItemPage", currentUserSiteId,id], fetchMainItemPage);
};
