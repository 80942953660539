// src/components/Popup/DynamicForm.jsx
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import TextareaAutosize from "react-textarea-autosize";

const DynamicForm = ({
  fields,
  formData, // Fully controlled form data from the parent
  onChangeField, // Callback: (fieldName, value) => void
  onSubmit,
  onCancel,
  errors = {},
  onOpenModal, // Called when a modal field is clicked (receives the field name)
  loading,
}) => {
  const handleChange = (e, fieldName) => {
    onChangeField(fieldName, e.target.value);
  };

  // For file fields, we update parent's state with the File object.
  const handleFileChange = (e, fieldName) => {
    if (e.target.files && e.target.files[0]) {
      onChangeField(fieldName, e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {loading && (
        <div className="text-center">
          <ClipLoader loading={loading} size={35} />
        </div>
      )}
      {fields.map((field) => {
        // Render textarea fields if inputType is "textarea"
        if (field.inputType === "textarea") {
          return (
            <div key={field.name}>
              <label className="block text-sm font-medium text-gray-700">
                {field.label}{" "}
                {field.required && <span className="text-red-500">*</span>}
              </label>

              <TextareaAutosize
                onChange={(e) => handleChange(e, field.name)}
          //      className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
    
          maxRows={10}
                type="text"
                placeholder={field.placeholder || ""}
                value={formData[field.name] || ""}
              />
              {errors[field.name] && (
                <p className="text-red-600 text-sm">{errors[field.name]}</p>
              )}
            </div>
          );
        }
// Inside DynamicForm.jsx, in the modal-based fields block:
if (field.isModal) {
  // If the field's value is an array (like for tags), convert it to a comma-separated string of labels.
  const fieldValue = Array.isArray(formData[field.name])
    ? formData[field.name].map((tag) => tag.label).join(", ")
    : formData[field.name] || "";
  return (
    <div key={field.name}>
      <label className="block text-sm font-medium text-gray-700">
        {field.label}{" "}
        {field.required && <span className="text-red-500">*</span>}
      </label>
      <input
        type="text"
        readOnly
        placeholder={field.placeholder || ""}
        value={fieldValue}
        onClick={() => onOpenModal && onOpenModal(field.name)}
        className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
      />
      {errors[field.name] && (
        <p className="text-red-600 text-sm">{errors[field.name]}</p>
      )}
    </div>
  );
}


        // File input fields
        if (field.type === "file") {
          return (
            <div key={field.name}>
              <label className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, field.name)}
                className="mt-1 block w-full"
              />
              {formData[field.name] && (
                <div className="mt-2">
                  <img
                    src={URL.createObjectURL(formData[field.name])}
                    alt="Preview"
                    className="h-20 w-20 rounded-md object-cover"
                  />
                </div>
              )}
              {errors[field.name] && (
                <p className="text-red-600 text-sm">{errors[field.name]}</p>
              )}
            </div>
          );
        }

        // Default input fields (text, number, etc.)
        return (
          <div key={field.name}>
            <label className="block text-sm font-medium text-gray-700">
              {field.label}{" "}
              {field.required && <span className="text-red-500">*</span>}
            </label>
            <input
              type={field.inputType || "text"}
              placeholder={field.placeholder || ""}
              value={formData[field.name] || ""}
              onChange={(e) => handleChange(e, field.name)}
              className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
              />
            {errors[field.name] && (
              <p className="text-red-600 text-sm">{errors[field.name]}</p>
            )}
          </div>
        );
      })}
   {/*   <div className="flex justify-end space-x-2 pt-4">
        <button
          type="button"
          onClick={onCancel}
          className="rounded-md bg-gray-500 px-3 py-2 text-white hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="rounded-md bg-blue-600 px-3 py-2 text-white hover:bg-blue-700"
        >
          Save
        </button>
      </div>*/}
    </form>
  );
};

export default DynamicForm;
