// src/pages/EditLocationPage.jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { useAuth } from "../../context/AuthContext";
import DynamicForm from "../Popup/DynamicForm";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import ClipLoader from "react-spinners/ClipLoader";
import SelectionModal from "../Popup/SelectionModal";
import { usePageTitle } from "../Navbar/PageTitleContext";

const EditLocationPage = () => {
  const { id } = useParams(); // location id from URL
  const { currentUserSiteId } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);       // Loading for fetching data
  const [formLoading, setFormLoading] = useState(false); // Loading for form submission & delete
  const [errors, setErrors] = useState({});



    // set page title
    const { setPageTitle } = usePageTitle("");
      setPageTitle("Edit Location");


  // Fully controlled form data.
  const [formData, setFormData] = useState({
    name: "",
    displayOrder: "",
    status: "",
    type: "",
    selectedImage: null, // Holds a File object if a new image is chosen.
  });

  // activeModal holds the field name when a modal field is clicked.
  const [activeModal, setActiveModal] = useState(null);

  // Modal options for modal-based fields.
  const modalOptions = {
    status: [
      { value: "IN-SERVICE", label: "IN-SERVICE" },
      { value: "OUT-OF-SERVICE", label: "OUT-OF-SERVICE" },
      { value: "N/A", label: "N/A" },
    ],
    type: [
      { value: "Vehicle", label: "Vehicle" },
      { value: "Storage", label: "Storage" },
    ],
  };

  // Field configuration for DynamicForm.
  const fields = [
    { name: "name", label: "Name", required: true, placeholder: "Enter name" },
    {
      name: "displayOrder",
      label: "Display Order",
      inputType: "number",
      placeholder: "Enter display order",
    },
    {
      name: "selectedImage",
      label: "Add Image",
      type: "file",
    },
    {
      name: "status",
      label: "Status",
      isModal: true,
      required: true,
      placeholder: "Select Status",
    },
    {
      name: "type",
      label: "Type",
      isModal: true,
      required: true,
      placeholder: "Select Type",
    },
  ];

  // Fetch the location data on component mount.
  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      try {
        const response = await ApparatusFinder.get(`/getApparatus/${id}`, {
          params: { currentUserSiteId },
        });
        if (!response.data.data.apparatus) {
            navigate("/notFound");
            return;
        }
        const data = response.data.data.apparatus;
        setFormData({
          name: data.name || "",
          displayOrder: data.displayorder || "",
          status: data.status || "",
          type: data.type || "",
          selectedImage: null, // We don't pre-load a file; you might show an image preview separately if desired.
        });
      } catch (err) {
        if (err.response && (err.response.status === 404 || err.response.status === 403)) {
          navigate("/notFound");
        } else {
          notify("Failed to fetch location data", "error");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [id, currentUserSiteId, navigate]);

  // Callback for non-modal field changes.
  const onChangeField = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  // Callback when a modal field is clicked.
  const onOpenModal = (fieldName) => {
    setActiveModal(fieldName);
  };

  // When the user selects an option from the modal.
  const handleModalConfirm = (selected) => {
    const value = typeof selected === "string" ? selected : selected.label;
    setFormData((prev) => ({ ...prev, [activeModal]: value }));
    setActiveModal(null);
  };

  // Handle form submission to update the location.
  const handleSubmit = async () => {
    setFormLoading(true);
    setErrors({});
    try {
      if (!formData.name) {
        setErrors((prev) => ({ ...prev, name: "Name is required" }));
        setFormLoading(false);
        return;
      }
      if (!formData.status) {
        setErrors((prev) => ({ ...prev, status: "Status is required" }));
        setFormLoading(false);
        return;
      }
      if (!formData.type) {
        setErrors((prev) => ({ ...prev, type: "Type is required" }));
        setFormLoading(false);
        return;
      }
      const dataForm = new FormData();
      dataForm.set("apparatusId", id);
      dataForm.set("name", formData.name);
      dataForm.set("status", formData.status);
      dataForm.set("apparatusType", formData.type);
      dataForm.set("currentUserSiteId", currentUserSiteId);
      dataForm.set("appartusOrder", formData.displayOrder || "");
      if (formData.selectedImage) {
        dataForm.append("file", formData.selectedImage);
      }
      await ApparatusFinder.post("/editApparatus", dataForm, {
        "content-type": "multipart/form-data",
      });
      notify("Location updated successfully!", "success");
      navigate("/location");
    } catch (err) {
      notify("Failed to update location. Please try again.", "error");
    } finally {
      setFormLoading(false);
    }
  };

  // Handle deletion of the location.
  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this location?");
    if (!confirmDelete) return;
    setFormLoading(true);
    try {
      await ApparatusFinder.post("/deleteApparatus", {
        apparatusId: id,
        oldImgPath: "", // Use the existing image path if needed; adjust if you store it.
        currentUserSiteId,
      });
      notify("Location deleted successfully!", "success");
      navigate("/location");
    } catch (err) {
      notify("Failed to delete location. Please try again.", "error");
    } finally {
      setFormLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/location");
  };

  if (loading) {
    return (
      <div className="p-4">
        <ClipLoader loading={loading} size={35} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <ToastNotifier />
      <div className="border p-4 rounded">
        <DynamicForm
          fields={fields}
          formData={formData}
          onChangeField={onChangeField}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          onOpenModal={onOpenModal}
          loading={formLoading}
        />
        {/* Delete Button */}
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleDelete}
            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
          >
            Delete Location
          </button>
        </div>
      </div>
      {/* Render the selection modal if a modal field is active */}
      {activeModal && (
        <SelectionModal
          isOpen={true}
          onClose={() => setActiveModal(null)}
          onConfirm={handleModalConfirm}
          options={modalOptions[activeModal] || []}
          label={`Select ${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`}
        />
      )}

<div className="sticky bottom-0 pb-5 bg-white border-t p-4 flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={handleSubmit}
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
    </div>
  );
};

export default EditLocationPage;
