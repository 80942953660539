// src/pages/NewInventoryItemPage.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InventoryFinder from "../../apis/InventoryFinder";
import { useAuth } from "../../context/AuthContext";
import DynamicForm from "../Popup/DynamicForm";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import ClipLoader from "react-spinners/ClipLoader";
import SelectionModal from "../Popup/SelectionModal";
import { usePageTitle } from "../Navbar/PageTitleContext";
import useIsMobile from "../../hooks/useIsMobile";
import { BsImage } from "react-icons/bs";
import PublicFolder from "../../apis/PublicFolder";

const NewInventoryItemPage = () => {
  const { currentUserSiteId, currentUserId, currentFirstName, currentLastName } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  // Set page title.
  const { setPageTitle } = usePageTitle("");
  setPageTitle("Add New Inventory Item");

  // Overall loading and error state.
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // New: State for fetched tag options.
  const [allTags, setAllTags] = useState([]);

  // Fetch tags from your API.
  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const response = await InventoryFinder.get("/getInventoryTags", {
          params: { currentUserSiteId },
        });
        const transformedTags = response.data.data.allTags.map((tag) => ({
          value: tag.categoryid,
          label: tag.name,
        }));
        setAllTags(transformedTags);
      } catch (err) {
        if (err.response && (err.response.status === 404 || err.response.status === 403)) {
          navigate("/notFound");
        } else {
          notify("Failed to fetch tags", "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchTags();
  }, [currentUserSiteId, navigate]);

  // ----------------------------
  // SEARCH BLOCK STATE & HANDLERS
  // ----------------------------
  const [lookUpItem, setLookUpItem] = useState("");
  const [lookUpResults, setLookUpResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await InventoryFinder.get("/getDefaultItems", {
        params: { lookUpItem, currentUserSiteId },
      });
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      setLookUpResults(response.data.data.lookUpResults);
      setSearchPerformed(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectedItemInfo = (id, name, manufacture, description, image) => {
    setSelectedItem({ id, name, manufacture, description, image });
  };

  const openSearchBox = () => {
    setSelectedItem(null);
    setLookUpItem("");
    setLookUpResults([]);
    setSearchPerformed(false);
  };

  // ----------------------------
  // COMMON FIELDS (displayed once an item is selected)
  // ----------------------------
  const commonFields = [
    {
      name: "tags",
      label: "Tags",
      isModal: true,
      required: false,
      placeholder: "Select Tags",
    },
    {
      name: "amount",
      label: "Amount",
      inputType: "number",
      required: true,
      placeholder: "Enter amount",
    },
    {
      name: "identificationName",
      label: "Identification Name",
      required: false,
      placeholder: "Enter identification name",
    },
    {
      name: "serialNumber",
      label: "Serial Number",
      required: false,
      placeholder: "Enter serial number",
    },
    {
      name: "size",
      label: "Size",
      required: false,
      placeholder: "Enter size",
    },
    {
      name: "status",
      label: "Status",
      isModal: true,
      required: true,
      placeholder: "Select status",
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      required: false,
      placeholder: "Enter manufacturer",
    },
    {
      name: "model",
      label: "Model",
      required: false,
      placeholder: "Enter model",
    },
    {
      name: "manufactureDate",
      label: "Manufacture Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "firstUseDate",
      label: "Date of First Use",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "mandatoryServiceDate",
      label: "Mandatory Service Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
    {
      name: "retirementDate",
      label: "Retirement Date",
      inputType: "date",
      required: false,
      placeholder: "Select date",
    },
  ];

  // State for common fields.
  const [commonFormData, setCommonFormData] = useState({
    tags: [], // Updated to an array for multiple selections.
    amount: "1",
    identificationName: "",
    serialNumber: "",
    size: "",
    status: "Safe to use", // default value for status
    manufacturer: "",
    model: "",
    manufactureDate: "",
    firstUseDate: "",
    mandatoryServiceDate: "",
    retirementDate: "",
  });

  // State for file input.
  const [selectedImage, setSelectedImage] = useState(null);

  // ----------------------------
  // MODAL SUPPORT FOR COMMON FIELDS
  // ----------------------------
  // For non-tags modals, we still use these static options.
  const [activeModal, setActiveModal] = useState(null);
  const modalOptions = {
    status: [
      { value: "Safe to use", label: "Safe to use" },
      { value: "Monitor", label: "Monitor" },
      { value: "Not safe to use", label: "Not safe to use" },
      { value: "Missing", label: "Missing" },
      { value: "Retired", label: "Retired" },
    ],
  };

  const onChangeField = (fieldName, value) => {
    setCommonFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onOpenModal = (fieldName) => {
    setActiveModal(fieldName);
  };

  // For tags, onConfirm will now expect an array of selected tags.
  const handleModalConfirm = (selected) => {
    if (activeModal === "tags") {
      // For tags, if selected is an array of strings, convert them to tag objects from allTags.
      const updatedTags = Array.isArray(selected)
        ? selected.map((tag) =>
            typeof tag === "string"
              ? allTags.find((t) => String(t.value) === String(tag))
              : tag
          )
        : [];
      // Store the array of tag objects directly.
      setCommonFormData((prev) => ({ ...prev, tags: updatedTags }));
    } else {
      const value = typeof selected === "string" ? selected : selected.label;
      setCommonFormData((prev) => ({ ...prev, [activeModal]: value }));
    }
    setActiveModal(null);
  };



  // ----------------------------
  // FILE INPUT HANDLER
  // ----------------------------
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  // ----------------------------
  // CLEAR FORM HELPER
  // ----------------------------
  const clearForm = () => {
    setCommonFormData({
      tags: [],
      amount: "1",
      identificationName: "",
      serialNumber: "",
      size: "",
      status: "Safe to use",
          manufacturer: "",
      model: "",
      manufactureDate: "",
      firstUseDate: "",
      mandatoryServiceDate: "",
      retirementDate: "",
    });
    setSelectedImage(null);
  };

  // ----------------------------
  // VALIDATION (placeholder)
  // ----------------------------
  const validateForm = (values, rules) => {
    // Implement your validation logic here.
    return true;
  };

  const validationRules = {
    NewItem: {
      // Define your validation rules.
    },
  };

  // ----------------------------
  // SUBMISSION HANDLERS
  // ----------------------------
  // "Add" button: Submit and navigate away.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = { name: selectedItem ? selectedItem.name : "" };
    if (!validateForm(formValues, validationRules.NewItem)) {
      return;
    }
    setLoading(true);
    try {
      // Build a plain object payload
      const payload = {
        inventoryId: selectedItem.id,
        name: selectedItem.name,
        manufacture: selectedItem.manufacture || commonFormData.manufacturer,
        model: commonFormData.model,
        description: selectedItem.description || "",
        selectedAmount: commonFormData.amount,
        selectedIdentificationName: commonFormData.identificationName,
        selectedSerialNumber:
          commonFormData.serialNumber === "" ? null : commonFormData.serialNumber,
        selectedManufactureDate: commonFormData.manufactureDate,
        selectedFirstUseDate: commonFormData.firstUseDate,
        size: commonFormData.size,
        itemStatus: commonFormData.status,
        // Send an array of tag IDs
        selectedItemTags: commonFormData.tags.map(tag => tag.value),
        currentUserId: currentUserId,
        currentUserSiteId: currentUserSiteId,
        currentFirstName: currentFirstName,
        currentLastName: currentLastName,
        SelectedRetirementDate: commonFormData.retirementDate,
        selectedMandatoryServiceDate: commonFormData.mandatoryServiceDate,
      };
  
      // If you need to include an image, you'll have to handle file uploads separately.
      // For example, you might convert the file to a base64 string or upload it via a separate endpoint.
      if (selectedImage) {
        // Here we're simply sending the file name (adjust as needed)
        payload.image = selectedImage.name;
      } else {
        payload.image = "";
      }
  
      const response = await InventoryFinder.post("/addNewItem", payload);

      clearForm();
      openSearchBox();
      notify("Item added successfully!", "success");
      setLoading(false);
      navigate(`/inventory/${response.data.data.inventory.itemid}/itempage`);
    } catch (err) {
      setLoading(false);
    }
  };
  

  // "Save & Add Another" button: Submit but keep form open for another entry.
  const handleAddNewItemAndAddAnother = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = { name: selectedItem ? selectedItem.name : "" };
    if (!validateForm(formValues, validationRules.NewItem)) {
      return;
    }
    setLoading(true);
    try {
      // Build a plain object payload
      const payload = {
        inventoryId: selectedItem.id,
        name: selectedItem.name,
        manufacture: selectedItem.manufacture || commonFormData.manufacturer,
        model: commonFormData.model,
        description: selectedItem.description || "",
        selectedAmount: commonFormData.amount,
        selectedIdentificationName: commonFormData.identificationName,
        selectedSerialNumber:
          commonFormData.serialNumber === "" ? null : commonFormData.serialNumber,
        selectedManufactureDate: commonFormData.manufactureDate,
        selectedFirstUseDate: commonFormData.firstUseDate,
        size: commonFormData.size,
        itemStatus: commonFormData.status,
        // Send an array of tag IDs
        selectedItemTags: commonFormData.tags.map(tag => tag.value),
        currentUserId: currentUserId,
        currentUserSiteId: currentUserSiteId,
        currentFirstName: currentFirstName,
        currentLastName: currentLastName,
        SelectedRetirementDate: commonFormData.retirementDate,
        selectedMandatoryServiceDate: commonFormData.mandatoryServiceDate,
      };
  
      // If you need to include an image, you'll have to handle file uploads separately.
      // For example, you might convert the file to a base64 string or upload it via a separate endpoint.
      if (selectedImage) {
        // Here we're simply sending the file name (adjust as needed)
        payload.image = selectedImage.name;
      } else {
        payload.image = "";
      }
  
      const response = await InventoryFinder.post("/addNewItem", payload);
      
      clearForm();
      setCommonFormData((prev) => ({ ...prev, amount: "1", tags: [] }));
      setLoading(false);
      notify("Item added successfully!", "success");
    } catch (err) {
      setLoading(false);
    }
  };

  // ----------------------------
  // RENDER
  // ----------------------------
  return (
    <div className="p-4">
      <ToastNotifier />
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="border p-4 rounded space-y-4">
          {/* SEARCH BLOCK */}
          <div className="popupContainer space-y-4">
            {errors.search && (
              <p className="text-red-600 text-sm">{errors.search}</p>
            )}
            {!selectedItem ? (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Search For Item
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="text"
                      value={lookUpItem}
                      onChange={(e) => setLookUpItem(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleItemSearch(e);
                        }
                      }}
                      required
                      className="w-full rounded-md border border-gray-300 p-2 pr-20 text-sm focus:ring-2 focus:ring-custom-blue focus:border-custom-blue"
                      placeholder="Search For Item…"
                    />
                    <button
                      type="button"
                      onClick={handleItemSearch}
                      className="absolute inset-y-0 right-0 m-1 rounded bg-custom-blue px-3 text-white text-sm hover:bg-custom-blue-hover focus:ring-2 focus:ring-custom-blue"
                    >
                      Search
                    </button>
                  </div>
                </div>
                {/* SEARCH RESULTS */}
                <div className="max-h-80 overflow-auto space-y-2">
                  {lookUpResults.length > 0 ? (
                    lookUpResults.map((item) => (
                      <div
                        key={item.id}
                        onClick={() =>
                          handleSelectedItemInfo(
                            item.id,
                            item.name,
                            item.manufacture,
                            item.description,
                            item.image
                          )
                        }
                        className="flex items-center space-x-4 rounded border p-2 hover:bg-gray-50 cursor-pointer"
                      >
                        {item.image !== "0" ? (
                          <img
                            src={`${PublicFolder.baseURL}preview_${item.image}`}
                            alt=""
                            className="h-12 w-12 object-cover rounded"
                          />
                        ) : (
                          <BsImage size={40} className="text-gray-400" />
                        )}
                        <div>
                          <h3 className="font-medium">{item.name}</h3>
                          <p className="text-sm text-gray-600">
                            {item.manufacture}
                            {item.description && ` — ${item.description}`}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    searchPerformed &&
                    lookUpItem.trim() !== "" && (
                      <div className="text-center py-8 space-y-2">
                        <p className="text-gray-500">No Results Found</p>
                        <button
                          type="button"
                          onClick={openSearchBox}
                          className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
                        >
                          Create New
                        </button>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-4">
                <img
                  src={`${PublicFolder.baseURL}preview_${selectedItem.image}`}
                  alt=""
                  className="h-16 w-16 object-cover rounded"
                />
                <div className="flex-1">
                  <h3 className="font-medium">{selectedItem.name}</h3>
                  <p className="text-sm text-gray-600">
                    {selectedItem.manufacture}
                    {selectedItem.description && ` — ${selectedItem.description}`}
                  </p>
                </div>
                <button
                  type="button"
                  onClick={openSearchBox}
                  className="text-custom-blue hover:underline"
                >
                  Search Different
                </button>
              </div>
            )}
          </div>

          {/* COMMON FIELDS (shown only when an item is selected) */}
          {selectedItem && (
            <>
              <DynamicForm
                fields={commonFields}
                formData={commonFormData}
                onChangeField={onChangeField}
                onOpenModal={onOpenModal}
                errors={errors}
                loading={loading}
              />
            
            </>
          )}
        </div>

        {/* FOOTER BUTTONS */}
        <div className="sticky bottom-0 bg-white border-t p-4 flex justify-end space-x-2">
          <button
            type="button"
            onClick={() => navigate("/inventory")}
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
          >
            {loading ? "Loading..." : "Add"}
          </button>
          <button
            type="button"
            onClick={handleAddNewItemAndAddAnother}
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
          >
            {loading ? "Loading..." : "Save & Add Another"}
          </button>
        </div>
      </form>

      {/* Render the selection modal for common fields if active.
          For "tags", we pass the fetched allTags with multiple selection enabled.
      */}
{activeModal && (
  <SelectionModal
    isOpen={true}
    onClose={() => setActiveModal(null)}
    onConfirm={handleModalConfirm}
    options={activeModal === "tags" ? allTags : modalOptions[activeModal] || []}
    label={`Select ${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`}
    {...(activeModal === "tags"
      ? { multiple: true, defaultValue: Array.isArray(commonFormData.tags) ? commonFormData.tags : [] }
      : {}
    )}
  />
)}
    </div>
  );
};

export default NewInventoryItemPage;
