import React, { useState } from "react";
import InventoryFinder from "../../apis/InventoryFinder";
import { useParams } from "react-router-dom";

import PopupWeb from "../Popup/PopupNew";

import ClipLoader from "react-spinners/ClipLoader";

import Select from "react-select";
import { useAuth } from "../../context/AuthContext";

import { useMainItemListPageData } from "./fetchItemListPageData";

const ItemListPageEvent = () => {
  const { currentUserSiteId } = useAuth();
  const { id } = useParams();

  const { data, refetch } = useMainItemListPageData(currentUserSiteId, id);

  //reload page
  const [loading, setLoading] = useState(false);

  const [eventId, setEventId] = useState("");
  const [taskDesciption, setTaskDesciption] = useState("");

  const [selectedTaskId, setSelectedTaskId] = useState("");

  const [eventName, setEventName] = useState("");

  const [addChecklist, setAddChecklist] = useState([]);

  //note is required

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };

  //Popup Add Item Task
  const [isOpen2, setIsOpen2] = useState(false);
  const addTaskPopupClose = () => {
    setIsOpen2(!isOpen2);
  };

  //Popup  Item Task
  const [isOpen3, setIsOpen3] = useState(false);
  const addTaskPopupClose3 = () => {
    setIsOpen3(!isOpen3);
  };

  //Popup Edit Item Task
  const [isOpen4, setIsOpen4] = useState(false);
  const editTaskPopupClose3 = () => {
    setIsOpen4(!isOpen4);
    setIsOpen3(!isOpen3);
  };
  const editTaskPopupClose = () => {
    setIsOpen4(!isOpen4);
  };

  //Popup New Event
  const [isOpen5, setIsOpen5] = useState(false);
  const addEventPopup = () => {
    setIsOpen5(!isOpen5);
  };

  //Popup Add Item Task
  const [isOpen6, setIsOpen6] = useState(false);
  const addEventPopupName = () => {
    setEventName("");
    setIsOpen5(!isOpen5);
    setIsOpen6(!isOpen6);
  };
  const addEventPopupName2 = () => {
    setEventName("");
    setIsOpen5(false);
    setIsOpen6(false);
  };

  //Popup Add Item Task
  const [isOpen7, setIsOpen7] = useState(false);
  const editEventPopupName = () => {
    setTaskDesciption("");
    setIsOpen7(!isOpen7);
    setIsOpen(!isOpen1);
  };
  const editEventPopupName2 = () => {
    setTaskDesciption("");
    setIsOpen7(false);
    setIsOpen(false);
  };

  //submit new event
  const handleNewEventSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/itemEvent", {
        invid: id,
        eventName,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();
      addEventPopupName2();
    } catch (err) {
      setLoading(false);
    }
  };

  //edit event name
  const handleEventEditSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/editItemEvent", {
        eventId,
        eventName,
        invid: id,
        addChecklist,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();

      editEventPopupName2();
    } catch (err) {
      setLoading(false);
    }
  };

  //Remove Event
  const handleDeleteEventSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/itemEventDelete", {
        eventId,
        invid: id,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();
      togglePopup();
    } catch (err) {
      setLoading(false);
    }
  };
  //submit new task
  const handleNewTaskSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/itemTask", {
        eventId,
        taskDesciption,
        invid: id,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();
      addTaskPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  //submit new task
  const handlTaskEditSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/editItemTask", {
        selectedTaskId,
        taskDesciption,
        invid: id,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();
      editTaskPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  //Remove Task
  const handleDeleteTaskSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.post("/itemTaskDelete", {
        selectedTaskId,
        invid: id,
        currentUserSiteId,
      });
      setLoading(false);

      refetch();
      addTaskPopupClose3();
    } catch (err) {
      setLoading(false);
    }
  };

  const addPopup = (id, eventname) => {
    setIsOpen(!isOpen1);
    setEventId(id);
    setEventName(eventname);
  };

  const addTaskPopup = () => {
    setTaskDesciption("");
    setIsOpen2(!isOpen2);
    setIsOpen(!isOpen1);
  };

  //Task Details
  const addPopup2 = (id, taskDesciption) => {
    setIsOpen3(!isOpen3);
    setSelectedTaskId(id);
    setTaskDesciption(taskDesciption);
  };

  let currentEvent = null;

  let options = data?.data.data.checklistInfo.map(function (e) {
    // console.log(e);
    return {
      value: e.clid,
      label: e.checklistname + " - " + e.apparatusname,
    };
  });

  return (
    <>
      <div className="itemListEventHolder">
        <div className="addEventTask" onClick={addEventPopup}>
          +
        </div>

        <div className="eventListContainer">
          {data?.data.data.itemEvent &&
            data?.data.data.itemEvent.map((data, i) => {
              //loops 3 times idk why
          //    console.log(data);
              if (currentEvent === data.eventname) {
                return (
                  <div key={i}>
                    <div className="itemListTaskDescContainer">
                      <div className="itemListTaskDesc">
                        {data.taskdescription}
                      </div>
                      <div
                        className="additemListTaskDescBtn"
                        onClick={() =>
                          addPopup2(data.itemtaskid, data.taskdescription)
                        }
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                );
              } else {
                currentEvent = data.eventname;
                return (
                  <div key={i}>
                    <div className="itemFirstEventName">
                      <div className="itemListEventName">{data.eventname}</div>
                      <div
                        className="addItemTaskBtn"
                        onClick={() =>
                          addPopup(data.itemeventid, data.eventname)
                        }
                      >
                        +
                      </div>
                    </div>
                    {data.taskdescription ? (
                      <div className="itemListTaskDescContainer">
                        <div className="itemListTaskDesc">
                          {data.taskdescription}
                        </div>
                        <div
                          className="additemListTaskDescBtn"
                          onClick={() =>
                            addPopup2(data.itemtaskid, data.taskdescription)
                          }
                        >
                          Edit
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>
      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>{eventName}</b>
              </div>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={addTaskPopup}>
                    <b>Add New Task</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={editEventPopupName}>
                    <b>Edit Name</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div
                    className="addMemberBtn"
                    onClick={handleDeleteEventSubmit}
                  >
                    <b> Remove Event</b>
                  </div>
                </div>
              </div>
            </>
          }

          handleClose={togglePopup}
          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={  <b>{eventName}</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <input
                value={taskDesciption}
                onChange={(e) => setTaskDesciption(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Add task description"
              />
            </>
          }

          handleClose={addTaskPopupClose}
          isOpen={isOpen2}
          handleSubmit={handleNewTaskSubmit}
          showSubmitButton={true}
          headerText={  <b>New Task</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 
          
        />
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <>
          
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <input
                value={taskDesciption}
                onChange={(e) => setTaskDesciption(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Add task description"
              />
            </>
          }

          handleClose={editTaskPopupClose3}
          isOpen={isOpen4}
          handleSubmit={handlTaskEditSubmit}
          showSubmitButton={true}
          headerText={   <b>Edit Task</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 

        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
           
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={editTaskPopupClose3}>
                    <b> Edit Task</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div
                    className="addMemberBtn"
                    onClick={handleDeleteTaskSubmit}
                    type="submit"
                  >
                    <b> Remove Task</b>
                  </div>
                </div>
              </div>
            </>
          }

          handleClose={addTaskPopupClose3}
          isOpen={isOpen3}
          showSubmitButton={false}
          headerText={  <b>Task Description</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 

        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
          
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={addEventPopupName}>
                    <b>Create Item Event</b>
                  </div>
                </div>
              </div>
            </>
          }

          handleClose={addEventPopup}
          isOpen={isOpen5}
          showSubmitButton={false}
          headerText={ <b>Item Information</b>}

        />
      )}

      {isOpen6 && (
        <PopupWeb
          content={
            <>
       
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <input
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Add Event Name"
              />
            </>
          }

          handleClose={addEventPopupName}
          isOpen={isOpen6}
          handleSubmit={handleNewEventSubmit}
          showSubmitButton={true}
          headerText={  <b>New Event</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 
        />
      )}
      {isOpen7 && (
        <PopupWeb
          content={
            <>
          
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <input
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Add Event Name"
              />

              <div className="custom-select">
                <Select
                  options={options}
                  defaultValue={data?.data.data.taskitemevent.map(function (
                    data2
                  ) {
                    return {
                      value: data2.clid,
                      label: data2.checklistname + " - " + data2.apparatusname,
                    };
                  })}
                  name="addMembers"
                  className="mb-3"
                  isSearchable={false}
                  onChange={setAddChecklist}
                  isMulti
                />
              </div>
            </>
          }

          handleClose={editEventPopupName}
          isOpen={isOpen7}
          handleSubmit={handleEventEditSubmit}
          showSubmitButton={true}
          headerText={   <b>Edit Event Name</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
        />
      )}
    </>
  );
};

export default ItemListPageEvent;
