import React, { useState, useRef, useEffect } from "react";
import PopupWeb from "../Popup/PopupNew";
import SiteFinder from "../../apis/SiteFinder";
import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";
import { useMainSiteCategoryData } from "./fetchBagsData";
import { FaArrowRight } from "react-icons/fa";
import Select from "react-select";
import { notify } from "../../ToastNotifier";
import { BsImage } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PDFReportTemplate from "../Reports/PDFReportTemplate";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";

const MAX_PAGE_BUTTONS = 5;

function getVisiblePages(currentPage, totalPages) {
  if (totalPages <= MAX_PAGE_BUTTONS) {
    return [...Array(totalPages).keys()];
  }
  let start = Math.max(currentPage - 2, 0);
  let end = Math.min(start + MAX_PAGE_BUTTONS - 1, totalPages - 1);
  if (end - start < MAX_PAGE_BUTTONS - 1) {
    start = end - (MAX_PAGE_BUTTONS - 1);
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);
  const visiblePages = getVisiblePages(pageIndex, pageCount);

  return (
    <>
      <div className="block sm:hidden w-full mt-4">
        <div className="text-sm text-gray-700 mb-2">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>
        <div className="flex items-center justify-between">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>
          <span className="text-sm text-gray-700">
            Page {pageIndex + 1} of {pageCount}
          </span>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-row sm:items-center sm:justify-between w-full mt-4">
        <div className="text-sm text-gray-700">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>
        <div className="flex flex-wrap items-center gap-1 justify-end">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>
          {visiblePages[0] > 0 && (
            <>
              <button
                onClick={() => table.setPageIndex(0)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                1
              </button>
              <span className="px-2">...</span>
            </>
          )}
          {visiblePages.map((pageNum) => {
            const isCurrent = pageIndex === pageNum;
            return (
              <button
                key={pageNum}
                onClick={() => table.setPageIndex(pageNum)}
                disabled={isCurrent}
                className={`px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 ${
                  isCurrent ? "bg-teal-100 text-teal-700" : "text-gray-700"
                } disabled:opacity-80`}
              >
                {pageNum + 1}
              </button>
            );
          })}
          {visiblePages[visiblePages.length - 1] < pageCount - 1 && (
            <>
              <span className="px-2">...</span>
              <button
                onClick={() => table.setPageIndex(pageCount - 1)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                {pageCount}
              </button>
            </>
          )}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </>
  );
};

const BagsHeader = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentFirstName,
    currentLastName,
  } = useAuth();
  const navigate = useNavigate();

  const { data, refetch } = useMainSiteCategoryData(currentUserSiteId);
  const [bagList, setBagList] = useState([]);
  const [apparatusList, setApparatusList] = useState([]);
  // selectedCompartmentId is an array to match react-select's value shape.
  const [selectedCompartmentId, setSelectedCompartmentId] = useState([]);

  useEffect(() => {
    if (data) {
      setBagList(data?.data.data.bagsList);
      setApparatusList(data?.data.data.apparatusListNew);
    }
  }, [data]);

  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");
  const headers = { "content-type": "multipart/form-data" };
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen7, setIsOpen7] = useState(false);

  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };
  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };

  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };

  const apparatusoptions =
    data?.data.data.apparatusList.map((data) => ({
      value: data.id,
      label: data.name,
    })) || [];

  const combineOptions = (location, apparatusOptions) => {
    return [
      ...apparatusOptions
        .filter((option) => location.includes(option.value))
        .map((option) => ({ ...option, type: "Apparatus" })),
    ];
  };

  const updateSelectedOptions = (location, apparatusOptions) => {
    const combinedOptions = combineOptions(location, apparatusoptions);
    setSelectedOptions(combinedOptions);
  };

  const [inventoryLookUpItem, setInventoryLookUpItem] = useState("");
  const [locationFilter, setLocationFilter] = useState([]);

  useEffect(() => {
    const storedSearchQuery = localStorage.getItem("BagsSearchQuery");
    const storedLocationFilter = (
      localStorage.getItem("BagsLocationFilter") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    if (storedSearchQuery) {
      setInventoryLookUpItem(storedSearchQuery);
    }
    if (storedLocationFilter.length > 0) {
      setLocationFilter(storedLocationFilter);
    }
  }, []);

  useEffect(() => {
    if (apparatusoptions.length && locationFilter.length) {
      updateSelectedOptions(locationFilter, apparatusoptions);
    }
  }, [locationFilter, apparatusoptions.length]);

  const locationdefaultValues = apparatusoptions.filter((option) =>
    locationFilter.includes(option.value)
  );

  const [selectedBag, setSelectedBag] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");
  const [newDate, setNewDate] = useState();
  const [status, setStatus] = useState("Active");
  const [category, setCategory] = useState("");
  const [eventType, setEventType] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [errors, setErrors] = useState({});

  const validationRules = {
    newForm: {
      selectedBag: "Please enter a name",
      selectedCompartmentId: "Please select a location",
    },
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;
    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });
    setErrors(newErrors);
    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }
    return isValid;
  };

  const clearForm = () => {
    setSelectedImage(null);
    setSelectedCompartmentId([]);
    setLoading(false);
    setErrors({});
    setSelectedBag("");
    setCategory("");
    setEventType("");
    setNewDate("");
    setStatus("Active");
  };

  // Update handler to allow clearing the selection (unassign)
  const handleCompartmentChange = (e) => {
    // If the user clears the selection, e will be null.
    setSelectedCompartmentId(e ? [e.value] : []);
    setErrors({});
  };

  const [isOpen1, setIsOpen] = useState(false);
  // In togglePopup we convert cid to Number (if not null) so that it matches the options.
  const togglePopup = (name, bagid, cid, image) => {
    setIsOpen(!isOpen1);
    setSelectedBag(name);
    setSelectedBagId(bagid);
    setSelectedCompartmentId(cid ? [Number(cid)] : []);
    setOldImgPath(image);
  };

  const togglePopupClose = () => {
    setIsOpen(!isOpen1);
    clearForm();
  };

  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(true);
    setIsOpen(false);
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");
    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.imagepath, {
      id: "imagepath",
      cell: (info) =>
        info.getValue() ? (
          <div
            className="min-max-widthLogo"
            onClick={() => handleBagClick(info.row.original.bagid)}
          >
            <img
              src={PublicFolder.baseURL + "preview_" + info.getValue()}
              alt="Did Not Load"
              className="InventoryTableImg"
            />
          </div>
        ) : (
          <div
            className="min-max-widthLogo"
            onClick={() => handleBagClick(info.row.original.bagid)}
          >
            <BsImage size={50} />
          </div>
        ),
      header: () => <div className="min-max-widthLogo">Image</div>,
    }),
    columnHelper.accessor((row) => row.bagname, {
      id: "bagname",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => handleBagClick(info.row.original.bagid)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Bag Name</div>,
    }),
    columnHelper.accessor((row) => row.compartmentname, {
      id: "title",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => handleBagClick(info.row.original.bagid)}
        >
          {info.row.original.apparatus} - {info.row.original.compartmentname}
        </div>
      ),
      header: () => <div className="min-max-width">Location</div>,
    }),
    columnHelper.accessor((row) => row.compartmentname, {
      id: "Edit",
      cell: (info) => (
        <div
          onClick={() =>
            togglePopup(
              info.row.original.bagname,
              info.row.original.bagid,
              info.row.original.cid,
              info.row.original.imagepath
            )
          }
        >
          <BsThreeDotsVertical />
        </div>
      ),
      header: () => <div className="min-max-width">Edit</div>,
    }),
  ];

  const initialPage = parseInt(localStorage.getItem("BagsPage")) || 0;
  const table = useReactTable({
    data: bagList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } },
  });

  const pdfColumns = [
    { header: "Date", accessor: (row) => row.date1 },
    { header: "Title", accessor: (row) => row.title },
    { header: "Note", accessor: (row) => row.note },
    { header: "Status", accessor: (row) => row.status },
  ];

  const handleClearFilters = () => {
    setLocationFilter([]);
    localStorage.removeItem("BagsLocationFilter");
    localStorage.removeItem("BagsPage");
    localStorage.removeItem("BagsSearchQuery");
    setSelectedOptions([]);
    setInventoryLookUpItem("");
    refetch();
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    const searchQuery = inventoryLookUpItem;
    localStorage.setItem("BagsSearchQuery", searchQuery);
    localStorage.setItem("BagsPage", 0);
    table.setPageIndex(0);
    setInventoryLookUpItem(searchQuery);
    refetch();
  };

  const handleChangeLocation = (e) => {
    const LocationFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("BagsLocationFilter", LocationFilter);
    localStorage.setItem("BagsPage", 0);
    table.setPageIndex(0);
    setLocationFilter(LocationFilter);
    refetch();
  };

  const StatusdefaultValues = StatusOptions.filter((option) =>
    []
      .includes(option.value)
  );

  const optionsApparatus = apparatusList.map((data) => ({
    label: data.name,
    options: data.compartments.map((data2) => ({
      value: data2.cid,
      label: data2.compartmentname,
    })),
  }));

  const flatOptionsApparatus = optionsApparatus.flatMap(
    (group) => group.options
  );

  const bagLocationValues = flatOptionsApparatus.filter((option) =>
    selectedCompartmentId.includes(option.value)
  );

  const handleBagSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = {
      selectedBag,
      selectedCompartmentId,
    };
    if (!validateForm(formValues, validationRules.newForm)) {
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set("selectedBag", selectedBag);
      // If nothing is selected, send an empty string (to be interpreted as unassigned)
      formData.set(
        "selectedCompartmentId",
        selectedCompartmentId.length ? selectedCompartmentId[0] : ""
      );
      formData.set("currentUserSiteId", currentUserSiteId);
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        formData.append("file", selectedImage);
      }
      await SiteFinder.post("/addSiteBag", formData, headers);
      refetch();
      clearForm();
      newUpcomingPopupClose();
      notify("Location created successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to create location. Please try again.", "error");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = {
      selectedBag,
      selectedCompartmentId,
    };
    if (!validateForm(formValues, validationRules.newForm)) {
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set("selectedBagId", selectedBagId);
      formData.set("selectedBag", selectedBag);
      formData.set(
        "selectedCompartmentId",
        selectedCompartmentId.length ? selectedCompartmentId[0] : ""
      );
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("oldImgPath", oldImgPath);
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        formData.append("file", selectedImage);
      }
      await SiteFinder.post("/moveBag", formData, headers);
      refetch();
      clearForm();
      togglePopupClose();
      notify("Location updated successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to update location. Please try again.", "error");
    }
  };

  const handleBagClick = (bagid) => {
    localStorage.setItem("InventoryBagFilter", bagid);
    navigate("/inventory", { state: { from: "/bags" } });
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const removehandleFileInput = () => {
    setSelectedImage(null);
  };

  const [oldImgPath, setOldImgPath] = useState("");

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await SiteFinder.post(`/deleteBag`, {
        selectedBagId,
        oldImgPath,
        currentUserSiteId,
      });
      refetch();
      togglePopup22();
      notify("Location deleted successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to delete location. Please try again.", "error");
    }
  };

  return (
    <div className="flex flex-col">
      <div className=" top-[64px] bg-white border-b shadow-sm">
        <div className="filter-search-toggle-container">
          <div className={`filter-search-container`}>
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopupFilter}
            >
              <AdjustmentsHorizontalIcon
                aria-hidden="true"
                className=" mr-2 size-5 flex-none text-gray-400 group-hover:text-gray-500"
              />
              Filters
            </button>
            <PDFReportTemplate
              table={table}
              title="Event Report"
              reportDateRange="2024-01-01 to 2024-12-31"
              category="Inventory"
              members={["John Doe", "Jane Smith"]}
              filters={selectedOptions}
              logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
              pdfColumns={pdfColumns}
              includeDateRange={false}
              includeCategory={false}
              includeMembers={false}
              includeFilters={true}
            />
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={newUpcomingPopup}
            >
              New Bag
            </button>
            <div className="selected-options-display">
              <div className="selected-options-row">
                {selectedOptions.map((option, index) => (
                  <div key={index} className="selected-option">
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="search-input-container">
              <form onSubmit={handleSearchChange} className="search-input-container">
                <input
                  className="inventorySearchButton"
                  placeholder="Search Inventory"
                  type="search"
                  value={inventoryLookUpItem}
                  onChange={(e) => setInventoryLookUpItem(e.target.value)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto border-b z-1  border-gray-200 print:border-0 print:shadow-none max-h-[calc(100vh_-_290px)] sm:max-h-[calc(100vh_-_290px)]">
        {bagList ? (
          <>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
      </div>

      <div className="flex items-center justify-between bg-white px-2 py-2 sm:px-6 sm:rounded-b-lg">
        <Pagination table={table} />
      </div>

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleBagSubmit}>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setSelectedBag(e.target.value)}
                      value={selectedBag}
                      required
                    />
                    {errors.selectedBag && (
                      <div className="errorInputLabel">
                        {errors.selectedBag}
                      </div>
                    )}
                  </label>
                  <label className="NewInputLabel" data-domain="Location">
                    {/* Added isClearable to allow unassigning */}
                    <Select
                      isClearable
                      options={optionsApparatus}
                      onChange={handleCompartmentChange}
                      value={bagLocationValues}
                      name="addMembers"
                      className="basic-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.selectedCompartmentId && (
                      <div className="errorInputLabel">
                        {errors.selectedCompartmentId}
                      </div>
                    )}
                  </label>
                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                    </div>
                    <div className="imagePreviewContainer">
                      {selectedImage && (
                        <div className="imageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                          <button
                            type="button"
                            className="removeImageButton"
                            onClick={removehandleFileInput}
                          >
                            <RiCloseFill size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={newUpcomingPopupClose}
          isOpen={isOpen7}
          handleSubmit={handleBagSubmit}
          showSubmitButton={true}
          headerText={<b>New Bag</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>
              <button
                className="close-button text-sm"
                onClick={handleClearFilters}
              >
                Clear all
              </button>
              <label className="NewInputLabel123" data-domain="Assigned To Location">
                <Select
                  isMulti
                  options={apparatusoptions}
                  onChange={handleChangeLocation}
                  value={locationdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div>
                <form onSubmit={handleUpdate} className="userForm">
                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        onChange={(e) => setSelectedBag(e.target.value)}
                        value={selectedBag}
                        required
                      />
                      {errors.selectedBag && (
                        <div className="errorInputLabel">
                          {errors.selectedBag}
                        </div>
                      )}
                    </label>
                    <label className="NewInputLabel" data-domain="Location">
                      {/* Added isClearable to allow unassigning */}
                      <Select
                        isClearable
                        options={optionsApparatus}
                        onChange={handleCompartmentChange}
                        value={bagLocationValues}
                        name="addMembers"
                        className="basic-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {errors.selectedCompartmentId && (
                        <div className="errorInputLabel">
                          {errors.selectedCompartmentId}
                        </div>
                      )}
                    </label>
                    <div className="NewInputLabel" data-domain="Add Image">
                      <input
                        ref={fileRef}
                        onChange={handleFileInput}
                        type="file"
                        accept="image/*"
                        hidden
                      />
                      <div
                        onClick={() => fileRef.current.click()}
                        className="NewInputImage"
                      >
                        <RiImageAddFill size={30} />
                      </div>
                      <div className="imagePreviewContainer">
                        {selectedImage && (
                          <div className="imageHolder">
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              alt="imageDidNotLoad"
                              className="addImage"
                            />
                            <button
                              type="button"
                              className="removeImageButton"
                              onClick={removehandleFileInput}
                            >
                              <RiCloseFill size={20} />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="editApparatusBtnContainer">
                      <div className="editApparatusBtn" onClick={togglePopup2}>
                        <b>Delete</b>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          }
          handleClose={togglePopupClose}
          isOpen={isOpen1}
          handleSubmit={handleUpdate}
          showSubmitButton={true}
          headerText={<b>Edit Bag</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>
                  This bag will no longer be available and items in bag will be unassigned
                </b>
              </div>
            </>
          }
          handleClose={togglePopup22}
          isOpen={isOpen2}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={<b>Delete - {selectedBag}</b>}
          submitButtonText={loading ? "Loading..." : "Delete"}
        />
      )}
    </div>
  );
};

export default BagsHeader;
