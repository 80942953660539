import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useMainItemPageData } from "./fetchItemPageData";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import PublicFolder from "../../apis/PublicFolder";


const MAX_PAGE_BUTTONS = 5;

function getVisiblePages(currentPage, totalPages) {
  if (totalPages <= MAX_PAGE_BUTTONS) {
    return [...Array(totalPages).keys()]; // e.g. [0, 1, 2, ..., totalPages-1]
  }

  let start = Math.max(currentPage - 2, 0);
  let end = Math.min(start + MAX_PAGE_BUTTONS - 1, totalPages - 1);

  // Shift window if we don't have enough pages to fill the limit
  if (end - start < MAX_PAGE_BUTTONS - 1) {
    start = end - (MAX_PAGE_BUTTONS - 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  // Persist current page index in localStorage (optional)
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  // Calculate total rows (unpaginated)
  const totalRows = table.getPrePaginationRowModel().rows.length;
  // Calculate which rows are displayed
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  // Determine visible page numbers (for desktop)
  const visiblePages = getVisiblePages(pageIndex, pageCount);

  return (
    <>
      {/* --------------------- MOBILE VIEW --------------------- */}
      {/* Visible only on screens smaller than the 'sm' breakpoint */}
      <div className="block sm:hidden w-full mt-4">
        {/* Row count text */}
        <div className="text-sm text-gray-700 mb-2">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Minimal pagination controls in one row */}
        <div className="flex items-center justify-between">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* Page X of Y label */}
          <span className="text-sm text-gray-700">
            Page {pageIndex + 1} of {pageCount}
          </span>

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>

      {/* --------------------- DESKTOP VIEW --------------------- */}
      {/* Hidden on screens smaller than 'sm' */}
      <div className="hidden sm:flex sm:flex-row sm:items-center sm:justify-between w-full mt-4">
        {/* Left side: "Showing X - Y of Z" */}
        <div className="text-sm text-gray-700">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Right side: Full pagination controls */}
        <div className="flex flex-wrap items-center gap-1 justify-end">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* If the first visible page isn't the first page, show 1 + ellipses */}
          {visiblePages[0] > 0 && (
            <>
              <button
                onClick={() => table.setPageIndex(0)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                1
              </button>
              <span className="px-2">...</span>
            </>
          )}

          {/* Numbered Page Buttons */}
          {visiblePages.map((pageNum) => {
            const isCurrent = pageIndex === pageNum;
            return (
              <button
                key={pageNum}
                onClick={() => table.setPageIndex(pageNum)}
                disabled={isCurrent}
                className={`px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200
                  ${
                    isCurrent
                      ? "bg-teal-100 text-teal-700"
                      : "text-gray-700"
                  }
                  disabled:opacity-80`}
              >
                {pageNum + 1}
              </button>
            );
          })}

          {/* If the last visible page isn't the last page, show ellipses + last page */}
          {visiblePages[visiblePages.length - 1] < pageCount - 1 && (
            <>
              <span className="px-2">...</span>
              <button
                onClick={() => table.setPageIndex(pageCount - 1)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                {pageCount}
              </button>
            </>
          )}

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </>
  );
};
const ItemPageHistory = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();
  const { data, refetch } = useMainItemPageData(currentUserSiteId, id);
  const [itemHistory, setItemHistory] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);

  const [showImages, setShowImages] = useState({}); // Track which rows have images shown

  const history = useNavigate();

  useEffect(() => {
    setItemHistory(data?.data.data.itemHistory || []);

  }, [data]);

  useEffect(() => {
    const storedEventStatusFilter = localStorage.getItem("EventStatusFilters");
    if (storedEventStatusFilter) {
      setStatusFilter(JSON.parse(storedEventStatusFilter));
    }
  }, []);

  const toggleNav = () => {
    document.getElementById("topbar").classList.toggle("collapse");
    document.getElementById("sidebar").classList.toggle("collapse");
    document.getElementById("appContainer").classList.toggle("collapse");
    document.getElementById("appContainerHidden").classList.toggle("collapse");
  };

  const handleClearFilters = () => {
    setStatusFilter([]);
    localStorage.removeItem("EventStatusFilters");
    localStorage.removeItem("EventPage");
    refetch();
  };

  const toggleShowImages = (rowIndex) => {
    setShowImages((prevShowImages) => ({
      ...prevShowImages,
      [rowIndex]: !prevShowImages[rowIndex],
    }));
  };

  const columnHelper = createColumnHelper();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('date1', {
        header: 'Date',
        cell: info => (info.getValue() ? `${info.getValue()}` : 'No date'),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => (
          <>
            {info.getValue() || 'No type'}
            {info.row.original.checklistname ? ` - ${info.row.original.checklistname}` : ''}
          </>
        ),
      }),
      columnHelper.accessor('username', {
        header: 'User Name',
        cell: info => info.getValue() || '',
      }),
  
      columnHelper.accessor('notes', {
        header: 'Notes',
        cell: info => info.getValue() || '',
      }),
      columnHelper.accessor('tasklist', {
        id: 'tasklistTasks',

        header: 'Tasks',
        cell: info => (
          <>
            {(info.getValue() || []).map((task, index) => (
              <div key={index}>
                {task.taskstatus || 'No status'}
                {task.taskname ? ` - ${task.taskname}` : ''}
                {task.tasknote ? ` - ${task.tasknote}` : ''}
                {task.username ? ` - ${task.username}` : ''}

              </div>
            ))}
            {/*info.getValue().length > 0 && (
              <div>{info.getValue()[0].tasknote || ''}</div>
            )*/}
          </>
        ),
      }),
      columnHelper.accessor('tasklist', {
        id: 'tasklistImages',

        header: 'Images',
        cell: info => {
          const rowIndex = info.row.index;
          const hasImages = info.getValue().length > 0 && info.getValue()[0].photos && info.getValue()[0].photos.length > 0;
          return (
            <>
              {hasImages && (
                <>
                  <button onClick={() => toggleShowImages(rowIndex)}>
                    {showImages[rowIndex] ? 'Hide Images' : 'Show Images'}
                  </button>
                  {showImages[rowIndex] && (
                    <div>
                      <div>Images Attached [{info.getValue()[0].photos.length}]</div>
                      <div>
                        {info.getValue()[0].photos.map((photo, index) => (
                          <div key={index} className="ItemHistoryPhoto">
                            <img
                              src={`${PublicFolder.baseURL}preview_${photo.filename}`}
                              alt="Inspection"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          );
        },
      }),
    ],
    [showImages]
  );

  const table = useReactTable({
    data: itemHistory,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 25 } }, // Set initial page size and page index
  });

  const pdfColumns = [
    { header: "Date", accessor: row => row.date1 },
    { header: "Title", accessor: row => row.title },
    { header: "Note", accessor: row => row.note },
    { header: "Status", accessor: row => row.status },
  ];

  return (
    <div className="containerInventory">
   <div className="overflow-x-auto border-b z-1  border-gray-200 print:border-0 print:shadow-none max-h-[calc(100vh_-_290px)] sm:max-h-[calc(100vh_-_290px)]">
   {itemHistory.length > 0 ? (
                  <div className={`table-container ${isCollapsed ? "collapsed" : ""}`}>
                    <table className="custom-table">
                      <thead className="custom-table-header-container">
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th key={header.id} className="custom-table-header">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <td key={cell.id} className="custom-table-cell">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>No history available</div>
                )}
        </div>
  
        <div className="flex items-center justify-between bg-white px-2 py-2 sm:px-6 sm:rounded-b-lg">
          <Pagination table={table} />
        </div>

    </div>
  );
};

export default ItemPageHistory;
