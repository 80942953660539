import React, { useState, useRef, useEffect } from "react";

import PopupWeb from "../Popup/PopupNew";

import TrainingFinder from "../../apis/TrainingFinder";

import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";

import TextareaAutosize from "react-textarea-autosize";
import { AiOutlineMenu } from "react-icons/ai";

import { useTrainingData } from "./fetchTrainingData";

import Select from "react-select";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import {
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import PDFReportTemplate from "../Reports/PDFReportTemplate";

import SelectionModal from "../Popup/SelectionModal";

import {
  CameraIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";

const MAX_PAGE_BUTTONS = 5;

function getVisiblePages(currentPage, totalPages) {
  if (totalPages <= MAX_PAGE_BUTTONS) {
    return [...Array(totalPages).keys()];
  }
  let start = Math.max(currentPage - 2, 0);
  let end = Math.min(start + MAX_PAGE_BUTTONS - 1, totalPages - 1);
  if (end - start < MAX_PAGE_BUTTONS - 1) {
    start = end - (MAX_PAGE_BUTTONS - 1);
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  // Persist current page index in localStorage (optional)
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  // Calculate total rows (unpaginated)
  const totalRows = table.getPrePaginationRowModel().rows.length;
  // Calculate which rows are displayed
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  // Determine visible page numbers (for desktop)
  const visiblePages = getVisiblePages(pageIndex, pageCount);

  return (
    <>
      {/* --------------------- MOBILE VIEW --------------------- */}
      {/* Visible only on screens smaller than the 'sm' breakpoint */}
      <div className="block sm:hidden w-full mt-4 mb-10">
        {/* Row count text */}
        <div className="text-sm text-gray-700 mb-2">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Minimal pagination controls in one row */}
        <div className="flex items-center justify-between">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* Page X of Y label */}
          <span className="text-sm text-gray-700">
            Page {pageIndex + 1} of {pageCount}
          </span>

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>

      {/* --------------------- DESKTOP VIEW --------------------- */}
      {/* Hidden on screens smaller than 'sm' */}
      <div className="hidden sm:flex sm:flex-row sm:items-center sm:justify-between w-full mt-4">
        {/* Left side: "Showing X - Y of Z" */}
        <div className="text-sm text-gray-700">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Right side: Full pagination controls */}
        <div className="flex flex-wrap items-center gap-1 justify-end">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* If the first visible page isn't the first page, show 1 + ellipses */}
          {visiblePages[0] > 0 && (
            <>
              <button
                onClick={() => table.setPageIndex(0)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                1
              </button>
              <span className="px-2">...</span>
            </>
          )}

          {/* Numbered Page Buttons */}
          {visiblePages.map((pageNum) => {
            const isCurrent = pageIndex === pageNum;
            return (
              <button
                key={pageNum}
                onClick={() => table.setPageIndex(pageNum)}
                disabled={isCurrent}
                className={`px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200
                  ${isCurrent ? "bg-teal-100 text-teal-700" : "text-gray-700"}
                  disabled:opacity-80`}
              >
                {pageNum + 1}
              </button>
            );
          })}

          {/* If the last visible page isn't the last page, show ellipses + last page */}
          {visiblePages[visiblePages.length - 1] < pageCount - 1 && (
            <>
              <span className="px-2">...</span>
              <button
                onClick={() => table.setPageIndex(pageCount - 1)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                {pageCount}
              </button>
            </>
          )}

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </>
  );
};

const EventHeader = () => {
  const { currentUserSiteId } = useAuth();
  const { data, refetch } = useTrainingData(currentUserSiteId);
  const navigate = useNavigate();

  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    setEventList(data?.data.data.trainingList);
  }, [data]);

  //reload page
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [memberFilter, setMemberFilter] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [thruDateFilter, setThruDateFilter] = useState("");
  const [noteCategoryFilter, setNoteCategoryFilter] = useState([]);
  const [noteMemberFilter, setNoteMemberFilter] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isCategoryModalOpenNew, setIsCategoryModalOpenNew] = useState(false);
  const [isActiveModalOpenNew, setIsActiveModalOpenNew] = useState(false);

  const [isEventCategoryModalOpen, setIsEventCategoryModalOpen] =
    useState(false);
  const [isEventStatusModalOpen, setIsEventStatusModalOpen] = useState(false);
  const [isEventMemberModalOpen, setIsEventMemberModalOpen] = useState(false);
  const [isEventNoteCategoryModalOpen, setIsEventNoteCategoryModalOpen] =
    useState(false);
  const [isEventNoteMemeberModalOpen, setIsEventNoteMemeberModalOpen] =
    useState(false);

  const [isColumnsModalOpenNew, setIsColumnsModalOpenNew] = useState(false);

  //Popup create new events
  const [isOpen7, setIsOpen7] = useState(false);
  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
  };

  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
    // setIsOpen8(!isOpen8);
  };

  //Popup create new events
  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };

  const [columnVisibility, setColumnVisibility] = useState({
    members: false,
    notes: false,
    address: false,
  });

  const options =
    data?.data.data.categoryResults.map(function (data) {
      return {
        value: data.categoryid,
        label: data.name,
      };
    }) || [];

  const Memberoptions =
    data?.data.data.memberResults.map(function (data) {
      return {
        value: data.user_id,
        label: data.fullname,
      };
    }) || [];

  const NoteCategoryOptions =
    data?.data.data.noteCategoryResults.map(function (data) {
      return {
        value: data.categoryid,
        label: data.name,
      };
    }) || [];

  const NoteMemberOptions =
    data?.data.data.memberResults.map(function (data) {
      return {
        value: data.user_id,
        label: data.fullname,
      };
    }) || [];

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const combineOptions = (
    tags,
    members,
    inspections,
    options, // Tag options
    Memberoptions1, // Member options
    StatusOptions, // Inspection/Status options
    fromDate, // New addition for From Date
    thruDate, // New addition for Thru Date
    noteCategories, // Note Category options
    noteCategoryVals, // Stored note category filter values
    noteMembers, // Note Member options
    noteMemberVals // Stored note member filter values
  ) => {
    let combinedOptions = [];

    // Add date range filters first
    if (fromDate) {
      combinedOptions.push({
        value: fromDate,
        label: `From Date: ${fromDate}`,
        type: "Date",
      });
    }

    if (thruDate) {
      combinedOptions.push({
        value: thruDate,
        label: `Thru Date: ${thruDate}`,
        type: "Date",
      });
    }

    // For the rest of the filters, ensure both sides are strings before comparing.
    combinedOptions = [
      ...combinedOptions,
      ...options
        .filter((option) => tags.map(String).includes(String(option.value)))
        .map((option) => ({ ...option, type: "Tag" })),

      ...Memberoptions1.filter((option) =>
        members.map(String).includes(String(option.value))
      ).map((option) => ({ ...option, type: "Member" })),

      ...StatusOptions.filter((option) =>
        inspections.map(String).includes(String(option.value))
      ).map((option) => ({ ...option, type: "Inspection" })),

      ...noteCategories
        .filter((opt) =>
          (noteCategoryVals || []).map(String).includes(String(opt.value))
        )
        .map((opt) => ({ ...opt, type: "NoteCategory" })),

      ...noteMembers
        .filter((opt) =>
          (noteMemberVals || []).map(String).includes(String(opt.value))
        )
        .map((opt) => ({ ...opt, type: "NoteMember" })),
    ];

    return combinedOptions;
  };

  const updateSelectedOptions = (
    tags,
    members,
    inspections,
    fromDate,
    thruDate,
    noteCategoryVals,
    noteMemberVals
  ) => {
    const combinedOptions = combineOptions(
      tags,
      members,
      inspections,
      options, // Tag options
      Memberoptions, // Member options
      StatusOptions, // Inspection/Status options
      fromDate, // From Date
      thruDate, // Thru Date
      NoteCategoryOptions, // Note Category options
      noteCategoryVals,
      NoteMemberOptions, // Note Member options
      noteMemberVals
    );

    setSelectedOptions(combinedOptions);
  };
  //Input useStae
  useEffect(() => {
    const parseLocalArray = (key) => {
      const raw = localStorage.getItem(key);
      if (!raw) return [];
      try {
        const parsed = JSON.parse(raw);
        // If parsed is not an array, return it as a one-item array
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch {
        // Fallback for legacy comma‑separated values
        return raw
          .split(",")
          .filter(Boolean)
          .map((val) => (isNaN(val) ? val : Number(val)));
      }
    };
    const storedFromDateFilter =
      localStorage.getItem("EventFromDateFilter") || "";
    const storedThruDateFilter =
      localStorage.getItem("EventThruDateFilter") || "";

    const storedEventCategoryFilter = parseLocalArray("EventCategoryFilters");
    const storedEventStatusFilter = parseLocalArray("EventStatusFilters");
    const storedEventMemberFilter = parseLocalArray("EventMemberFilter");
    const storedEventNoteCategoryFilter = parseLocalArray(
      "EventNoteCategoryFilter"
    );
    const storedEventNoteMemberFilter = parseLocalArray(
      "EventNoteMemberFilter"
    );

    if (storedEventStatusFilter.length)
      setStatusFilter(storedEventStatusFilter);
    if (storedEventCategoryFilter.length)
      setCategoryFilter(storedEventCategoryFilter);
    if (storedEventMemberFilter.length)
      setMemberFilter(storedEventMemberFilter);
    if (storedEventNoteCategoryFilter.length)
      setNoteCategoryFilter(storedEventNoteCategoryFilter);
    if (storedEventNoteMemberFilter.length)
      setNoteMemberFilter(storedEventNoteMemberFilter);

    if (storedFromDateFilter) setFromDateFilter(storedFromDateFilter);
    if (storedThruDateFilter) setThruDateFilter(storedThruDateFilter);
  }, []);

  useEffect(() => {
  
    // Ensure that options are available before attempting to update selected options
    if (options.length && Memberoptions.length && StatusOptions.length) {
  
      // Always update selected options even if the filters are empty
      updateSelectedOptions(
        categoryFilter,
        memberFilter,
        statusFilter,
        fromDateFilter,
        thruDateFilter,
        noteCategoryFilter,
        noteMemberFilter
      );
    }
  }, [
    categoryFilter,
    memberFilter,
    statusFilter,
    fromDateFilter,
    thruDateFilter,
    noteCategoryFilter,
    noteMemberFilter,
    options.length,
    Memberoptions.length,
    StatusOptions.length,
  ]);
  

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");
  const [trainingAddress, setTrainingAddress] = useState("");

  const [trainer] = useState([]);

  const [newDate, setNewDate] = useState();

  const [status, setStatus] = useState("Active");

  //note

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      newDate: "Please select a date",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setTrainingName("");
    setTrainingDesc("");
    setCategory("");
    setTrainingAddress("");
    setEventType("");
    setNewDate("");
    setStatus("Active");
  };

  const handleNewTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingName,
      category,
      newDate,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
      //eslint-disable-next-line
      const response = await TrainingFinder.post("/newtraingevent", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainingAddress: trainingAddress,
        trainer: trainer,
        categoryid: category,
        eventType: eventType,
        date: newDate,
        status: status,
        currentUserSiteId,
      });

      newUpcomingPopupClose();
      clearForm();
      refetch();
      notify("Event created successfully!", "success"); // Show success notification
    } catch (err) {
      setLoading(false);
      notify("Failed to create event. Please try again.", "error"); // Show error notification
    }
  };

  const selecttraining = (id) => {
    history(`/eventinfo/${id}`);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.date1, {
      id: "date1",
      label: "Date",
      enableHiding: false,
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Date</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.training_name, {
      id: "training_name",
      label: "Event Name",
      enableHiding: false,

      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Event Name</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.description, {
      id: "description",
      label: "Description",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Description</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.category, {
      id: "category",
      label: "Category",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Category</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.status, {
      id: "status",
      label: "Status",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Status</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.address, {
      id: "address",
      label: "Address",

      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Address</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.attended, {
      id: "attended",
      label: "Member Count",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Member Count</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.notecount, {
      id: "notecount",
      label: "Note Count",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Note Count</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.itemcount, {
      id: "itemcount",
      label: "Item Count",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Item Count</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.photocount, {
      id: "photocount",
      label: "Photos",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Photos</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor("members", {
      id: "members",
      label: "Members",
      header: ({ column }) => (
        <div
          className="w-full h-full flex items-center justify-between cursor-pointer px-4 py-1 text-sm"
          onClick={column.getToggleSortingHandler()}
        >
          <span>Members</span>
          {column.getIsSorted() === "asc" && <AiOutlineArrowUp />}
          {column.getIsSorted() === "desc" && <AiOutlineArrowDown />}
        </div>
      ),
      cell: (info) => (
        <ul
          className="list-disc list-inside"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {(info.getValue() || []).map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      ),
    }),

    columnHelper.accessor("notes", {
      id: "notes",
      label: "Notes",
      enableSorting: false,
      header: () => (
        <div className="w-full h-full px-4 py-1 text-sm">Notes</div>
      ),
      cell: (info) => (
        <div
          className="min-w-[300px] max-w-[600px] whitespace-nowrap overflow-hidden text-ellipsis"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {(info.getValue() || []).map((note, idx) => (
            <div key={idx} className="p-2">
              <strong>Category:</strong> {note.category}
              <br />
              <strong>Note:</strong> {note.note}
              <br />
              <strong>Members:</strong> {(note.members || []).join(", ")}
            </div>
          ))}
        </div>
      ),
    }),
  ];

  const initialPage = parseInt(localStorage.getItem("InventoryPage")) || 0;

  const table = useReactTable({
    data: eventList,
    columns,
    state: {
      columnVisibility,
      pagination: { pageIndex: initialPage, pageSize: 50 },
    },
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(), // <-- add this

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 50 } }, // Set initial page size and page index
  });

  const pdfColumns = [
    { id: "date1", header: "Date", accessor: (row) => row.date1 },
    {
      id: "training_name",
      header: "Event Name",
      accessor: (row) => row.training_name,
    },
    {
      id: "description",
      header: "Description",
      accessor: (row) => row.description,
    },
    {
      id: "address",
      header: "address",
      accessor: (row) => row.address,
    },
    { id: "category", header: "Category", accessor: (row) => row.category },
    { id: "status", header: "Status", accessor: (row) => row.status },
    { id: "itemcount", header: "Item Count", accessor: (row) => row.itemcount },
    {
      id: "members",
      header: "Members",
      accessor: (row) => (row.members || []).map((m) => `• ${m}`).join("\n"),
    },
    {
      id: "notes",
      header: "Notes",
      accessor: (row) =>
        (row.notes || [])
          .map(
            (noteObj) =>
              `• Category: ${noteObj.category}\n  Note: ${
                noteObj.note
              }\n  Members: ${(noteObj.members || []).join(", ")}`
          )
          .join("\n\n"),
    },
  ];

  const visiblePdfColumns = pdfColumns.filter((pdfCol) => {
    return table
      .getAllLeafColumns()
      .some((col) => col.id === pdfCol.id && col.getIsVisible());
  });

  const handleClearFilters = () => {
    setCategoryFilter([]);
    setStatusFilter([]);
    setMemberFilter([]);
    setFromDateFilter("");
    setThruDateFilter("");
    setNoteMemberFilter([]);
    setNoteCategoryFilter([]);
    localStorage.removeItem("EventCategoryFilters");
    localStorage.removeItem("EventStatusFilters");
    localStorage.removeItem("EventMemberFilter");
    localStorage.removeItem("EventFromDateFilter");
    localStorage.removeItem("EventThruDateFilter");
    localStorage.removeItem("EventNoteCategoryFilter");
    localStorage.removeItem("EventNoteMemberFilter");
    localStorage.removeItem("EventPage");
    setSelectedOptions([]);

    refetch();
  };

  const handleChange = (e) => {
    console.log("e", e);
    const categorySearch = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("EventCategoryFilters", categorySearch); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index\
    console.log("categorySearch",categorySearch)
    setCategoryFilter(categorySearch); // Update the state
    refetch(); // Refetch data
  };

  const handleChangeMember = (e) => {
    const MemberFilter2 = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("EventMemberFilter", MemberFilter2); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setMemberFilter(MemberFilter2); // Update the state
    refetch(); // Refetch data
  };

  // Get default values for the Select component from the state
  const defaultValues = options.filter((option) =>
    categoryFilter.includes(option.value)
  );

  const handleStatusChange = (e) => {
    const statusSearch = Array.isArray(e) ? e.map((x) => x) : [];

    localStorage.setItem("EventStatusFilters", statusSearch); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setStatusFilter(statusSearch); // Update the state
    refetch(); // Refetch data
  };

  // Get default values for the Select component from the state
  const StatusdefaultValues = StatusOptions.filter((option) =>
    statusFilter.includes(option.value)
  );

  const MemberdefaultValues = Memberoptions.filter((option) =>
    memberFilter.includes(option.value)
  );

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDateFilter(selectedFromDate);
    localStorage.setItem("EventFromDateFilter", selectedFromDate);

    if (
      !thruDateFilter ||
      new Date(thruDateFilter) < new Date(selectedFromDate)
    ) {
      setThruDateFilter(selectedFromDate);
      localStorage.setItem("EventThruDateFilter", selectedFromDate);
    }

    refetch();
  };

  const handleThruDateChange = (e) => {
    const selectedThruDate = e.target.value;
    setThruDateFilter(selectedThruDate);
    localStorage.setItem("EventThruDateFilter", selectedThruDate);

    if (
      !fromDateFilter ||
      new Date(fromDateFilter) > new Date(selectedThruDate)
    ) {
      setFromDateFilter(selectedThruDate);
      localStorage.setItem("EventFromDateFilter", selectedThruDate);
    }

    refetch();
  };

  const handleChangeNoteCategory = (selected) => {
    const vals = Array.isArray(selected) ? selected.map((x) => x) : [];
    localStorage.setItem("EventNoteCategoryFilter", vals);
    localStorage.setItem("EventPage", 0);
    table.setPageIndex(0);
    setNoteCategoryFilter(vals);

    refetch(); // Re-fetch data if your API respects these filters
  };

  const handleChangeNoteMember = (selected) => {
    const vals = Array.isArray(selected) ? selected.map((x) => x) : [];
    localStorage.setItem("EventNoteMemberFilter", vals);
    localStorage.setItem("EventPage", 0);
    table.setPageIndex(0);
    setNoteMemberFilter(vals);
    refetch();
  };

  return (
    <div className="flex flex-col">
      {/* Top bar (Buttons, Filters) - stays visible on all screen sizes */}

      <div className="bg-white border-b shadow-sm">
  {/* Top row: Columns, PDF, and New Event together */}
  <div className="px-4 py-2 flex flex-col items-start space-y-2 sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0">
    {/* Columns (hidden on mobile) */}
    <div className="hidden sm:block">
      <button
        className="group flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900
                   shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={() => setIsColumnsModalOpenNew(true)}
      >
        Columns
      </button>
    </div>

    {/* Generate PDF (hidden on mobile) */}
    <div className="hidden sm:block">
      <PDFReportTemplate
        data={eventList}
        reportDateRange="2024-01-01 to 2024-12-31"
        category="Inventory"
        members={["John Doe", "Jane Smith"]}
        filters={selectedOptions}
        title="Event Report"
        logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
        pdfColumns={visiblePdfColumns}
        includeDateRange={false}
        includeCategory={false}
        includeMembers={false}
        includeFilters={true}
      />
    </div>

    {/* New Event (visible on both mobile and desktop) */}
    <button
      className="group flex items-center rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900
                 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={() => navigate("/events/new")}
    >
      New Event
    </button>
  </div>

  {/* Bottom row: Filters and filter pills */}
  <div className="border-t px-4 py-2">
    <div className="flex flex-wrap items-center gap-2">
      {/* Filters button */}
      <button
        className="group flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900
                   shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={togglePopupFilter}
      >
        <AdjustmentsHorizontalIcon
          aria-hidden="true"
          className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        />
        Filters
      </button>

      {/* Filter pills */}
      {selectedOptions.map((option, index) => (
        <div
          key={index}
          className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-700"
        >
          {option.label}
        </div>
      ))}
    </div>
  </div>
</div>


      {/* 
    ======================================
    MOBILE VIEW: Card layout 
    Only visible on small screens and hidden on md+ 
    ======================================
  */}
      <div className="block sm:hidden">
        {eventList && eventList.length > 0 ? (
          <div className="space-y-3 p-1">
            {eventList.map((event, idx) => (
              <div
                key={idx}
                className="bg-white rounded shadow-sm p-4 border border-gray-200"
                onClick={() => selecttraining(event.id)}
              >
                {/* You can adjust the layout here to match your second screenshot’s style */}
                <div className="flex items-center justify-between">
                  <span className="text-custom-blue text-sm font-semibold">
                    {/* example status */}
                    {event.training_name ?? "No event name"}
                  </span>

                  <span className="text-xs text-gray-500">
                    {/* example #ID or reference */}
                    {event.date1 ? `${event.date1}` : ""}
                  </span>
                </div>

                <div className="text-sm text-gray-500">
                  {/* example date or "Due Date" */}
                  {event.category ? `${event.category} - ` : ""}{" "}
                  {event.status ? `${event.status}` : ""}
                </div>

                <div className="mt-2 text-base font-medium text-gray-900">
                  {/* example event name */}
                  {event.description ?? "No event name"}
                </div>

                {/* NEW: Row of counts */}
                <div className="flex items-center mt-2 space-x-4 text-sm text-gray-600">
                  {/* Member Count */}
                  <div className="flex items-center space-x-1">
                    <UsersIcon className="w-4 h-4" />
                    <span>{event.attended ?? 0}</span>
                  </div>

                  {/* Note Count */}
                  <div className="flex items-center space-x-1">
                    <DocumentTextIcon className="w-4 h-4" />
                    <span>{event.notecount ?? 0}</span>
                  </div>

                  {/* Item Count */}
                  <div className="flex items-center space-x-1">
                    <ClipboardDocumentListIcon className="w-4 h-4" />
                    <span>{event.itemcount ?? 0}</span>
                  </div>

                  {/* Photo Count */}
                  <div className="flex items-center space-x-1">
                    <CameraIcon className="w-4 h-4" />
                    <span>{event.photocount ?? 0}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center text-gray-500">No events found.</div>
        )}
      </div>

      {/* 
    ======================================
    DESKTOP VIEW: Table layout 
    Hidden on small screens 
    ======================================
  */}
      <div
        className="overflow-x-auto border-b z-1 border-gray-200 print:border-0 print:shadow-none 
                  max-h-[calc(100vh_-_290px)] sm:max-h-[calc(100vh_-_290px)] 
                  hidden sm:block"
      >
        {eventList ? (
          <>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between bg-white px-2 py-2 sm:px-6 sm:rounded-b-lg">
        <Pagination table={table} />
      </div>

      {isFilterOpen && (
        <PopupWeb
          content={
            <div className="pb-10 space-y-4">
              <button
                className="close-button text-sm"
                onClick={handleClearFilters}
              >
                <b>Clear all</b>
              </button>
              <div className="grid grid-cols-2 gap-4 mb-4">
                {/* From Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    From Date
                  </label>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateChange}
                    placeholder="Select date"
                    className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                  />
                </div>

                {/* Thru Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Thru Date
                  </label>
                  <input
                    type="date"
                    value={thruDateFilter}
                    min={fromDateFilter}
                    onChange={handleThruDateChange}
                    placeholder="Select date"
                    className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Event Category
                </label>
                <div
                  onClick={() => {
                    setIsEventCategoryModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {categoryFilter.length
                    ? categoryFilter
                        .map((val) => {
                          const foundOption = options.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Event Category"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <div
                  onClick={() => {
                    setIsEventStatusModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {statusFilter.length
                    ? statusFilter
                        .map((val) => {
                          const foundOption = StatusOptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Event Category"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Members Attended
                </label>
                <div
                  onClick={() => {
                    setIsEventMemberModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {memberFilter.length
                    ? memberFilter
                        .map((val) => {
                          const foundOption = Memberoptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Members Attended"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Note Category
                </label>
                <div
                  onClick={() => {
                    setIsEventNoteCategoryModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {noteCategoryFilter.length
                    ? noteCategoryFilter
                        .map((val) => {
                          const foundOption = NoteCategoryOptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Note Category"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Note Members
                </label>
                <div
                  onClick={() => {
                    setIsEventNoteMemeberModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {noteMemberFilter.length
                    ? noteMemberFilter
                        .map((val) => {
                          const foundOption = NoteMemberOptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Note Members"}
                </div>
              </div>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      <SelectionModal
        isOpen={isEventCategoryModalOpen}
        onClose={() => {
          setIsEventCategoryModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChange(tags);
          setIsEventCategoryModalOpen(false);
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Event Category"
        multiple
        defaultValue={categoryFilter}
      />

      <SelectionModal
        isOpen={isEventStatusModalOpen}
        onClose={() => {
          setIsEventStatusModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleStatusChange(tags);
          setIsEventStatusModalOpen(false);
          //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={StatusOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Event Status"
        multiple
        defaultValue={statusFilter}
      />

      <SelectionModal
        isOpen={isEventMemberModalOpen}
        onClose={() => {
          setIsEventMemberModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeMember(tags);
          setIsEventMemberModalOpen(false);
          //    setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={Memberoptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Member Attended"
        multiple
        defaultValue={memberFilter}
      />

      <SelectionModal
        isOpen={isEventNoteCategoryModalOpen}
        onClose={() => {
          setIsEventNoteCategoryModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeNoteCategory(tags);
          setIsEventNoteCategoryModalOpen(false);
          //    setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={NoteCategoryOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Note Category"
        multiple
        defaultValue={noteCategoryFilter}
      />

      <SelectionModal
        isOpen={isEventNoteMemeberModalOpen}
        onClose={() => {
          setIsEventNoteMemeberModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeNoteMember(tags);
          setIsEventNoteMemeberModalOpen(false);
          //    setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={NoteMemberOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Note Category"
        multiple
        defaultValue={noteMemberFilter}
      />

      <SelectionModal
        isOpen={isColumnsModalOpenNew}
        onClose={() => setIsColumnsModalOpenNew(false)}
        onConfirm={(selectedIds) => {
          const visibility = table.getAllLeafColumns().reduce((acc, col) => {
            acc[col.id] = selectedIds.includes(col.id);
            return acc;
          }, {});
          table.setColumnVisibility(visibility);
          setIsColumnsModalOpenNew(false);
        }}
        options={table
          .getAllLeafColumns()
          .filter((col) => col.getCanHide()) // <-- only hideable columns
          .map((col) => ({
            value: col.id,
            label: col.columnDef.label || col.id,
          }))}
        label="Show/Hide Columns"
        multiple
        defaultValue={table
          .getAllLeafColumns()
          .filter((col) => col.getIsVisible())
          .map((col) => col.id)}
      />

      <ToastNotifier />
    </div>
  );
};

export default EventHeader;
