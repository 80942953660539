import React, { useState, useRef, useEffect, useMemo } from "react";
import PopupWeb from "../Popup/PopupNew";
import Header from "../Header/Header";
import InventoryFinder from "../../apis/InventoryFinder";
import { useLocation, useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import { useMainInventoryData } from "./fetchInventoryData";
import { AiOutlineMenu } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { MdArrowBackIosNew } from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PDFReportTemplate from "../Reports/PDFReportTemplate";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import ScannerButton from "../Scanner/ScannerButton";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import SelectionModal from "../Popup/SelectionModal";

// -------------------------------------------------------------------
// Helper components for the new checklist format
// -------------------------------------------------------------------

const RenderNoChecklist = ({ setInspectionStatus, inspectionStatusObject }) => {
  const taskId = "0";
  // Instead of an array, we store a single status value.
  const currentStatus = inspectionStatusObject[taskId] || "";

  // When a radio is selected, we simply set that status.
  const handleChange = (status) => {
    setInspectionStatus(status, taskId);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center gap-2">
        <label className="cursor-pointer flex items-center">
          <input
            type="radio"
            name={taskId}
            className="hidden"
            value="Pass"
            checked={currentStatus === "Pass"}
            onChange={() => handleChange("Pass")}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatus === "Pass"
                ? "bg-green-500 text-white border-green-500"
                : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
            }`}
          >
            Pass
          </span>
        </label>
        <label className="cursor-pointer flex items-center">
          <input
            type="radio"
            name={taskId}
            className="hidden"
            value="Monitor"
            checked={currentStatus === "Monitor"}
            onChange={() => handleChange("Monitor")}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatus === "Monitor"
                ? "bg-yellow-400 text-white border-yellow-400"
                : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
            }`}
          >
            Monitor
          </span>
        </label>
        <label className="cursor-pointer flex items-center">
          <input
            type="radio"
            name={taskId}
            className="hidden"
            value="Fail"
            checked={currentStatus === "Fail"}
            onChange={() => handleChange("Fail")}
          />
          <span
            className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
              currentStatus === "Fail"
                ? "bg-red-500 text-white border-red-500"
                : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
            }`}
          >
            Fail
          </span>
        </label>
      </div>
      {/* Hidden input to submit the selected status */}
      <input type="hidden" name={`status_${taskId}`} value={currentStatus} />
    </div>
  );
};

const RenderChecklist = ({
  ChecklistData,
  dropdownQuery,
  setInspectionStatus,
  inspectionStatusObject,
}) => {
  const filteredData = ChecklistData.filter((data) => {
    if (!dropdownQuery.value || dropdownQuery.value === "None") return true;
    return data.clid === dropdownQuery.value;
  });

  if (filteredData.length === 0) {
    return <div>No tasks found for this checklist.</div>;
  }

  return (
    <>
      {filteredData.map((data) => (
        <div key={data.clid}>
          {Array.isArray(data.tasklist) && data.tasklist.length > 0 ? (
            data.tasklist.map((task) => {
              const currentStatus = inspectionStatusObject[task.tid] || "";
              const handleChange = (status) => {
                setInspectionStatus(status, task.tid);
              };
              return (
                <div key={task.tid} className="mb-4">
                  <div className="taskNameHolder mb-1">
                    <div className="taskName2 font-bold">{task.taskname}</div>
                    <div className="taskDesc2 text-sm text-gray-600">
                      {task.taskdescription}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Pass"
                        checked={currentStatus === "Pass"}
                        onChange={() => handleChange("Pass")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Pass"
                            ? "bg-green-500 text-white border-green-500"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-green-50"
                        }`}
                      >
                        Pass
                      </span>
                    </label>
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Monitor"
                        checked={currentStatus === "Monitor"}
                        onChange={() => handleChange("Monitor")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Monitor"
                            ? "bg-yellow-400 text-white border-yellow-400"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-yellow-50"
                        }`}
                      >
                        Monitor
                      </span>
                    </label>
                    <label className="cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name={task.tid}
                        className="hidden"
                        value="Fail"
                        checked={currentStatus === "Fail"}
                        onChange={() => handleChange("Fail")}
                      />
                      <span
                        className={`px-4 py-1 text-sm font-semibold rounded-full border transition-colors duration-200 ${
                          currentStatus === "Fail"
                            ? "bg-red-500 text-white border-red-500"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-red-50"
                        }`}
                      >
                        Fail
                      </span>
                    </label>
                  </div>
                  <input
                    type="hidden"
                    name={`status_${task.tid}`}
                    value={currentStatus}
                  />
                  <input
                    name={`tid_${task.tid}`}
                    type="hidden"
                    value={task.tid}
                  />
                  <input
                    name={`taskname_${task.tid}`}
                    type="hidden"
                    value={task.taskname}
                  />
                  <input
                    name={`taskdesc_${task.tid}`}
                    type="hidden"
                    value={task.taskdescription}
                  />
                  <input
                    name={`clid_${task.tid}`}
                    type="hidden"
                    value={data.clid}
                  />
                </div>
              );
            })
          ) : (
            <div>No tasks available in this checklist.</div>
          )}
        </div>
      ))}
    </>
  );
};

const MAX_PAGE_BUTTONS = 5;

function getVisiblePages(currentPage, totalPages) {
  if (totalPages <= MAX_PAGE_BUTTONS) {
    return [...Array(totalPages).keys()]; // e.g. [0, 1, 2, ..., totalPages-1]
  }

  let start = Math.max(currentPage - 2, 0);
  let end = Math.min(start + MAX_PAGE_BUTTONS - 1, totalPages - 1);

  // Shift window if we don't have enough pages to fill the limit
  if (end - start < MAX_PAGE_BUTTONS - 1) {
    start = end - (MAX_PAGE_BUTTONS - 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  // Persist current page index in localStorage (optional)
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  // Calculate total rows (unpaginated)
  const totalRows = table.getPrePaginationRowModel().rows.length;
  // Calculate which rows are displayed
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  // Determine visible page numbers (for desktop)
  const visiblePages = getVisiblePages(pageIndex, pageCount);

  return (
    <>
      {/* --------------------- MOBILE VIEW --------------------- */}
      {/* Visible only on screens smaller than the 'sm' breakpoint */}
      <div className="block sm:hidden w-full mt-4">
        {/* Row count text */}
        <div className="text-sm text-gray-700 mb-2">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Minimal pagination controls in one row */}
        <div className="flex items-center justify-between">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* Page X of Y label */}
          <span className="text-sm text-gray-700">
            Page {pageIndex + 1} of {pageCount}
          </span>

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>

      {/* --------------------- DESKTOP VIEW --------------------- */}
      {/* Hidden on screens smaller than 'sm' */}
      <div className="hidden sm:flex sm:flex-row sm:items-center sm:justify-between w-full mt-4">
        {/* Left side: "Showing X - Y of Z" */}
        <div className="text-sm text-gray-700">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Right side: Full pagination controls */}
        <div className="flex flex-wrap items-center gap-1 justify-end">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* If the first visible page isn't the first page, show 1 + ellipses */}
          {visiblePages[0] > 0 && (
            <>
              <button
                onClick={() => table.setPageIndex(0)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                1
              </button>
              <span className="px-2">...</span>
            </>
          )}

          {/* Numbered Page Buttons */}
          {visiblePages.map((pageNum) => {
            const isCurrent = pageIndex === pageNum;
            return (
              <button
                key={pageNum}
                onClick={() => table.setPageIndex(pageNum)}
                disabled={isCurrent}
                className={`px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200
                  ${isCurrent ? "bg-teal-100 text-teal-700" : "text-gray-700"}
                  disabled:opacity-80`}
              >
                {pageNum + 1}
              </button>
            );
          })}

          {/* If the last visible page isn't the last page, show ellipses + last page */}
          {visiblePages[visiblePages.length - 1] < pageCount - 1 && (
            <>
              <span className="px-2">...</span>
              <button
                onClick={() => table.setPageIndex(pageCount - 1)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                {pageCount}
              </button>
            </>
          )}

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </>
  );
};

const InventoryWeb = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentLastName,
    currentFirstName,
  } = useAuth();
  const { data, refetch } = useMainInventoryData(currentUserSiteId);

  const [inventoryList, setInventoryList] = useState([]);
  const [allFrequency, setAllFrequency] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [isTagsModalOpenNew, setIsTagsModalOpenNew] = useState(false);
  const [isStatusModalOpenNew, setIsStatusModalOpenNew] = useState(false);

  const [isFilterTagsModalOpen, setIsFilterTagsModalOpen] = useState(false);
  const [isFilterInspectionModalOpen, setIsFilterInspectionModalOpen] =
    useState(false);
  const [isFilterMemberModalOpen, setIsFilterMemberModalOpen] = useState(false);
  const [isFilterLocationModalOpen, setIsFilterLocationModalOpen] =
    useState(false);
  const [isFilterBagModalOpen, setIsFilterBagModalOpen] = useState(false);

  useEffect(() => {
    setInventoryList(data?.data.data.inventory2);

    setAllTags(data?.data.data.allTags ? data?.data.data.allTags : []);
    setAllFrequency(
      data?.data.data.allFrequency ? data?.data.data.allFrequency : []
    );
  }, [data]);

  const [isColumnsModalOpenNew, setIsColumnsModalOpenNew] = useState(false);

  const [columnVisibility, setColumnVisibility] = useState({
    Manufacturer: false,
    Model: false,
    Size: false,

    Tags: false,
    InspectionFrequancy: false,
    mandatoryinspectiondate: false,
    manufacturedate: false,
  });

  const Memberoptions =
    data?.data.data.memberResults.map(function (data) {
      return {
        value: data.user_id,
        label: data.fullname,
      };
    }) || [];

  // Initialize options
  const options =
    data?.data.data.categoryResults.map(function (data) {
      return {
        value: data.categoryid,
        label: data.name,
      };
    }) || [];

  const NextInspectionOptions = [
    { value: "Inspection due in 30 days", label: "Inspection due in 30 days" },
    { value: "Inspection is overdue", label: "Inspection is overdue" },
  ];

  const SelectOptions = [
    { value: "Inspect", label: "Inspect" },
    { value: "Add Tag", label: "Add Tag" },
    { value: "Add Inspection Frequency", label: "Add Inspection Frequency" },
    { value: "Remove From Inventory", label: "Remove From Inventory" },
  ];

  const combineOptions = (
    tags,
    members,
    inspections,
    tagOptions,
    Memberoptions1,
    NextInspectionOptions,
    optionsBags,
    apparatusOptions
  ) => {
    return [
      ...tagOptions
        .filter((option) =>
          tags.map(String).includes(String(option.value))
        )
        .map((option) => ({ ...option, type: "Tag" })),
  
      ...Memberoptions1
        .filter((option) =>
          members.map(String).includes(String(option.value))
        )
        .map((option) => ({ ...option, type: "Member" })),
  
      ...NextInspectionOptions
        .filter((option) =>
          inspections.map(String).includes(String(option.value))
        )
        .map((option) => ({ ...option, type: "Inspection" })),
  
      ...optionsBags
        .filter((option) =>
          // assuming bagFilter values might be numbers vs. string in option.value
          bagFilter.map(String).includes(String(option.value))
        )
        .map((option) => ({ ...option, type: "Bag" })),
  
      ...apparatusOptions
        .filter((option) =>
          locationFilter.map(String).includes(String(option.value))
        )
        .map((option) => ({ ...option, type: "Apparatus" })),
    ];
  };
  
  const updateSelectedOptions = (
    tags,
    members,
    inspections,
    bagFilter,
    locationFilter
  ) => {
    console.log("updateSelectedOptions", tags);
    const combinedOptions = combineOptions(
      tags,
      members,
      inspections,
      options,
      Memberoptions,
      NextInspectionOptions,
      bagFilter,       // pass bagFilter here
      locationFilter,  // pass locationFilter here
      flatOptionsBags, // pass the flattened bags options
      apparatusoptions // pass the apparatus options
    );
    setSelectedOptions(combinedOptions);
  };

  const [inventoryLookUpItem, setInventoryLookUpItem] = useState("");
  const [tagsFilter, setTagsFilter] = useState([]);
  const [inspectionFilter, setInspectionFilter] = useState([]);
  const [memberFilter, setMemberFilter] = useState([]);
  const [bagFilter, setBagFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);

  useEffect(() => {

    const parseLocalArray = (key) => {
      const raw = localStorage.getItem(key);
      if (!raw) return [];
      try {
        const parsed = JSON.parse(raw);
        // If parsed is not an array, return it as a one-item array
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch {
        // Fallback for legacy comma‑separated values
        return raw.split(",").filter(Boolean).map((val) =>
          isNaN(val) ? val : Number(val)
        );
      }
    };
  
    const storedTagsFilter = parseLocalArray("InventoryFilters");
    // For string values (like a search query), just retrieve or default to empty string
    const storedSearchQuery =
      localStorage.getItem("InventorySearchQuery") || "";

    // Parse arrays with our helper
    const storedInspectionFilter = parseLocalArray("InventoryInspectionFilter");
    const storedMemberFilter = parseLocalArray("InventoryMemberFilter");
    const storedBagFilter = parseLocalArray("InventoryBagFilter");
    const storedLocationFilter = parseLocalArray("InventoryLocationFilter");

    console.log("storedTagsFilter",storedTagsFilter)


    // If we found a stored search query, set it
    if (storedSearchQuery) {
      setInventoryLookUpItem(storedSearchQuery);
    }

    // If arrays have any values, set them
    if (storedInspectionFilter.length) {
      setInspectionFilter(storedInspectionFilter);
    }
    if (storedTagsFilter.length) {
      setTagsFilter(storedTagsFilter);
    }
    if (storedMemberFilter.length) {
      setMemberFilter(storedMemberFilter);
    }
    if (storedBagFilter.length) {
      setBagFilter(storedBagFilter);
    }
    if (storedLocationFilter.length) {
      setLocationFilter(storedLocationFilter);
    }
  }, []);

  useEffect(() => {
    // Ensure that options are available before attempting to update selected options
    if (
      options.length &&
      Memberoptions.length &&
      NextInspectionOptions.length
    ) {
      // Only update if there's a change in the filters or the necessary options are loaded

      if (
        tagsFilter.length ||
        memberFilter.length ||
        inspectionFilter.length ||
        bagFilter.length ||
        locationFilter.length
      ) {
        updateSelectedOptions(
          tagsFilter,
          memberFilter,
          inspectionFilter,
          bagFilter,
          locationFilter
        );
      }
    }
  }, [
    tagsFilter,
    memberFilter,
    inspectionFilter,
    bagFilter,
    locationFilter,
    options.length, // Watch for when options are loaded
    Memberoptions.length, // Watch for when Memberoptions are loaded
    NextInspectionOptions.length, // Watch for when NextInspectionOptions are loaded
  ]);

  let history = useNavigate();

  const [file, setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };
  const togglePopupOpen = () => {
    setIsOpen(!isOpen1);
    setIsOpenSearch(!isOpenSearch);
    setName(lookUpItem);
    setSelectedImage("");
    setCategory("");
    setType("");
    setManufacture("");
    setDescription("");
    setFile("");
    setSelectedItem("");
  };

  const togglePopupClose = () => {
    setIsOpen(!isOpen1);
  };

  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  //const [inspectionOpen, setInspectionOpen] = useState([]);

  //Input useStae
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [size, setSize] = useState("");
  const [model, setModel] = useState("");

  const [itemStatus, setItemStatus] = useState("Safe to use"); // You might want a default value, e.g. "Good"

  const [selectedAmount, setSelectedAmount] = useState("1");
  const [selectedIdentificationName, setSelectedIdentificationName] =
    useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedFirstUseDate, setSelectedFirstUseDate] = useState();
  const [selectedManufactureDate, setSelectedManufactureDate] = useState();
  const [SelectedRetirementDate, setSelectedRetirementDate] = useState();

  const [selectedMandatoryServiceDate, setSelectedMandatoryServiceDate] =
    useState();

  const [selectedItemId, setSelectedItemId] = useState();
  const [dropdownQuery, setDropdownQuery] = useState({
    value: "None",
    label: "None",
  });

  const [errors, setErrors] = useState({});

  const validationRules = {
    NewItem: {
      name: "Please select item or create new",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);
    //  console.log(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setCategory("");
    setType("");
    setLookUpItem("");
    setLookUpResults("");
    setSelectedItem("");
    setSearchPerformed(false);
    setSelectedIdentificationName("");
    setSize("");
    setModel("");

    setItemStatus("Safe to use");
    setName("");
    setSelectedImage("");
    setManufacture("");
    setDescription("");
    setSelectedItemTags("");
    setSelectedRetirementDate("");
    setSelectedMandatoryServiceDate("");
    setFile(null);
  };
  const [allTags, setAllTags] = useState([]);
  const [addItemTags, setAddItemTags] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const openSearchBox = () => {
    setSelectedItem(null);
    setLookUpItem(""); // Clear the search input
    setLookUpResults([]); // Clear the search results
    setSelectedItemId("");
    setName("");
    setManufacture("");
    setDescription("");
    setSize("");
    setModel("");

    setItemStatus("Safe to use");
    setImage("");
  };

  const handleSelctedItemInfo = async (
    id,
    name,
    manufacture,
    description,
    image
  ) => {
    setSelectedItemId(id);
    setName(name);
    setManufacture(manufacture);
    setDescription(description);
    setImage(image);
    setSelectedItem({ id, name, manufacture, description, image });
  };

  const [selectedItemTags, setSelectedItemTags] = useState("");

  //Submit user new item
  const handleAddNewItem = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      name,
    };

    if (!validateForm(formValues, validationRules.NewItem)) {
      return;
    }

    setLoading(true);

    try {
      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/addNewItem`, {
        inventoryId: selectedItemId,
        name,
        manufacture,
        image,
        model,
        description,
        selectedAmount,
        selectedIdentificationName,
        selectedSerialNumber:
          selectedSerialNumber === "" ? null : selectedSerialNumber,
        selectedManufactureDate,
        selectedFirstUseDate,
        size,
        itemStatus,
        selectedItemTags,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        SelectedRetirementDate,
        selectedMandatoryServiceDate,
      });
      //  refetch();
      clearForm();
      handleItem(response.data.data.inventory.itemid);

      setLoading(false);
      togglePopupSearch();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    //stop the page from reloading
    // console.log(name);
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      name,
    };

    if (!validateForm(formValues, validationRules.NewItem)) {
      return;
    }

    setLoading(true);

    try {
      const form = document.getElementById("newItemForm"); // Get the form by its ID
      const formData = new FormData(form); // Create FormData from the form

      formData.set("name", name);
      formData.set("manufacture", manufacture);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("image", image);

      formData.set("selectedAmount", selectedAmount);
      formData.set("selectedIdentificationName", selectedIdentificationName);
      formData.set("selectedSerialNumber", selectedSerialNumber);
      formData.set("selectedManufactureDate", selectedManufactureDate);

      formData.set("SelectedRetirementDate", SelectedRetirementDate);
      formData.set("SelectedRetirementDate", SelectedRetirementDate);

      formData.set("itemStatus", itemStatus);

      formData.set("size", size);
      formData.set("model", model);

      formData.set("selectedFirstUseDate", selectedFirstUseDate);
      formData.append("selectedItemTags", JSON.stringify(selectedItemTags)); // Convert array to JSON string
      formData.set("currentUserId", currentUserId);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentLastName", currentLastName);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }

      const response = await InventoryFinder.post("/newInventory", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      clearForm();

      refetch();
      // setInventory(response.data.data.inventory);
      //setCategory(response.data.data.itemGroupresults);
      form.reset();
      togglePopup();
      setName("");
      setSelectedImage("");
      setCategory("");
      setType("");
      setManufacture("");
      setDescription("");
      setFile(null);
      setLoading(false);
      handleItem(response.data.data.inventory.itemid);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAddNewItemAndAddAnother = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent double submission

    const formValues = { name };
    if (!validateForm(formValues, validationRules.NewItem)) {
      return;
    }
    setLoading(true);
    try {
      const response = await InventoryFinder.post(`/addNewItem`, {
        inventoryId: selectedItemId,
        name,
        manufacture,
        image,
        model,
        description,
        selectedAmount,
        selectedIdentificationName,
        selectedSerialNumber:
          selectedSerialNumber === "" ? null : selectedSerialNumber,
        selectedManufactureDate,
        selectedFirstUseDate,
        selectedItemTags,
        size,
        itemStatus,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        SelectedRetirementDate,
        selectedMandatoryServiceDate,
      });
      refetch();
      clearForm();

      setSelectedAmount("1");
      setSelectedItemTags([]);
      setDescription("");
      setSelectedSerialNumber("");
      setSelectedIdentificationName("");
      setSelectedFirstUseDate("");
      setSelectedManufactureDate("");
      setSelectedRetirementDate("");
      setSelectedMandatoryServiceDate("");
      setModel("");

      setSize("");
      setItemStatus("Safe to use");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };

  const [inspectionPhotos, setInspectionPhotos] = useState([]);

  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState([]);

  const [searchPerformed, setSearchPerformed] = useState(false);

  //Popup
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const togglePopupSearch = () => {
    clearForm();
    setIsOpenSearch(!isOpenSearch);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [lookUpItem, setLookUpItem] = useState("");

  const [lookUpResults, setLookUpResults] = useState("");

  const handleItemSearch = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      //// console.log(lookUpItem);
      const response = await InventoryFinder.get("/getDefaultItems", {
        params: {
          lookUpItem,
          currentUserSiteId,
        },
      });
      //// console.log(response.data.data);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      // //// console.log(response.data.data.training);
      setLookUpResults(response.data.data.lookUpResults);
      setSearchPerformed(true);
    } catch (err) {}
  };

  const handleItem = (itemid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
    });
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data?.data.data.inventory2.map((li) => li.itemid));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck((isCheck) => [...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const optionsBags =
    data?.data.data.bagsList.map(function (data) {
      return {
        label: data.name,
        options: data.bags.map((data2, i) => ({
          value: data2.bagid,
          label: data2.bagname,
        })),
      };
    }) || [];

  const flatOptionsBags = optionsBags.flatMap((group) => group.options);

  const apparatusoptions =
    data?.data.data.apparatusList.map(function (data) {
      return {
        value: data.id,
        label: data.name,
      };
    }) || [];

  var categorySearch = "";

  const handleChange = (e) => {
    const categorySearch = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("InventoryFilters", categorySearch); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setTagsFilter(categorySearch); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeInspection = (e) => {
    const InspectionFilter = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("InventoryInspectionFilter", InspectionFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setInspectionFilter(InspectionFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeMember = (e) => {
    const MemberFilter = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("InventoryMemberFilter", MemberFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setMemberFilter(MemberFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleSearchChange = (e) => {
    e.preventDefault(); // prevents the page from reloading
    const searchQuery = inventoryLookUpItem;
    localStorage.setItem("InventorySearchQuery", searchQuery); // Store the search query
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setInventoryLookUpItem(searchQuery); // Update the state
    refetch(); // Refetch data
  };

  const handleChangeLocation = (e) => {
    const LocationFilter = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("InventoryLocationFilter", LocationFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setLocationFilter(LocationFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeBag = (e) => {
    const BagFilter = Array.isArray(e) ? e.map((x) => x) : [];
    localStorage.setItem("InventoryBagFilter", BagFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setBagFilter(BagFilter); // Update the state

    refetch(); // Refetch data
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    // setInspectionOpen(!inspectionOpen);
  };

  // Get default values for the Select component from the state
  const defaultValues = options.filter((option) =>
    tagsFilter.includes(option.value)
  );

  const InspectiondefaultValues = NextInspectionOptions.filter((option) =>
    inspectionFilter.includes(option.value)
  );

  const memberdefaultValues = Memberoptions.filter((option) =>
    memberFilter.includes(option.value)
  );

  const locationdefaultValues = apparatusoptions.filter((option) =>
    locationFilter.includes(option.value)
  );

  const bagdefaultValues = flatOptionsBags.filter((option) =>
    bagFilter.includes(option.value)
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === "Remove From Inventory" ? "red" : "black", // Apply red color to "Remove From Inventory"
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const [ChecklistData, setChecklistData] = useState([]);

  const handleSelectOptionsChange = (e) => {
    if (e === "Inspect") {
      setDropdownQuery({ value: "None", label: "None" });
      setInspectionNotes("");
      fetchInspectionResults();
      togglePopup4();
    }

    if (e === "Add Tag") {
      setDropdownQuery({ value: "None", label: "None" });
      setAddItemTags([]);
      editItemPopupClose();
    }

    if (e === "Add Inspection Frequency") {
      setDropdownQuery({ value: "None", label: "None" });
      setAddFreq("");
      mainInspectionFreqPopup();
    }

    if (e === "Remove From Inventory") {
      setDropdownQuery({ value: "None", label: "None" });
      setAddFreq("");
      mainRemovePopup();
    }
  };

  const fetchInspectionResults = async () => {
    try {
      const response = await InventoryFinder.get("/getInventoryInspection", {
        params: {
          currentUserSiteId,
          isCheck: isCheck,
        },
      });

      setChecklistData(response.data.data.inspectionData);
    } catch (err) {
      // //// console.log(err);
    }
  };

  const ChecklistOptionsNone = [{ value: "None", label: "None" }];
  const ChecklistOptionsNew = ChecklistData.map((e) => ({
    value: e.clid,
    label: e.checklistname,
  }));
  const ChecklistOptions = [...ChecklistOptionsNone, ...ChecklistOptionsNew];

  const [inspectionStatusArray, setInspectionStatusArray] = useState([]);
  const [inspectionNotes, setInspectionNotes] = useState("");

  const handleInspectionSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      let myForm = document.getElementById("inspectionForm"); // Reference the form by ID
      let formData = new FormData(myForm);

      // //// console.log("handleFileInput working!");

      inspectionPhotos.forEach((image, index) => {
        formData.append(`file`, image);
      });
      // eslint-disable-next-line
      // Get only the values from the object
      const inspectionStatusValues = Object.values(inspectionStatusObject);

      // Convert the values to a comma-separated string
      const inspectionStatusString = inspectionStatusValues.join(",");

      formData.set("isCheck", isCheck);
      formData.set("inspectionStatus", inspectionStatusString);
      formData.set("inspectionNotes", inspectionNotes);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentUserId", currentUserId);
      formData.set("selectedChecklistClid", dropdownQuery.value);
      formData.set("selectedChecklistName", dropdownQuery.label);

      //eslint-disable-next-line
      const response2 = await InventoryFinder.post(
        `/postInspection`,
        formData,
        headers
      );
      // After successful submission, reset the inspection statuses:
      setInspectionStatusObject({});
      // Optionally reset the dropdown as well:
      setDropdownQuery({ value: "None", label: "None" });

      // Optionally reset images/notes if needed:
      setInspectionPhotos([]);
      setInspectionNotes("");
      // setInventory(response.data.data.inventory);
      //setCategory(response.data.data.itemGroupresults);
      refetch();
      togglePopup4();

      setFile(null);
      setLoading(false);
    } catch (err) {
      //// console.log(err);
      setLoading(false);
    }
  };

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      // SELECT checkbox
      columnHelper.accessor((row) => row.itemid, {
        id: "itemid",
        enableHiding: false,
        header: () => (
          <div className="min-max-width2">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={isCheckAll}
              style={{ width: 15, height: 15 }}
            />
          </div>
        ),
        cell: (info) => (
          <div className="min-max-width2">
            <input
              type="checkbox"
              onChange={handleClick}
              checked={isCheck.includes(info.getValue())}
              style={{ width: 15, height: 15 }}
            />
          </div>
        ),
      }),

      // IMAGE
      columnHelper.accessor((row) => row.image, {
        id: "image",
        enableHiding: false,
        header: () => <div className="min-max-widthLogo">Image</div>,
        cell: (info) => {
          const img = info.getValue();
          return (
            <div
              className="min-max-widthLogo"
              onClick={() => handleItem(info.row.original.itemid)}
            >
              {img && img !== "0" ? (
                <img
                  src={PublicFolder.baseURL + "preview_" + img}
                  className="InventoryTableImg"
                />
              ) : (
                <BsImage size={50} className="InventoryTableImg" />
              )}
            </div>
          );
        },
      }),

      // SERIAL NUMBER
      columnHelper.accessor((row) => row.serialnumber, {
        id: "serialnumber",
        enableHiding: false,
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Serial Number</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => (
          <div
            className="min-max-width text-blue-600 underline cursor-pointer"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue() || "N/A"}
          </div>
        ),
      }),

      // NAME
      columnHelper.accessor((row) => row.itemname, {
        id: "Name",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Name</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // IDENTIFICATION
      columnHelper.accessor((row) => row.identificationname, {
        id: "Identification Name",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Identification</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // MANUFACTURER
      columnHelper.accessor((row) => row.manufacture, {
        id: "Manufacturer",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Manufacturer</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // MODEL
      columnHelper.accessor((row) => row.model, {
        id: "Model",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Model</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // SIZE
      columnHelper.accessor((row) => row.size, {
        id: "Size",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Size</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // AMOUNT
      columnHelper.accessor((row) => row.itemamount, {
        id: "Amount",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Amount</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // LOCATION
      columnHelper.accessor((row) => row.apparatus, {
        id: "Location",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Location</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => (
          <div className="min-max-width">
            {info.row.original.apparatus
              ? `${info.row.original.apparatus} - ${
                  info.row.original.compartmentname
                }${
                  info.row.original.bagname
                    ? ` - ${info.row.original.bagname}`
                    : ""
                }`
              : info.row.original.memberitem || ""}
          </div>
        ),
      }),

      // TAGS
      columnHelper.accessor((row) => row.tags, {
        id: "Tags",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Tags</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => (
          <ul className="flex flex-wrap gap-2">
            {(info.getValue() || []).map((t, i) => (
              <li key={i} className="bg-gray-100 px-2 py-1 rounded text-sm">
                {t.tag}
              </li>
            ))}
          </ul>
        ),
      }),

      // STATUS
      columnHelper.accessor((row) => row.status, {
        id: "Status",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Status</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => {
          const status = info.getValue();
          return (
            <div className="min-max-width">
              {status === "Not safe to use" ? (
                <span className="bg-red-100 text-red-800 px-2 py-1 rounded">
                  Not safe to use
                </span>
              ) : status === "Safe to use" ? (
                <span className="bg-green-100 text-green-800 px-2 py-1 rounded">
                  Safe to use
                </span>
              ) : status === "Monitor" ? (
                <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded">
                  Monitor
                </span>
              ) : status === "Expired" ? (
                <span className="bg-gray-300 text-gray-700 px-2 py-1 rounded">
                  Expired
                </span>
              ) : (
                status
              )}
            </div>
          );
        },
      }),

      // INSPECTION DATE
      columnHelper.accessor((row) => row.inspectiondate, {
        id: "Inspection Date",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Inspection Date</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // INSPECTION STATUS
      columnHelper.accessor((row) => row.inspectionresult, {
        id: "Inspection Result",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Inspection Status</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => {
          const result = info.getValue();
          return (
            <div className="min-max-width">
              {result === "Pass" ? (
                <span className="bg-green-100 text-green-800 px-2 py-1 rounded">
                  Pass
                </span>
              ) : result === "Fail" ? (
                <span className="bg-red-100 text-red-800 px-2 py-1 rounded">
                  Fail
                </span>
              ) : result === "Monitor" ? (
                <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded">
                  Monitor
                </span>
              ) : (
                ""
              )}
            </div>
          );
        },
      }),

      // NEXT INSPECTION DATE
      columnHelper.accessor((row) => row.nextinspectiondate, {
        id: "Next Inspection Date",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Next Inspection Date</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // INSPECTION FREQUENCY
      columnHelper.accessor((row) => row.inspectionfeq, {
        id: "Inspection Frequency",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Inspection Frequency</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // MANDATORY INSPECTION DATE
      columnHelper.accessor((row) => row.mandatoryinspectiondate, {
        id: "Mandatoryi Inspection Date",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Mandatory Inspection Date</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // MANUFACTURE DATE
      columnHelper.accessor((row) => row.manufacturedate, {
        id: "Mnufacture Date",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Manufacture Date</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),

      // EXPIRATION DATE
      columnHelper.accessor((row) => row.expirationdate, {
        id: "Expiration Date",
        header: ({ column }) => (
          <div
            className="min-max-width flex justify-between cursor-pointer py-1 text-sm"
            onClick={column.getToggleSortingHandler()}
          >
            <span>Expiration Date</span>
            {column.getIsSorted() === "asc" ? (
              <AiOutlineArrowUp />
            ) : column.getIsSorted() === "desc" ? (
              <AiOutlineArrowDown />
            ) : null}
          </div>
        ),
        cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      }),
    ],
    [handleClick, handleSelectAll, isCheck, isCheckAll]
  );
  const initialPage = parseInt(localStorage.getItem("InventoryPage")) || 0;

  const table = useReactTable({
    data: inventoryList,
    columns,
    state: {
      columnVisibility,
      pagination: { pageIndex: initialPage, pageSize: 25 },
    },
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(), // <-- add this

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } }, // Set initial page size and page index
  });


  const pdfColumns = columns
  // Exclude columns that you don’t want in your PDF (e.g. checkboxes or images)
  .filter((col) => !["itemid", "image"].includes(col.id))
  .map((col) => ({
    id: col.id,
    // If you have a dedicated pdfHeader (or fallback to the id), use that for a plain text header.
    header:
      col.pdfHeader ||
      (typeof col.header === "function" ? col.id : col.header),
    // Create an accessor that calls the original accessor if it’s a function;
    // otherwise, assume the row has a property matching the column id.
    accessor: (row) =>
      typeof col.accessor === "function" ? col.accessor(row) : row[col.id],
  }));

  const visiblePdfColumns = pdfColumns.filter((pdfCol) => {
    return table
      .getAllLeafColumns()
      .some((col) => col.id === pdfCol.id && col.getIsVisible());
  });

  const handleClearFilters = () => {
    setTagsFilter([]);
    setMemberFilter([]);
    setInspectionFilter([]);
    setLocationFilter([]);
    setBagFilter([]);
    setLookUpItem("");
    localStorage.removeItem("InventoryFilters");
    localStorage.removeItem("InventorySearchQuery");
    localStorage.removeItem("InventoryPage");
    localStorage.removeItem("InventoryMemberFilter");
    localStorage.removeItem("InventoryInspectionFilter");
    localStorage.removeItem("InventoryLocationFilter");
    localStorage.removeItem("InventoryBagFilter");

    setInventoryLookUpItem("");
    setSelectedOptions([]);
    refetch();
  };

  //Popup Edit item details
  const [isOpen3, setIsOpen3] = useState(false);
  const editItemPopupClose = () => {
    setIsOpen3(!isOpen3);
  };

  let optionsAllTags = allTags.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
    };
  });

  //update inventory
  const handleTagUpdate = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // // console.log("dmdkmfds");
      const response = await InventoryFinder.post(`/editTagItem`, {
        isCheck,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addItemTags,
      });
      // // console.log("rerwr");

      setLoading(false);
      // // console.log("dmdkmfds");
      //(response.data.data.alert);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }

      refetch();

      editItemPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const [addFreq, setAddFreq] = useState("");

  const [isInspectionFreqOpen, setIsInspectionFreqOpen] = useState(false);
  const mainInspectionFreqPopup = () => {
    setIsInspectionFreqOpen(!isInspectionFreqOpen);
  };

  //Item move submit
  const handleFreqSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/updateItemFreq`, {
        isCheck,
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      refetch();
      mainInspectionFreqPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  let optionsFreq = allFrequency.map(function (data) {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const mainRemovePopup = () => {
    setIsRemoveOpen(!isRemoveOpen);
  };

  //Item move submit
  const handleRemoveSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/deleteItemInventory`, {
        isCheck,
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      setIsCheck([]);
      setDropdownQuery({ value: "None", label: "None" });
      setAddFreq("");
      refetch();
      mainRemovePopup();
    } catch (err) {
      setLoading(false);
    }
  };

  const [inspectionStatusObject, setInspectionStatusObject] = useState({});

  const setInspectionStatus = (value, taskId) => {
    setInspectionStatusObject((prevObject) => ({
      ...prevObject,
      [taskId]: value,
    }));
  };

  const [fromBags, setFromBags] = useState(false);

  useEffect(() => {
    if (location.state?.from === "/bags") {
      setFromBags(true);
    }
  }, [location]);

  const goBackDiv = (
    <div className="goBackDiv" onClick={() => navigate(-1)}>
      <div className="goBackDivarrow">
        <MdArrowBackIosNew />
      </div>
      <div className="goBackDivtxt">Back</div>
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="bg-white border-b shadow-sm pb-3">
        {/* Top row: actions */}
        <div className="flex items-center px-4 py-2">
          <div className="flex items-center space-x-2">
            <button
              className="group flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 
                       shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setIsColumnsModalOpenNew(true)}
            >
              Columns
            </button>

            <PDFReportTemplate
              data={inventoryList}
              title="Inventory Report"
              reportDateRange="2024-01-01 to 2024-12-31"
              category="Inventory"
              members={["John Doe", "Jane Smith"]}
              logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
              pdfColumns={visiblePdfColumns}
              filters={selectedOptions}
              includeDateRange={false} // Pass these flags based on your requirement
              includeCategory={false}
              includeMembers={false}
              includeFilters={true}
            />
          

            <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => navigate("/inventory/new")}
          >
             Add Item
          </button>

          </div>
        </div>

        {/* Bottom row: filter button & filter pills together */}
        <div className="border-t px-4 py-2">
          <div className="flex items-center flex-wrap gap-2">
            {/* Filter button */}
            <button
              className="group flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 
                       shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopupFilter}
            >
              <AdjustmentsHorizontalIcon
                aria-hidden="true"
                className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Filters
            </button>

            {/* Filter pills */}
            {selectedOptions.map((option, index) => (
              <div
                key={index}
                className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-700"
              >
                {option.label}
                <button
                  type="button"
                  className=" text-gray-500 hover:text-gray-700 focus:outline-none"
                  // onClick={() => removeFilterOption(option)}
                ></button>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full px-4 py-1 bg-white">
          {/* 
        If you want it literally edge-to-edge, remove the `px-4 py-4`
        and ensure your parent container has no padding/margins.
      */}
          <form className="relative w-full" onSubmit={handleSearchChange}>
            <input
              type="search"
              placeholder="Search Inventory"
              value={inventoryLookUpItem}
              onChange={(e) => setInventoryLookUpItem(e.target.value)}
              className="
            w-full
            border border-gray-300
            rounded-xl
            py-3 px-4 pr-12
            focus:outline-none focus:ring-2 focus:ring-blue-600
            shadow-sm
            placeholder-gray-400
          "
            />
            <button
              type="submit"
              className="
            absolute
            right-4
            top-1/2
            -translate-y-1/2
            text-gray-500
            hover:text-gray-700
            focus:outline-none
          "
            >
              <FaArrowRight />
            </button>
          </form>
        </div>
      </div>

      {isCheck.length > 0 ? (
        <div className="ChecklistSelectPopup">
          <label className="NewInputLabel" data-domain="Options">
            <Select
              defaultValue={null}
              options={SelectOptions}
              onChange={(e) => handleSelectOptionsChange(e.value)}
              isSearchable={false}
              name="addMembers"
              className="basic-multi-select"
              classNamePrefix="select"
              maxMenuHeight={150}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />{" "}
          </label>
        </div>
      ) : (
        <></>
      )}

      <div className="overflow-x-auto border-b z-1  border-gray-200 print:border-0 print:shadow-none max-h-[calc(100vh_-_290px)] sm:max-h-[calc(100vh_-_290px)]">
        {inventoryList ? (
          <>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {inventoryList.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="no-data-found">
                      <div
                        onClick={togglePopupSearch}
                        style={{ cursor: "pointer" }}
                      >
                        You don't have any items yet. Click here to add one!
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="flex items-center justify-between bg-white px-2 py-2 sm:px-6 sm:rounded-b-lg">
        <Pagination table={table} />
      </div>
      {isOpenSearch && (
        <PopupWeb
          content={
            <form onSubmit={handleAddNewItem} className="space-y-6">
              {/* Loader */}

              <div className="popupContainer space-y-4">
                {/* SEARCH BLOCK */}
                {!selectedItem ? (
                  <>
                    {errors.name && (
                      <p className="text-red-600 text-sm">{errors.name}</p>
                    )}

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Search For Item
                      </label>
                      <div className="relative mt-1">
                        <input
                          type="text"
                          value={lookUpItem}
                          onChange={(e) => setLookUpItem(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent the outer form from submitting
                              handleItemSearch(e); // Trigger your search function
                            }
                          }}
                          required
                          className="w-full rounded-md border border-gray-300 p-2 pr-20 text-sm focus:ring-2 focus:ring-custom-blue focus:border-custom-blue"
                          placeholder="Search For Item…"
                        />
                        <button
                          type="button"
                          onClick={handleItemSearch}
                          className="absolute inset-y-0 right-0 m-1 rounded bg-custom-blue px-3 text-white text-sm hover:bg-custom-blue-hover focus:ring-2 focus:ring-custom-blue"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    {/* RESULTS */}
                    <div className="max-h-80 overflow-auto space-y-2">
                      {lookUpResults.length > 0
                        ? lookUpResults.map((item) => (
                            <div
                              key={item.id}
                              onClick={() =>
                                handleSelctedItemInfo(
                                  item.id,
                                  item.name,
                                  item.manufacture,
                                  item.description,
                                  item.image
                                )
                              }
                              className="flex items-center space-x-4 rounded border p-2 hover:bg-gray-50 cursor-pointer"
                            >
                              {item.image !== "0" ? (
                                <img
                                  src={`${PublicFolder.baseURL}preview_${item.image}`}
                                  alt=""
                                  className="h-12 w-12 object-cover rounded"
                                />
                              ) : (
                                <BsImage size={40} className="text-gray-400" />
                              )}
                              <div>
                                <h3 className="font-medium">{item.name}</h3>
                                <p className="text-sm text-gray-600">
                                  {item.manufacture}
                                  {item.description && ` — ${item.description}`}
                                </p>
                              </div>
                            </div>
                          ))
                        : searchPerformed &&
                          lookUpItem.trim() !== "" && (
                            <div className="text-center py-8 space-y-2">
                              <p className="text-gray-500">No Results Found</p>
                              <button
                                type="button"
                                onClick={togglePopupOpen}
                                className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
                              >
                                Create New
                              </button>
                            </div>
                          )}
                    </div>
                  </>
                ) : (
                  <div className="flex items-center space-x-4">
                    <img
                      src={`${PublicFolder.baseURL}preview_${selectedItem.image}`}
                      alt=""
                      className="h-16 w-16 object-cover rounded"
                    />
                    <div className="flex-1">
                      <h3 className="font-medium">{selectedItem.name}</h3>
                      <p className="text-sm text-gray-600">
                        {selectedItem.manufacture}
                        {selectedItem.description &&
                          ` — ${selectedItem.description}`}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={openSearchBox}
                      className="text-custom-blue hover:underline"
                    >
                      Search Different
                    </button>
                  </div>
                )}

                {/* COMMON FIELDS */}
                {selectedItem && (
                  <>
                    {/** Tags */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Tags
                      </label>
                      <div
                        onClick={() => {
                          setIsTagsModalOpen(true);
                          setIsOpenSearch(false);
                        }}
                        className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                      >
                        {selectedItemTags.length
                          ? selectedItemTags
                              .map((val) => {
                                // Find the matching object in options
                                const foundOption = options.find((o) =>
                                  typeof o === "string"
                                    ? o === val
                                    : o.value === val
                                );
                                // Fallback to the raw value if something's missing
                                return typeof foundOption === "object"
                                  ? foundOption.label
                                  : foundOption || val;
                              })
                              .join(", ")
                          : "Select Tags"}
                      </div>
                    </div>

                    {/** Amount */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Amount
                      </label>
                      <input
                        type="number"
                        value={selectedAmount}
                        onChange={(e) => setSelectedAmount(e.target.value)}
                        className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                      />
                    </div>

                    {/** Identification & Serial */}
                    {["Identification Name", "Serial Number"].map((field) => (
                      <div key={field}>
                        <label className="block text-sm font-medium text-gray-700">
                          {field}
                        </label>
                        <div className="relative mt-1">
                          <input
                            type="text"
                            value={
                              field === "Identification Name"
                                ? selectedIdentificationName
                                : selectedSerialNumber
                            }
                            onChange={(e) =>
                              field === "Identification Name"
                                ? setSelectedIdentificationName(e.target.value)
                                : setSelectedSerialNumber(e.target.value)
                            }
                            className="w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                          />
                          {/*   <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ScannerButton
                              onScan={(value) =>
                                field === "Identification Name"
                                  ? setSelectedIdentificationName(value)
                                  : setSelectedSerialNumber(value)
                              }
                            />
                          </div>*/}
                        </div>
                      </div>
                    ))}

                    {/** Size */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Size
                      </label>
                      <input
                        type="text"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                      />
                    </div>

                    {/** Status */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <div
                        onClick={() => {
                          setIsStatusModalOpen(true);
                          setIsOpenSearch(false);
                        }}
                        className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                      >
                        {itemStatus || "Select Status"}
                      </div>
                    </div>

                    {/** Manufacture & Dates */}
                    {[
                      {
                        label: "Manufacturer",
                        state: manufacture,
                        setter: setManufacture,
                      },
                      {
                        label: "Model",
                        state: model,
                        setter: setModel,
                      },
                      {
                        label: "Manufacture Date",
                        state: selectedManufactureDate,
                        setter: setSelectedManufactureDate,
                        type: "date",
                      },
                      {
                        label: "Date of First Use",
                        state: selectedFirstUseDate,
                        setter: setSelectedFirstUseDate,
                        type: "date",
                      },
                      {
                        label: "Mandatory Service Date",
                        state: selectedMandatoryServiceDate,
                        setter: setSelectedMandatoryServiceDate,
                        type: "date",
                      },
                      {
                        label: "Retirement Date",
                        state: SelectedRetirementDate,
                        setter: setSelectedRetirementDate,
                        type: "date",
                      },
                    ].map(({ label, state, setter, type = "text" }) => (
                      <div key={label + "1"} className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                          {label}
                        </label>
                        <input
                          type={type}
                          value={state}
                          onChange={(e) => setter(e.target.value)}
                          placeholder={type === "date" ? "Select date" : ""}
                          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>

              {/* FOOTER */}
              <div className="sticky bottom-0 bg-white border-t p-4 flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={togglePopupSearch}
                  className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
                >
                  {loading ? "Loading..." : "Add"}
                </button>
                <button
                  type="button"
                  onClick={handleAddNewItemAndAddAnother}
                  className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue-hover"
                >
                  {loading ? "Loading..." : "Save & Add Another"}
                </button>
              </div>
            </form>
          }
          handleClose={togglePopupSearch}
          isOpen={isOpenSearch}
          showSubmitButton={false}
          headerText={<b>Add to Inventory</b>}
        />
      )}
      {/* SELECTION MODALS */}
      {isFilterOpen && (
        <PopupWeb
          content={
            <div className="space-y-4">
              <button
                className="close-button text-sm"
                onClick={handleClearFilters}
              >
                Clear all
              </button>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Tags
                </label>
                <div
                  onClick={() => {
                    setIsFilterTagsModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {tagsFilter.length
                    ? tagsFilter
                        .map((val) => {
                          const foundOption = options.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Tags"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Inspection
                </label>
                <div
                  onClick={() => {
                    setIsFilterInspectionModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {inspectionFilter.length
                    ? inspectionFilter
                        .map((val) => {
                          const foundOption = NextInspectionOptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Select Inspection"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Assigned To Member
                </label>
                <div
                  onClick={() => {
                    setIsFilterMemberModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {memberFilter.length
                    ? memberFilter
                        .map((val) => {
                          const foundOption = Memberoptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Assigned To Member"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Assigned To Location
                </label>
                <div
                  onClick={() => {
                    setIsFilterLocationModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {locationFilter.length
                    ? locationFilter
                        .map((val) => {
                          const foundOption = apparatusoptions.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Assigned To Location"}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Assigned To Bag
                </label>
                <div
                  onClick={() => {
                    setIsFilterBagModalOpen(true);
                    setIsFilterOpen(false);
                  }}
                  className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                >
                  {bagFilter.length
                    ? bagFilter
                        .map((val) => {
                          const foundOption = flatOptionsBags.find(
                            (o) => String(o.value) === String(val)
                          );
                          return foundOption ? foundOption.label : val;
                        })
                        .join(", ")
                    : "Assigned To Bag"}
                </div>
              </div>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      <SelectionModal
        isOpen={isFilterTagsModalOpen}
        onClose={() => {
          setIsFilterTagsModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChange(tags);
          setIsFilterTagsModalOpen(false);
       //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Tags"
        multiple
        defaultValue={tagsFilter}
      />

      <SelectionModal
        isOpen={isFilterInspectionModalOpen}
        onClose={() => {
          setIsFilterInspectionModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeInspection(tags);
          setIsFilterInspectionModalOpen(false);
       //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={NextInspectionOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Inspection"
        multiple
        defaultValue={inspectionFilter}
      />

      <SelectionModal
        isOpen={isFilterMemberModalOpen}
        onClose={() => {
          setIsFilterMemberModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeMember(tags);
          setIsFilterMemberModalOpen(false);
       //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={Memberoptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Assigned To Member"
        multiple
        defaultValue={memberFilter}
      />

      <SelectionModal
        isOpen={isFilterLocationModalOpen}
        onClose={() => {
          setIsFilterLocationModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeLocation(tags);
          setIsFilterLocationModalOpen(false);
       //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={apparatusoptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Assigned To Location"
        multiple
        defaultValue={locationFilter}
      />

      <SelectionModal
        isOpen={isFilterBagModalOpen}
        onClose={() => {
          setIsFilterBagModalOpen(false);
          setIsFilterOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleChangeBag(tags);
          setIsFilterBagModalOpen(false);
       //   setIsFilterOpen(true); // Reopen the parent popup
        }}
        options={flatOptionsBags.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Assigned To Bag"
        multiple
        defaultValue={bagFilter}
      />

      {isOpen1 && (
        <PopupWeb
          content={
            <form
              onSubmit={handleSubmit}
              id="newItemForm"
              className="formClass"
            >
              <div className="popupContainer space-y-4">
                {errors.name && (
                  <div className="errorInputLabel">{errors.name}</div>
                )}

                {/* Item Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Item Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                  />
                </div>

                {/* Manufacturer */}

                {/* Tags – using a simple text input for comma-separated tags */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tags
                  </label>
                  <div
                    onClick={() => {
                      setIsTagsModalOpenNew(true);
                      setIsOpen(false);
                    }}
                    className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                  >
                    {selectedItemTags.length
                      ? selectedItemTags
                          .map((val) => {
                            // Find the matching object in options
                            const foundOption = options.find((o) =>
                              typeof o === "string"
                                ? o === val
                                : o.value === val
                            );
                            // Fallback to the raw value if something's missing
                            return typeof foundOption === "object"
                              ? foundOption.label
                              : foundOption || val;
                          })
                          .join(", ")
                      : "Select Tags"}
                  </div>
                </div>

                {/* Identification Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Identification Name
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="text"
                      value={selectedIdentificationName}
                      onChange={(e) =>
                        setSelectedIdentificationName(e.target.value)
                      }
                      className="w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                    />

                    {/*     <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ScannerButton
                        onScan={(value) => setSelectedIdentificationName(value)}
                      />
                    </div>*/}
                  </div>
                </div>

                {/* Amount */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Amount
                  </label>
                  <input
                    type="number"
                    value={selectedAmount}
                    onChange={(e) => setSelectedAmount(e.target.value)}
                    className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                  />
                </div>

                {/* Serial Number */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Serial Number
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="text"
                      value={selectedSerialNumber}
                      onChange={(e) => setSelectedSerialNumber(e.target.value)}
                      className="w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                    />

                    {/*    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ScannerButton
                        onScan={(value) => setSelectedSerialNumber(value)}
                      />
                    </div>*/}
                  </div>
                </div>

                {/* Size */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Size
                  </label>
                  <input
                    type="text"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                    placeholder="Enter Size"
                    className="mt-1 w-full rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue"
                  />
                </div>

                {/* Status */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Status
                  </label>
                  <div
                    onClick={() => {
                      setIsStatusModalOpenNew(true);
                      setIsOpen(false);
                    }}
                    className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                  >
                    {itemStatus || "Select Status"}
                  </div>
                </div>

                {/* Manufacture & Date Fields */}
                {[
                  {
                    label: "Manufacture",
                    state: manufacture,
                    setter: setManufacture,
                  },
                  {
                    label: "Model",
                    state: model,
                    setter: setModel,
                  },

                  {
                    label: "Manufacture Date",
                    state: selectedManufactureDate,
                    setter: setSelectedManufactureDate,
                    type: "date",
                  },
                  {
                    label: "Date of First Use",
                    state: selectedFirstUseDate,
                    setter: setSelectedFirstUseDate,
                    type: "date",
                  },
                  {
                    label: "Mandatory Service Date",
                    state: selectedMandatoryServiceDate,
                    setter: setSelectedMandatoryServiceDate,
                    type: "date",
                  },
                  {
                    label: "Retirement Date",
                    state: SelectedRetirementDate,
                    setter: setSelectedRetirementDate,
                    type: "date",
                  },
                ].map(({ label, state, setter, type = "text" }) => (
                  <div key={label} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {label}
                    </label>
                    <input
                      type={type}
                      value={state}
                      onChange={(e) => setter(e.target.value)}
                      placeholder={type === "date" ? "Select date" : ""}
                      className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                    />
                  </div>
                ))}

                {/* Add Image */}
                <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInput}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {selectedImage && (
                      <div className="imageHolder">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={removehandleFileInput}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopupClose}
          isOpen={isOpen1}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b>New Item</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}
      <SelectionModal
        isOpen={isTagsModalOpen}
        onClose={() => {
          setIsTagsModalOpen(false);
          setIsOpenSearch(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setSelectedItemTags(tags);
          setIsTagsModalOpen(false);
        //  setIsOpenSearch(true); // Reopen the parent popup
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Tags"
        multiple
        defaultValue={selectedItemTags}
      />
      <SelectionModal
        isOpen={isStatusModalOpen}
        onClose={() => {
          setIsStatusModalOpen(false);
          setIsOpenSearch(true); // Reopen the parent popup
        }}
        onConfirm={(selection) => {
          setItemStatus(
            typeof selection === "string" ? selection : selection.label
          );
          setIsStatusModalOpen(false);
      //    setIsOpenSearch(true); // Reopen the parent popup
        }}
        options={[
          "Safe to use",
          "Monitor",
          "Not safe to use",
          "Missing",
          "Retired",
        ]}
        label="Select Status"
        defaultValue={itemStatus}
      />

      <SelectionModal
        isOpen={isTagsModalOpenNew}
        onClose={() => {
          setIsTagsModalOpenNew(false);
          setIsOpen(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setSelectedItemTags(tags);
          setIsTagsModalOpenNew(false);
       //  setIsOpen(true); // Reopen the parent popup
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Tags"
        multiple
        defaultValue={selectedItemTags}
      />
      <SelectionModal
        isOpen={isStatusModalOpenNew}
        onClose={() => {
          setIsStatusModalOpenNew(false);
          setIsOpen(true); // Reopen the parent popup
        }}
        onConfirm={(selection) => {
          setItemStatus(
            typeof selection === "string" ? selection : selection.label
          );
          setIsStatusModalOpenNew(false);
      //    setIsOpen(true); // Reopen the parent popup
        }}
        options={[
          "Safe to use",
          "Monitor",
          "Not safe to use",
          "Missing",
          "Retired",
        ]}
        label="Select Status"
        defaultValue={itemStatus}
      />

      {
        //inspectionOpen && <ItemPageInspectionTask />
      }
      {/* ------------------------------------------------------------- */}
      {/* Inspection Popup using the new checklist format */}
      {/* ------------------------------------------------------------- */}
      {isOpen4 && (
        <PopupWeb
          content={
            <form id="inspectionForm" className="formClass">
              <div className="popupContainer space-y-4">
                {/* Checklist Section */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
                  <label className="NewInputLabel" data-domain="Checklist">
                    <Select
                      defaultValue={{ value: "None", label: "None" }}
                      options={ChecklistOptions}
                      onChange={(e) => {
                        const { value, label } = e;
                        setDropdownQuery({ value, label });
                      }}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      maxMenuHeight={150}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </label>
                  <div className="PassFailTaskContainer mt-4">
                    {dropdownQuery.value === "None" ? (
                      <RenderNoChecklist
                        setInspectionStatus={setInspectionStatus}
                        inspectionStatusObject={inspectionStatusObject}
                      />
                    ) : (
                      <RenderChecklist
                        ChecklistData={ChecklistData}
                        dropdownQuery={dropdownQuery}
                        setInspectionStatus={setInspectionStatus}
                        inspectionStatusObject={inspectionStatusObject}
                      />
                    )}
                  </div>
                </div>

                {/* Image Upload and Notes Section */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
                  <div className="mb-4">
                    <span className="text-sm font-semibold">Add Image</span>
                    <div className="flex items-center mt-2">
                      <input
                        ref={fileRef}
                        onChange={handleFileInputInspectionPhotos}
                        multiple
                        type="file"
                        accept="image/*"
                        hidden
                      />
                      <div
                        onClick={() => fileRef.current.click()}
                        className="w-12 h-12 border flex items-center justify-center cursor-pointer mr-4"
                      >
                        <RiImageAddFill size={30} />
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {inspectionPhotos.map((image, index) => (
                          <div key={index} className="relative w-16 h-16">
                            <img
                              src={URL.createObjectURL(image)}
                              alt="preview"
                              className="object-cover w-full h-full rounded"
                            />
                            <button
                              type="button"
                              className="absolute top-0 right-0 bg-white rounded-full p-1 text-red-600"
                              onClick={() => removeInspectionPhotos(index)}
                            >
                              <RiCloseFill size={20} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm font-semibold block mb-1">
                      Notes
                    </label>
                    <textarea
                      onChange={(e) => setInspectionNotes(e.target.value)}
                      className="w-full border p-2 text-sm rounded"
                      placeholder="Note..."
                    />
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleInspectionSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}
      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleTagUpdate}>
                <div className="popupContainer">
                  <div>
                    <label className="NewInputLabel" data-domain="Tags">
                      <Select
                        isMulti
                        options={optionsAllTags}
                        onChange={setAddItemTags}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight={150}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={editItemPopupClose}
          isOpen={isOpen3}
          handleSubmit={handleTagUpdate}
          showSubmitButton={true}
          headerText={<b>Edit Item Details</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}
      {isInspectionFreqOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleFreqSubmit}>
                <label className="NewInputLabel" data-domain="Frequency">
                  <Select
                    options={optionsFreq}
                    onChange={(e) => setAddFreq(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    maxMenuHeight={150}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>
              </form>
            </>
          }
          handleClose={mainInspectionFreqPopup}
          isOpen={isInspectionFreqOpen}
          handleSubmit={handleFreqSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}
      {isRemoveOpen && (
        <PopupWeb
          content={
            <>
              <div className="popup-content">
                <h3>
                  Are you sure you want to remove these items from inventory?
                </h3>
                <p>
                  You are about to remove {isCheck.length} item
                  {isCheck.length > 1 ? "s" : ""} from inventory. This action
                  cannot be undone.
                </p>
              </div>
            </>
          }
          handleClose={mainRemovePopup}
          isOpen={isRemoveOpen}
          handleSubmit={handleRemoveSubmit}
          showSubmitButton={true}
          headerText={<b>Remove</b>}
          submitButtonText={loading ? "Loading..." : "Remove"}
          submitButtonClassName="remove-button" // Add a class for the remove button
        />
      )}

      {/* Column Visibility Popup */}
      <SelectionModal
        isOpen={isColumnsModalOpenNew}
        onClose={() => setIsColumnsModalOpenNew(false)}
        onConfirm={(selectedIds) => {
          const visibility = table.getAllLeafColumns().reduce((acc, col) => {
            acc[col.id] = selectedIds.includes(col.id);
            return acc;
          }, {});
          table.setColumnVisibility(visibility);
          setIsColumnsModalOpenNew(false);
        }}
        options={table
          .getAllLeafColumns()
          .filter((col) => col.getCanHide()) // <-- only hideable columns
          .map((col) => ({
            value: col.id,
            label: col.columnDef.label || col.id,
          }))}
        label="Show/Hide Columns"
        multiple
        defaultValue={table
          .getAllLeafColumns()
          .filter((col) => col.getIsVisible())
          .map((col) => col.id)}
      />

      <ToastNotifier />
    </div>
  );
};

export default InventoryWeb;
