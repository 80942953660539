import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/training"
    : "http://192.168.1.250:3001/api/training";

export default axios.create({
  baseURL,
});
