// src/components/Apparatus/ApparatusList.jsx
import React, { useState } from "react";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import { useAuth } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useMainLocationsData } from "./fetchApparatusData";
import PopupWeb from "../Popup/PopupNew";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import useIsMobile from "../../hooks/useIsMobile";
import DynamicForm from "../Popup/DynamicForm";
import SelectionModal from "../Popup/SelectionModal";

const ApparatusList = () => {
  const { currentUserRole, currentUserSiteId } = useAuth();
  const { data, refetch } = useMainLocationsData(currentUserSiteId);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  // Search state
  const [locationLookUpItem, setLocationLookUpItem] = useState("");

  // States for editing an existing location
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Handle search submission
  const handleSearchChange = (e) => {
    e.preventDefault();
    localStorage.setItem("LocationSearchQuery", locationLookUpItem);
    refetch();
  };

  // Navigation functions for Inventory and Checklist
  const handleApparatus = (id) => {
    navigate(`/location/${id}`);
  };

  const handleApparatusChecklist = (id) => {
    navigate(`/location/${id}/checklist`);
  };

  // Toggle dropdown visibility for each list item (if needed)
  const toggleClass = (id) => {
    document.getElementById(id)?.classList.toggle("show");
  };

  // Set up edit popup when a user clicks Edit
  const editApparatus = (apparatus, evt) => {
    evt.stopPropagation();
    setEditData(apparatus);
    setIsOpenEdit(true);
  };

  // Handler for submitting the edit form (using DynamicForm data)
  const handleSubmitEdit = async (formData) => {
    setLoading(true);
    try {
      const dataForm = new FormData();
      dataForm.set("apparatusId", editData.id);
      dataForm.set("name", formData.name);
      dataForm.set("status", formData.status);
      dataForm.set("apparatusType", formData.type);
      dataForm.set("oldImgPath", editData.image || "");
      dataForm.set("currentUserSiteId", currentUserSiteId);
      dataForm.set("appartusOrder", formData.displayOrder || "");
      if (formData.selectedImage) {
        dataForm.append("file", formData.selectedImage);
      }
      await ApparatusFinder.post("/editApparatus", dataForm, {
        "content-type": "multipart/form-data",
      });
      refetch();
      notify("Location updated successfully!", "success");
      setIsOpenEdit(false);
    } catch (err) {
      notify("Failed to update location. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Handler for deleting a location
  const handleDelete = async () => {
    setLoading(true);
    try {
      await ApparatusFinder.post("/deleteApparatus", {
        apparatusId: editData.id,
        oldImgPath: editData.image || "",
        currentUserSiteId,
      });
      refetch();
      notify("Location deleted successfully!", "success");
      setIsOpenEdit(false);
    } catch (err) {
      notify("Failed to delete location. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Top: Search and Add Location */}
      <div className="filter-search-toggle-container">
        <div className="filter-search-container">
          {/* Instead of a popup form, navigate to your dedicated NewLocationPage */}
          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => navigate("/location/new")}
          >
            Add Location
          </button>
          <div className="search-input-container">
            <form onSubmit={handleSearchChange} className="search-input-container">
              <input
                className="inventorySearchButton"
                placeholder="Search Locations"
                type="search"
                value={locationLookUpItem}
                onChange={(e) => setLocationLookUpItem(e.target.value)}
              />
            </form>
          </div>
        </div>
        <div className="mb-4"></div>
      </div>

      {/* List of Locations */}
      {data?.data.data.apparatus.length > 0 ? (
        data.data.data.apparatus.map((apparatus) => (
          <div
            key={apparatus.id}
            className="apparatusHolder"
            onClick={() => toggleClass(`${apparatus.id}`)}
          >
            <div className="apparatusContainer">
              <div className="apparatusNameContainer">
                {apparatus.image && (
                  <img
                    src={PublicFolder.baseURL + "preview_" + apparatus.image}
                    alt="Did Not Load"
                    className="apparatusImg"
                  />
                )}
                <div className="apparatusName">{apparatus.name}</div>
                {apparatus.status === "IN-SERVICE" ? (
                  <div className="apparatusINStatus">
                    <b>{apparatus.status}</b>
                  </div>
                ) : apparatus.status === "OUT-OF-SERVICE" ? (
                  <div className="apparatusOUTStatus">
                    <b>{apparatus.status}</b>
                  </div>
                ) : null}
                <div
                  className={apparatus.image ? "apparatusDropdown" : "apparatusDropdownNoImage"}
                  id={apparatus.id}
                >
                  <div className="apparatusDropdown2">
                    <div
                      className="apparatusInventoryLink"
                      onClick={() => handleApparatus(apparatus.id)}
                    >
                      <b>Inventory</b>
                    </div>
                    <div
                      className="apparatusChecklistLink"
                      onClick={() => handleApparatusChecklist(apparatus.id)}
                    >
                      <b>Check List</b>
                    </div>
  <div
    className="apparatusChecklistLinkEdit"
    onClick={(evt) => {
      evt.stopPropagation();
      navigate(`/location/${apparatus.id}/edit`);
    }}
  >
    <b>Edit</b>
  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="apparatusEmptyHolder">
          <div className="apparatusEmptyName">No Current Locations</div>
          <div className="apparatusEmptyButton">Tap + to start</div>
        </div>
      )}

      {/* Edit Location Popup */}
      {isOpenEdit && (
        <PopupWeb
          isOpen={isOpenEdit}
          handleClose={() => setIsOpenEdit(false)}
          content={
            <DynamicForm
              fields={[
                { name: "name", label: "Name", required: true, placeholder: "Enter name" },
                { name: "type", label: "Type", required: true, placeholder: "Enter type" },
                { name: "status", label: "Status", required: true, placeholder: "Enter status" },
                { name: "displayOrder", label: "Display Order", inputType: "number", placeholder: "Enter display order" },
                { name: "selectedImage", label: "Add Image", type: "file" },
              ]}
              initialData={{
                name: editData.name,
                type: editData.type,
                status: editData.status,
                displayOrder: editData.displayorder,
              }}
              loading={loading}
              onSubmit={handleSubmitEdit}
              onCancel={() => setIsOpenEdit(false)}
              errors={errors}
            />
          }
          showSubmitButton={false}
          headerText="Edit Location"
          submitButtonText={loading ? "Loading..." : "Save"}
          loading={loading}
        />
      )}

      {/* (Optional) Delete confirmation can be integrated into the edit popup or shown separately */}
      <PopupWeb
        isOpen={isOpenEdit /* or use separate state if desired */}
        handleClose={() => setIsOpenEdit(false)}
        content={
          <div className="deleteInfoApparatusContainer">
            <b>This location will no longer be available</b>
          </div>
        }
        handleSubmit={handleDelete}
        showSubmitButton={true}
        headerText={<b>Delete - {editData.name}</b>}
        submitButtonText={loading ? "Loading..." : "Delete"}
      />

      <ToastNotifier />
    </>
  );
};

export default ApparatusList;
