// src/pages/NotFoundPage.jsx
import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6">
      <img
        src="/assets/notfound.png"
        alt="Not Found"
        className="w-32 h-32 mb-4"
      />
      <h1 className="text-2xl font-bold text-red-600 mb-2">Not Found</h1>
      <p className="text-gray-700 mb-4">
        The content you are looking for doesn't exist.
      </p>
      <Link
        to="/"
        className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
      >
        Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
