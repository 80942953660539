import { useQuery } from "@tanstack/react-query";
import TrainingFinder from "../../apis/TrainingFinder";

// Updated fetch function
const fetchEventInfoData = ({ queryKey }) => {
  const [_key, currentUserSiteId, id] = queryKey;

  return TrainingFinder.get(`/trainingDetails`, {
    params: {
      currentUserSiteId,
      trainingId: id, // Pass id as a query param if required
    },
  });
};

// Custom hook updated to accept both parameters
export const useTrainingData = (currentUserSiteId, id) => {
  return useQuery(
    ["EventDetails", currentUserSiteId, id], 
    fetchEventInfoData,
    {
      enabled: Boolean(currentUserSiteId && id), // Optional: Only fetch if both params are available
    }
  );
};
