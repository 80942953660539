import React, { useState } from "react";
import ScannerButton from "./ScannerButton";
import { useAuth } from "../../context/AuthContext";
import ScannerFinder from "../../apis/ScannerFinder";
import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";

function SearchWithScanner() {
  const [searchBy, setSearchBy] = useState("serial");
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const { currentUserSiteId } = useAuth();
  const navigate = useNavigate();

  // Called when the user scans a code
  const handleScan = (scannedValue) => {
    // If scanned value contains a "?", parse it as a query
    if (scannedValue.includes("?")) {
      const queryPart = scannedValue.split("?")[1]; // e.g. "itemId:123"
      const [key, value] = queryPart.split(":");
      handleSearch(value);
    } else {
      // Otherwise, treat scanned value as the search value
      setSearchValue(scannedValue);
      // Auto-trigger search for serial, identification, Bag, or Compartment types
      if (
        searchBy === "serial" ||
        searchBy === "identification" ||
        searchBy === "Bag" ||
        searchBy === "Compartment"
      ) {
        handleSearch(scannedValue);
      }
    }
  };

  // API call for serial-based searches
  const fetchData2 = async (itemID) => {
    try {
      const response = await ScannerFinder.get("/getItemBySerial", {
        params: { itemID, currentUserSiteId },
      });
      return response.data.data.ItemInfo; // array of items
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // API call for identification-based searches
  const fetchDataIdentification = async (itemID) => {
    try {
      const response = await ScannerFinder.get("/getItemByIdentification", {
        params: { itemID, currentUserSiteId },
      });
      return response.data.data.ItemInfo; // array of items
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // API call for bag-based searches
  const fetchDataBag = async (bagID) => {
    try {
      const response = await ScannerFinder.get("/getBagScanner", {
        params: { bagId: bagID, currentUserSiteId },
      });
      return response.data.data.bagInfo; // array of bag objects
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // API call for compartment-based searches
  const fetchDataCompartment = async (compartmentID) => {
    try {
      const response = await ScannerFinder.get("/getCompartmentScanner", {
        params: { compartmentId: compartmentID, currentUserSiteId },
      });
      return response.data.data.compartmentInfo; // array of compartment objects
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // Handle searching
  const handleSearch = async (query) => {
    const searchQuery = query || searchValue;
    setHasSearched(true);
    setLoading(true);
    setResults([]);

    try {
      if (searchBy === "serial") {
        const apiResults = await fetchData2(searchQuery);
        setResults(apiResults);
      } else if (searchBy === "identification") {
        const apiResults = await fetchDataIdentification(searchQuery);
        setResults(apiResults);
      } else if (searchBy === "Bag") {
        const apiResults = await fetchDataBag(searchQuery);
        setResults(apiResults);
      } else if (searchBy === "Compartment") {
        const apiResults = await fetchDataCompartment(searchQuery);
        setResults(apiResults);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Navigate to detail page based on search type and result clicked
  const handleItemClick = (item) => {
    if (searchBy === "serial" || searchBy === "identification") {
      navigate(`/inventory/${item.itemid}/itempage`, {
        state: { itemid: item.itemid },
      });
    } else if (searchBy === "Bag") {
      navigate(`/location/${item.apparatusid}`, {
        state: { bagId: item.bagid },
      });
    } else if (searchBy === "Compartment") {
      navigate(`/location/${item.apparatusid}`, {
        state: { compartmentId: item.compartmentid },
      });
    }
  };

  return (
    <div className="mt-8 max-w-md mx-auto p-4 space-y-4 border border-gray-200 rounded-md shadow-sm bg-white">
      {/* Title row: "Search by" */}
      <div className="flex justify-between items-center">
        <label className="text-gray-700 font-semibold">Search by:</label>
        <div className="flex items-center space-x-4">
          <select
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onChange={(event) => setSearchBy(event.target.value)}
            value={searchBy}
          >
            <option value={"serial"}>{"Serial #"}</option>
            <option value={"identification"}>{"Identification Name"}</option>
            <option value={"Compartment"}>{"Compartment"}</option>
            <option value={"Bag"}>{"Bag"}</option>
          </select>
        </div>
        <div className="flex items-right space-x-2 justify-end ">
          <button
            onClick={() => handleSearch()}
            className="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-custom-blue-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Search
          </button>
        </div>
      </div>

      {/* Input with embedded Scan button */}
      <label
        className="NewInputLabel"
        data-domain={
          searchBy === "serial"
            ? "Serial #"
            : searchBy === "identification"
            ? "Identification Name"
            : searchBy
        }
      >
        <div className="relative mt-1">
          <input
            type="text"
            className="NewInput"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={
              searchBy === "serial"
                ? "Enter Serial #"
                : searchBy === "identification"
                ? "Enter Identification Name"
                : `Enter ${searchBy} ID`
            }
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ScannerButton onScan={handleScan} />
          </div>
        </div>
      </label>

      {/* Results area */}
      {loading ? (
        <div className="text-gray-500 flex items-center justify-center h-16">
          Loading...
        </div>
      ) : !hasSearched ? null : results.length === 0 ? (
        <div className="text-gray-500 flex flex-col items-center justify-center h-24 space-y-2">
          <svg
            className="w-8 h-8 text-gray-400"
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 9.75V19.5a2.25 2.25 0 01-2.25 2.25H6.75
                 A2.25 2.25 0 014.5 19.5V4.5A2.25 2.25 0 016.75 2.25h6.75
                 c.621 0 1.218.247 1.656.688l3.375 3.375c.44.438.69 1.035.69
                 1.657z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 2.25V5.25c0 .621.504 1.125 1.125
                 1.125h3"
            />
          </svg>
          <p className="text-sm">
            No results found
            {searchValue && (
              <span className="ml-1 text-gray-400">for “{searchValue}”</span>
            )}
          </p>
        </div>
      ) : (
        <div className="max-h-64 overflow-y-auto space-y-4">
          {results.map((item) => (
            <div
              key={item.itemid || item.bagid || item.cid}
              className="flex items-start space-x-4 border border-gray-200 p-3 rounded-md cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={() => handleItemClick(item)}
            >
              <img
                src={
                  PublicFolder.baseURL +
                  "preview_" +
                  (item.image || item.imagepath || "default.jpg")
                }
                alt="Did Not Load"
                className="selected-item-img"
              />
              <div>
                <h2 className="font-semibold text-gray-800">
                  {item.name || item.bagname || item.compartmentname || "Unnamed Item"}
                </h2>
                <p className="text-sm text-gray-600">
                  {item.identificationname ||
                    item.description ||
                    ""}
                </p>
                {searchBy === "serial" || searchBy === "identification" ? (
                  <>
                    <p className="text-sm text-gray-500 mt-1">
                      Serial: {item.serialnumber || "N/A"}
                    </p>
                    <p className="text-sm text-gray-500">
                      Manufacture: {item.manufacture || "N/A"}
                    </p>
                  </>
                ) : searchBy === "Bag" ? (
                  <>
                    
                    <p className="text-sm text-gray-500">
                      Bag ID: {item.bagid || "N/A"}
                    </p>
                    <p className="text-sm text-gray-600">
                      Location: {item.apparatus || "N/A"}
                    </p>
                    <p className="text-sm text-gray-600">
                      Compartment: {item.compartmentname || "N/A"}
                    </p>
                  </>
                ) : searchBy === "Compartment" ? (
                  <p className="text-sm text-gray-600">
                  Location: {item.apparatus || "N/A"}
                </p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchWithScanner;
