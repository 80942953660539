import { useQuery } from "@tanstack/react-query";
import TrainingFinder from "../../apis/TrainingFinder";

const fetchTrainingPage = ({ queryKey }) => {
  var  categoryFilter = localStorage.getItem('EventCategoryFilters');
  var  statusFilter = localStorage.getItem('EventStatusFilters');
  var  memberFilter = localStorage.getItem('EventMemberFilter');
  var  startDate = localStorage.getItem('EventFromDateFilter');
  var  endDate = localStorage.getItem('EventThruDateFilter');
  var noteCategoryFilter = localStorage.getItem('EventNoteCategoryFilter');
  var noteMemberFilter = localStorage.getItem('EventNoteMemberFilter');

  const currentUserSiteId = queryKey[1];
  return TrainingFinder.get(`/main`, {
    params: {
      currentUserSiteId,
      categoryFilter,
      statusFilter,
      memberFilter,
      startDate,
      endDate,
      noteCategoryFilter,
      noteMemberFilter,
    },
  });
};

export const useTrainingData = (currentUserSiteId) => {
  return useQuery(["mainEvents", currentUserSiteId], fetchTrainingPage);
};
