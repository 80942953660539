import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import DynamicForm from "../Popup/DynamicForm";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import SelectionModal from "../Popup/SelectionModal";
import ClipLoader from "react-spinners/ClipLoader";
import { usePageTitle } from "../Navbar/PageTitleContext";
import TrainingFinder from "../../apis/TrainingFinder";
import { useTrainingData } from "./fetchEventInfoData";

const TrainingEditInfo = () => {
    const { id } = useParams();
  

  const navigate = useNavigate();
  const { currentUserSiteId } = useAuth();
  const { setPageTitle } = usePageTitle("");
  const { data, refetch } = useTrainingData(currentUserSiteId, id);

  const [formLoading, setFormLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    eventName: "",
    eventDescription: "",
    eventAddress: "",
    category: "",
    status: "",
    newDate: "",
  });


    // Validation rules and helper functions
    const validationRules = {
      trainingForm: {
        eventName: "Please enter a name",
        category: "Please select a category",
        newDate: "Please select a date",
      },
      noteForm: {
        trainingNewNoteSubjectId: "Please select a category",
      },
    };

  const [trainingId, setTrainingId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [activeModal, setActiveModal] = useState(null);

  const modalOptions = {
    status: [
      { value: "Active", label: "Active" },
      { value: "Completed", label: "Completed" },
    ],
  };

  const fields = [
    {
      name: "eventName",
      label: "Event Name",
      required: true,
      placeholder: "Enter event name",
    },
    {
      name: "eventDescription",
      label: "Event Description",
      placeholder: "Enter description",
    },
    {
      name: "eventAddress",
      label: "Event Address",
      placeholder: "Enter address",
    },
    {
      name: "category",
      label: "Category",
      isModal: true,
      required: true,
      placeholder: "Select category",
    },
    {
      name: "status",
      label: "Status",
      isModal: true,
      required: true,
      placeholder: "Select status",
    },
    {
      name: "newDate",
      label: "Date",
      inputType: "date",
      required: true,
      placeholder: "Select date",
    },
  ];

  useEffect(() => {
    if (data) {
      const trainingInfo = data?.data?.data?.trainingInfo || [];
      const categoryList = data?.data?.data?.categoryList || [];

      if (trainingInfo.length > 0) {
        const t = trainingInfo[0];
        setFormData({
          eventName: t.training_name || "",
          eventDescription: t.description || "",
          eventAddress: t.address || "",
          category: t.category || "",
          status: t.status || "",
          newDate: t.date ? new Date(t.date).toISOString().substring(0, 10) : "",
        });
        setPageTitle(`Event - ${t.training_name}`);
        setTrainingId(t.id);
        const matchedCat = categoryList.find((c) => c.categoryid === t.categoryid);
        if (matchedCat) {
          setSelectedCategory({ value: matchedCat.categoryid, label: matchedCat.name });
        }
      }

      const formattedCategories = categoryList.map((cat) => ({
        value: cat.categoryid,
        label: cat.name,
      }));
      setCategoryOptions(formattedCategories);
    }
  }, [data, setPageTitle]);

  const onChangeField = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onOpenModal = (fieldName) => {
    setActiveModal(fieldName);
  };

  const handleModalConfirm = (selected) => {
    if (activeModal === "category") {
      // If only a value is returned, look it up in categoryOptions.
      if (typeof selected === "string") {
        const foundCategory = categoryOptions.find(
          (cat) => String(cat.value) === String(selected)
        );
        setSelectedCategory(foundCategory);
      } else {
        setSelectedCategory(selected);
      }
    } else {
      // For other modal fields, update formData directly.
      const value = typeof selected === "string" ? selected : selected.label;
      setFormData((prev) => ({ ...prev, [activeModal]: value }));
    }
    setActiveModal(null);
  };

  const handleSubmit = async () => {
    setFormLoading(true);
    setErrors({});

    // Basic validation
    if (!formData.eventName) {
      setErrors((prev) => ({ ...prev, eventName: "Event name is required" }));
      setFormLoading(false);
      return;
    }
    if (!formData.category) {
      setErrors((prev) => ({ ...prev, category: "Category is required" }));
      setFormLoading(false);
      return;
    }
    if (!formData.status) {
      setErrors((prev) => ({ ...prev, status: "Status is required" }));
      setFormLoading(false);
      return;
    }
    if (!formData.newDate) {
      setErrors((prev) => ({ ...prev, newDate: "Date is required" }));
      setFormLoading(false);
      return;
    }

    try {
      const response = await TrainingFinder.post("/updatetrainingdetails", {
        training_name: formData.eventName,
        training_desc: formData.eventDescription,
        trainingAddress: formData.eventAddress,
        trainer: [],
        categoryId: selectedCategory?.value || "",
        eventType: "", // optional
        date: formData.newDate === "" ? null : formData.newDate,
        trainingId: trainingId,
        trainingStatus: formData.status,
        currentUserSiteId,
      });
      notify("Training updated successfully!", "success");
      navigate(`/eventinfo/${id}`);
    } catch (err) {
      notify("Failed to update training. Please try again.", "error");
    } finally {
      setFormLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/eventinfo/${id}`);
  };

  if (!data) {
    return (
      <div className="p-4">
        <ClipLoader loading={true} size={35} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <ToastNotifier />
      <div className="border p-4 rounded">
        <DynamicForm
          fields={fields}
          formData={formData}
          onChangeField={onChangeField}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          onOpenModal={onOpenModal}
          loading={formLoading}
        />
      </div>

      {activeModal && (
        <SelectionModal
          isOpen={true}
          onClose={() => setActiveModal(null)}
          onConfirm={handleModalConfirm}
          options={
            activeModal === "category"
              ? categoryOptions
              : modalOptions[activeModal] || []
          }
          label={`Select ${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`}
        />
      )}

<div className="sticky bottom-0 pb-5 bg-white border-t p-4 flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={handleSubmit}
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue"
          >
            {formLoading ? "Loading..." : "Save"}
          </button>
        </div>
    </div>
  );
};

export default TrainingEditInfo;
