import React, { useState, useRef, useEffect } from "react";
import PopupWeb from "../Popup/PopupNew";
import ToDoFinder from "../../apis/ToDo";
import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import TextareaAutosize from "react-textarea-autosize";
import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";
import { AiOutlineMenu } from "react-icons/ai";
import { useToDoData } from "./fetchToDoData";
import Select from "react-select";
import { notify } from "../../ToastNotifier";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PDFReportTemplate from "../Reports/PDFReportTemplate";
import { usePageTitle } from "../Navbar/PageTitleContext";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);
  setPageTitle("To-Do");

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </button>
        <span>{pageIndex + 1}</span>
        <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </button>
      </div>
    </div>
  );
};

const ToDoHeader = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentFirstName,
    currentLastName,
  } = useAuth();
  const { data, refetch } = useToDoData(currentUserSiteId);

  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    setEventList(data?.data.data.results);
  }, [data]);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [memberFilter, setMemebrFilter] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Popup create new events
  const [isOpen7, setIsOpen7] = useState(false);
  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };

  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
  };

  // Popup for new event selection
  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };

  // Retrieve filters from localStorage with error handling
  useEffect(() => {
    const getParsedLocalStorageItem = (key) => {
      const item = localStorage.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (err) {
          // If parsing fails, assume it's a single string value and wrap it in an array
          return [item];
        }
      }
      return [];
    };

    const storedEventCategoryFilter = getParsedLocalStorageItem("EventCategoryFilters");
    const storedEventStatusFilter = getParsedLocalStorageItem("EventStatusFilters");
    const storedEventMemberFilter = getParsedLocalStorageItem("EventMemberFilter");

    setStatusFilter(storedEventStatusFilter);
    setCategoryFilter(storedEventCategoryFilter);
    setMemebrFilter(storedEventMemberFilter);
  }, []);

  // Input state
  const [toDoName, setTrainingName] = useState("");
  const [toDoNote, setTrainingDesc] = useState("");
  const [trainer] = useState([]);
  const [newDate, setNewDate] = useState();
  const [status, setStatus] = useState("Active");
  const [category, setCategory] = useState("");
  const [eventType, setEventType] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      toDoName: "Please enter a name",
      toDoNote: "Please select a category",
      newDate: "Please select a date",
    },
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setTrainingName("");
    setTrainingDesc("");
    setCategory("");
    setEventType("");
    setNewDate("");
    setStatus("Active");
  };

  const handleNewToDoSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = {
      toDoName,
      toDoNote,
      newDate,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
      const response = await ToDoFinder.post("/newToDoList", {
        toDoName: toDoName,
        toDoNote: toDoNote,
        date: newDate,
        status: status,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });

      newUpcomingPopupClose();
      clearForm();
      refetch();
      notify("Event created successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to create event. Please try again.", "error");
    }
  };

  const selecttraining = (id) => {
    history(`/training/${id}`);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  // Update selectedOptions whenever statusFilter changes
  useEffect(() => {
    setSelectedOptions(
      StatusOptions.filter((option) => statusFilter.includes(option.value))
    );
  }, [statusFilter]);

  const setCategoryFunc = (label) => {
    setCategory(label);
    setEventType("");
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.date1, {
      id: "date1",
      cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      header: () => <div className="min-max-width">Date</div>,
    }),
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      header: () => <div className="min-max-width">Title</div>,
    }),
    columnHelper.accessor((row) => row.note, {
      id: "note",
      cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      header: () => <div className="min-max-width">Note</div>,
    }),
    columnHelper.accessor((row) => row.todostatus, {
      id: "todostatus",
      cell: (info) => <div className="min-max-width">{info.getValue()}</div>,
      header: () => <div className="min-max-width">Status</div>,
    }),
  ];

  const initialPage = parseInt(localStorage.getItem("InventoryPage")) || 0;

  const table = useReactTable({
    data: eventList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } },
  });

  // Updated PDF columns using the same accessor for status as the table
  const pdfColumns = [
    { header: "Date", accessor: (row) => row.date1 },
    { header: "Title", accessor: (row) => row.title },
    { header: "Note", accessor: (row) => row.note },
    { header: "Status", accessor: (row) => row.todostatus },
  ];

  const handleClearFilters = () => {
    setStatusFilter([]);
    setSelectedOptions([]);
    localStorage.removeItem("EventStatusFilters");
    localStorage.removeItem("EventPage");
    refetch();
  };

  const handleStatusChange = (e) => {
    const statusSearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("EventStatusFilters", JSON.stringify(statusSearch));
    localStorage.setItem("EventPage", 0);
    table.setPageIndex(0);
    setStatusFilter(statusSearch);
    setSelectedOptions(e);
    refetch();
  };

  const StatusdefaultValues = StatusOptions.filter((option) =>
    statusFilter.includes(option.value)
  );

  return (
    <div className="containerInventory">
      <div className="top">
        <div className="filter-search-toggle-container">
          <div className="filter-search-container">
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={handleClearFilters}
            >
              Clear
            </button>

            <PDFReportTemplate
              table={table}
              title="Event Report"
              reportDateRange="2024-01-01 to 2024-12-31"
              category="Inventory"
              members={["John Doe", "Jane Smith"]}
              filters={selectedOptions}
              logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
              pdfColumns={pdfColumns}
              includeDateRange={false}
              includeCategory={false}
              includeMembers={false}
              includeFilters={true}
            />

            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopupFilter}
            >
              Filters
            </button>

            <div className="selected-options-display">
              <div className="selected-options-row">
                {selectedOptions.map((option, index) => (
                  <div key={index} className="selected-option">
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="middle-container">
        <div className="middle">
          <div className="inventoryList no-extra-content">
            <div className="ReportResultsContainer no-extra-content">
              <div className="reportsPopupContainer no-extra-content">
                {eventList ? (
                  <>
                    <div className={`table-container ${isCollapsed ? "collapsed" : ""}`}>
                      <table className="custom-table">
                        <thead className="custom-table-header-container">
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th key={header.id} className="custom-table-header">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="custom-table-cell">
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <Pagination table={table} />
      </div>

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewToDoSubmit}>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Title">
                    <TextareaAutosize
                      onChange={(e) => setTrainingName(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                      required
                    />
                    {errors.toDoName && <div className="errorInputLabel">{errors.toDoName}</div>}
                  </label>
                  <label className="NewInputLabel" data-domain="Note">
                    <TextareaAutosize
                      onChange={(e) => setTrainingDesc(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                    />
                  </label>
                  <label className="NewInputLabel" data-domain="Status">
                    <Select
                      options={StatusOptions}
                      defaultValue={StatusOptions.find((option) => option.value === "Active")}
                      onChange={(e) => setStatus(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </label>
                  <label className="NewInputLabel" data-domain="Date">
                    <input
                      className="NewInput"
                      type="date"
                      placeholder=""
                      onChange={(e) => setNewDate(e.target.value)}
                      required
                    />
                    {errors.newDate && <div className="errorInputLabel">{errors.newDate}</div>}
                  </label>
                </div>
              </form>
            </>
          }
          handleClose={newUpcomingPopupClose}
          isOpen={isOpen7}
          handleSubmit={handleNewToDoSubmit}
          showSubmitButton={true}
          headerText={<b>New To-Do</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>
              <label className="NewInputLabel123" data-domain="Status">
                <Select
                  isMulti
                  options={StatusOptions}
                  onChange={handleStatusChange}
                  value={StatusdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen8 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={newUpcomingPopup}>
                    <b>New Event</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={newSelectPopup}
          isOpen={isOpen8}
          showSubmitButton={false}
          headerText={<b>New Event</b>}
        />
      )}
    </div>
  );
};

export default ToDoHeader;
