import React, { useEffect } from "react";


const MAX_PAGE_BUTTONS = 5;

function getVisiblePages(currentPage, totalPages) {
  if (totalPages <= MAX_PAGE_BUTTONS) {
    return [...Array(totalPages).keys()]; // e.g. [0, 1, 2, ..., totalPages-1]
  }

  let start = Math.max(currentPage - 2, 0);
  let end = Math.min(start + MAX_PAGE_BUTTONS - 1, totalPages - 1);

  // Shift window if we don't have enough pages to fill the limit
  if (end - start < MAX_PAGE_BUTTONS - 1) {
    start = end - (MAX_PAGE_BUTTONS - 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  // Persist current page index in localStorage (optional)
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  // Calculate total rows (unpaginated)
  const totalRows = table.getPrePaginationRowModel().rows.length;
  // Calculate which rows are displayed
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  // Determine visible page numbers (for desktop)
  const visiblePages = getVisiblePages(pageIndex, pageCount);

  return (
    <>
      {/* --------------------- MOBILE VIEW --------------------- */}
      {/* Visible only on screens smaller than the 'sm' breakpoint */}
      <div className="block sm:hidden w-full mt-4">
        {/* Row count text */}
        <div className="text-sm text-gray-700 mb-2">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Minimal pagination controls in one row */}
        <div className="flex items-center justify-between">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* Page X of Y label */}
          <span className="text-sm text-gray-700">
            Page {pageIndex + 1} of {pageCount}
          </span>

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>

      {/* --------------------- DESKTOP VIEW --------------------- */}
      {/* Hidden on screens smaller than 'sm' */}
      <div className="hidden sm:flex sm:flex-row sm:items-center sm:justify-between w-full mt-4">
        {/* Left side: "Showing X - Y of Z" */}
        <div className="text-sm text-gray-700">
          {totalRows === 0 ? 0 : startRow} - {endRow} of {totalRows}
        </div>

        {/* Right side: Full pagination controls */}
        <div className="flex flex-wrap items-center gap-1 justify-end">
          {/* First Page */}
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            «
          </button>

          {/* Previous Page */}
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ‹
          </button>

          {/* If the first visible page isn't the first page, show 1 + ellipses */}
          {visiblePages[0] > 0 && (
            <>
              <button
                onClick={() => table.setPageIndex(0)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                1
              </button>
              <span className="px-2">...</span>
            </>
          )}

          {/* Numbered Page Buttons */}
          {visiblePages.map((pageNum) => {
            const isCurrent = pageIndex === pageNum;
            return (
              <button
                key={pageNum}
                onClick={() => table.setPageIndex(pageNum)}
                disabled={isCurrent}
                className={`px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200
                  ${
                    isCurrent
                      ? "bg-teal-100 text-teal-700"
                      : "text-gray-700"
                  }
                  disabled:opacity-80`}
              >
                {pageNum + 1}
              </button>
            );
          })}

          {/* If the last visible page isn't the last page, show ellipses + last page */}
          {visiblePages[visiblePages.length - 1] < pageCount - 1 && (
            <>
              <span className="px-2">...</span>
              <button
                onClick={() => table.setPageIndex(pageCount - 1)}
                className="px-3 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 text-gray-700"
              >
                {pageCount}
              </button>
            </>
          )}

          {/* Next Page */}
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            ›
          </button>

          {/* Last Page */}
          <button
            onClick={() => table.setPageIndex(pageCount - 1)}
            disabled={!table.getCanNextPage()}
            className="px-2 py-1 text-sm border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
