// src/pages/NewLocationPage.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { useAuth } from "../../context/AuthContext";
import DynamicForm from "../Popup/DynamicForm";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import ClipLoader from "react-spinners/ClipLoader";
import SelectionModal from "../Popup/SelectionModal";
import { usePageTitle } from "../Navbar/PageTitleContext";
import useIsMobile from "../../hooks/useIsMobile";

const NewLocationPage = () => {
  const { currentUserSiteId } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const isMobile = useIsMobile();

  // set page title
  const { setPageTitle } = usePageTitle("");
  setPageTitle("Create New Location");

  // Modal options for fields that use modal selection.
  const modalOptions = {
    status: [
      { value: "IN-SERVICE", label: "IN-SERVICE" },
      { value: "OUT-OF-SERVICE", label: "OUT-OF-SERVICE" },
      { value: "N/A", label: "N/A" },
    ],
    type: [
      { value: "Vehicle", label: "Vehicle" },
      { value: "Storage", label: "Storage" },
    ],
    // Add additional modal field options as needed.
  };

  // Fully controlled form data.
  const [formData, setFormData] = useState({
    name: "",
    displayOrder: "",
    status: "",
    type: "",
    selectedImage: null,
  });

  // activeModal holds the field name currently using a modal (e.g., "status" or "type").
  const [activeModal, setActiveModal] = useState(null);

  // Field configuration for DynamicForm.
  // For modal-based fields, we mark them with isModal: true.
  const fields = [
    { name: "name", label: "Name", required: true, placeholder: "Enter name" },
    {
      name: "displayOrder",
      label: "Display Order",
      inputType: "number",
      placeholder: "Enter display order",
    },

    {
      name: "status",
      label: "Status",
      isModal: true,
      required: true,
      placeholder: "Select Status",
    },
    {
      name: "type",
      label: "Type",
      isModal: true,
      required: true,
      placeholder: "Select Type",
    },
    {
      name: "selectedImage",
      label: "Add Image",
      type: "file",
    },
  ];

  // Callback when a non-modal field changes.
  const onChangeField = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  // Callback when a modal field is clicked.
  const onOpenModal = (fieldName) => {
    setActiveModal(fieldName);
  };

  // When the user selects an option from the modal.
  const handleModalConfirm = (selected) => {
    const value = typeof selected === "string" ? selected : selected.label;
    setFormData((prev) => ({ ...prev, [activeModal]: value }));
    setActiveModal(null);
  };

  // On form submission, merge data and send the API call.
  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    try {
      // Basic validation
      if (!formData.name) {
        setErrors((prev) => ({ ...prev, name: "Name is required" }));
        setLoading(false);
        return;
      }
      if (!formData.status) {
        setErrors((prev) => ({ ...prev, status: "Status is required" }));
        setLoading(false);
        return;
      }
      if (!formData.type) {
        setErrors((prev) => ({ ...prev, type: "Type is required" }));
        setLoading(false);
        return;
      }

      const dataForm = new FormData();
      dataForm.set("name", formData.name);
      dataForm.set("status", formData.status);
      dataForm.set("apparatusType", formData.type);
      dataForm.set("currentUserSiteId", currentUserSiteId);
      dataForm.set("appartusOrder", formData.displayOrder || "");
      if (formData.selectedImage) {
        dataForm.append("file", formData.selectedImage);
      }

      await ApparatusFinder.post("/createapparatus", dataForm, {
        "content-type": "multipart/form-data",
      });
      notify("Location created successfully!", "success");
      navigate("/location");
    } catch (err) {
      notify("Failed to create location. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/location");
  };

  return (
    <div className="p-4">
      <ToastNotifier />
      <div className="border p-4 rounded">
        <ClipLoader loading={loading} size={35} />
        <DynamicForm
          fields={fields}
          formData={formData}
          onChangeField={onChangeField}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          onOpenModal={onOpenModal}
          loading={loading}
          fullScreen={isMobile} // This makes the form take up the entire screen on mobile
        />
      </div>

      {/* Render the selection modal if a modal field is active */}
      {activeModal && (
        <SelectionModal
          isOpen={true}
          onClose={() => setActiveModal(null)}
          onConfirm={handleModalConfirm}
          options={modalOptions[activeModal] || []}
          label={`Select ${
            activeModal.charAt(0).toUpperCase() + activeModal.slice(1)
          }`}
        />
      )}

<div className="sticky bottom-0 pb-5 bg-white border-t p-4 flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className="rounded bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={handleSubmit}
            className="rounded bg-custom-blue px-4 py-2 text-white hover:bg-custom-blue"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
    </div>
  );
};

export default NewLocationPage;
