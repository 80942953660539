import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/site"
    : "http://192.168.1.250:3001/api/site";

export default axios.create({
  baseURL,
});
