import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { useModalScrollLock } from "./useModalScrollLock";

const SelectionModal = ({
  isOpen,
  onClose,
  onConfirm,
  options,
  label,
  multiple = false,
  defaultValue = multiple ? [] : "",
  showClearButton = true, // <-- New prop

}) => {
  const [search, setSearch] = useState("");
  // 'selected' is the internal state that holds the current selection.
  const [selected, setSelected] = useState(multiple ? [] : "");

  const [isMobile, setIsMobile] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Whenever the modal opens, set the selected value from defaultValue.
  useEffect(() => {
    if (isOpen) {
      setSelected(
        multiple
          ? Array.isArray(defaultValue)
            ? defaultValue
            : []
          : defaultValue || ""
      );
      setSearch("");
    } else {
      setSearch("");
    }
  }, [isOpen, defaultValue, multiple]);

  // Handle option click
  const handleOptionClick = (opt) => {
    const value = typeof opt === "string" ? opt : opt.value;

    if (!multiple) {
      // If the clicked item is already selected, unselect it
      if (String(selected) === String(value)) {
        setSelected("");
        onConfirm("");
      } else {
        setSelected(value);
        onConfirm(value);
      }
      handleClose();
    } else {
      // multiple = true: toggle the item in the array
      setSelected((prev) =>
        prev.map((v) => String(v)).includes(String(value))
          ? prev.filter((v) => String(v) !== String(value))
          : [...prev, value]
      );
    }
  };

  const handleConfirm = () => {
    onConfirm(selected);
    handleClose();
  };

  const handleClose = () => {
    if (isMobile) {
      setIsClosing(true);
      setTimeout(() => {
        // Forcefully remove any scroll lock
        document.body.style.overflow = "auto";
        setIsClosing(false);
        onClose();
      }, 300);
    } else {
      onClose();
    }
  };
  // Filter options based on the search term.
  const filtered = options.filter((opt) => {
    const labelText = typeof opt === "string" ? opt : opt.label;
    return (labelText || "").toLowerCase().includes(search.toLowerCase());
  });

  const handleTransitionEnd = () => {
    if (isClosing) {
      document.body.style.overflow = "auto";
      setIsClosing(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="fixed inset-0 z-[100]"
    >
      <div className="fixed inset-0 bg-black/30" onClick={handleClose} />

      <div
        className={`fixed inset-0 ${
          isMobile && !multiple
            ? "flex items-end"
            : isMobile && multiple
            ? ""
            : "flex mt-[100px] justify-center p-4"
        }`}
      >
        <Dialog.Panel
          onTransitionEnd={handleTransitionEnd}
          className={`bg-white shadow-lg transition-transform duration-300 ${
            isMobile
              ? `fixed inset-0 flex flex-col h-full ${
                  isClosing ? "translate-y-full" : "translate-y-0"
                }`
              : "max-w-md w-full max-h-[80vh] flex flex-col rounded-lg overflow-hidden"
          }`}
        >
          {/* Modal Header */}
          <div className="p-4 border-b flex justify-between items-center">
            <h2 className="text-lg font-semibold">{label}</h2>
            <button onClick={handleClose} className="text-gray-600 text-xl">
              ✖
            </button>
          </div>

          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-3 border-b focus:outline-none"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          {/* Scrollable Content */}
          <div
            className="flex-1 min-h-0 overflow-y-auto touch-pan-y overscroll-contain -webkit-overflow-scrolling-touch"
            style={{ paddingBottom: multiple ? "64px" : 0 }}
          >
            {filtered.map((opt, idx) => {
              const value = typeof opt === "string" ? opt : opt.value;
              const labelText = typeof opt === "string" ? opt : opt.label;
              // Use string conversion for comparing values.
              const isSelected = multiple
                ? Array.isArray(selected) &&
                  selected.map((v) => String(v)).includes(String(value))
                : String(selected) === String(value);
              return (
                <div
                  key={idx}
                  className={`
                    flex justify-between items-center p-4 cursor-pointer
                    hover:bg-gray-200 ${isSelected ? "bg-gray-100" : ""}
                  `}
                  onClick={() => handleOptionClick(opt)}
                >
                  <span>{labelText}</span>
                  {multiple && isSelected && <AiOutlineCheck size={20} />}
                </div>
              );
            })}
          </div>

          {/* Confirm/Clear Buttons (for multiple select) */}
          {multiple && (
  <div
    className={`p-4 border-t bg-white flex ${
      showClearButton ? "justify-between" : "justify-end"
    }`}
  >
    {showClearButton && (
      <button
        type="button"
        onClick={() => {
          onConfirm([]);
          handleClose();
        }}
        className="rounded px-4 py-2 text-gray-700 hover:bg-gray-100"
      >
        Clear
      </button>
    )}
    <button
      onClick={handleConfirm}
      disabled={!selected.length}
      className="rounded bg-custom-blue px-4 py-2 text-white disabled:opacity-50"
    >
      Confirm
    </button>
  </div>
)}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default SelectionModal;
