import React, { useContext, useState } from "react";
import TrainingFinder from "../../apis/TrainingFinder";
import { TrainingContext } from "../../context/TrainingContext";

import PopupWeb from "../Popup/PopupNew";

import { useAuth } from "../../context/AuthContext";
import Select from "react-select";
import { notify } from "../../ToastNotifier";
import SelectionModal from "../Popup/SelectionModal";

const TrainingSignIn = () => {
  const { currentUserRole, currentUserSiteId } = useAuth();
  const [isMemebrsModalOpen, setIsMemebrsModalOpen] = useState(false);

  // Members avaiable
  const { memberList, setMemberList } = useContext(TrainingContext);
  const { trainingMembers, setTrainingMembers } = useContext(TrainingContext);

  const { trainingId } = useContext(TrainingContext);

  const { setMemberAttendingCount, setNoteList } = useContext(TrainingContext);
  const [addMember, setAddMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const validationRules = {
    signMembers: {
      addMember: "Please add at least one member",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    // Check if addMember is empty
    if (addMember.length === 0) {
      newErrors.signMembers = "Please add at least one member";
      isValid = false;
    }

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
  };

  const handleNewMemberSubmit = async (e) => {
    console.log("dklfmdl");
    //stop the page from reloading
    // e.preventDefault();
    setAddMembers(e);

    //   if (loading) return; // Prevent double submission
    //   const formValues = {
    //     addMember,
    //   };
    //console.log(formValues)
    //   if (!validateForm(formValues, validationRules.signMembers)) {
    //     return;
    //  }

    setLoading(true);

    try {
      const response = await TrainingFinder.post("/addMember", {
        addMember: e,
        trainingId,
        currentUserSiteId,
      });

      setMemberList(response.data.data.memberNames);
      setTrainingMembers(response.data.data.trainingMembers);
      setMemberAttendingCount(response.data.data.trainingMembers.length);
      notify("Member Added", "success"); // Show success notification
      clearForm();
      setAddMembers([]);
     // addMemberPopupClose();
      setLoading(false);
    } catch (err) {
      notify("Failed to add member. Please try again.", "error"); // Show error notification
      setLoading(false);
    }
  };

  //Popup Add Members
  const [isOpen3, setIsOpen3] = useState(false);
  const addMemberPopupClose = () => {
    setMemberListPopup(!memberListPopup);

    setIsMemebrsModalOpen(false);
  };

  const addMemberPopup = () => {
    //
  //  setMemberListPopup(!memberListPopup);

    setIsMemebrsModalOpen(!isMemebrsModalOpen);
    // setIsOpen3(false);
  };

  //
  //Delete Member
  const [deleteMemberHolder, setDeleteMemberHolder] = useState("");
  const [deleteMemberId, setDeleteMemberId] = useState("");
  const [deleteUserId, setDeleteUserId] = useState("");

  //Popup delete Member
  const [isOpen1, setIsOpen1] = useState(false);
  const deleteMemberPopup = () => {
    setIsOpen1(!isOpen1);
    setMemberListPopup(!memberListPopup);
  };

  const removeMember = (user_id, trainingipm_id, first, last) => {
    setDeleteMemberHolder(first + " " + last);
    setDeleteMemberId(trainingipm_id);
    setIsOpen1(!isOpen1);
    setMemberListPopup(!memberListPopup);
    setDeleteUserId(user_id);
  };

  const handleDeleteMemberSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    setLoading(true);

    try {
      //eslint-disable-next-line
      const response = await TrainingFinder.post("/removeMember", {
        deleteMemberId,
        trainingId,
        currentUserSiteId,
        deleteUserId,
      });
      notify("Member Removed", "success"); // Show success notification

      setTrainingMembers(response.data.data.trainingMembers);
      setMemberList(response.data.data.memberNames);
      setMemberAttendingCount(response.data.data.trainingMembers.length);
      setNoteList(response.data.data.trainingnotes);
      setLoading(false);

      deleteMemberPopup();
    } catch (err) {
      notify("Failed to removed member. Please try again.", "error"); // Show error notification
      setLoading(false);
    }
  };

  //Popup delete Member
  const { memberPopupOpen, setMemberPopupOpen } = useContext(TrainingContext);
  const MainPopup = () => {
    setMemberPopupOpen(!memberPopupOpen);
  };

  //Popup delete Member
  const [memberListPopup, setMemberListPopup] = useState(true);

  let options = memberList.map(function (data) {
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      id: data.user_id,
    };
  });

  return (
    <div className="sliderHolder">
      {memberListPopup && (
        <PopupWeb
          content={
            <>
              {currentUserRole === "Admin" || currentUserRole === "Advanced" ? (
                //admin
                <div className="personalContainer2">
                  {trainingMembers.length > 0 ? (
                    trainingMembers.map((data, i) => {
                      // console.log(data);
                      return (
                        <div key={i}>
                          <div
                            className="personal"
                            onClick={() =>
                              removeMember(
                                data.user_id,
                                data.trainingmembers_id,
                                data.firstname,
                                data.lastname
                              )
                            }
                          >
                            {data.firstname} {data.lastname}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="personal">Empty</div>
                    </>
                  )}
                </div>
              ) : (
                //Not admin
                <div className="personalContainerTraining">
                  {trainingMembers.length > 0 ? (
                    trainingMembers.map((data, i) => {
                      return (
                        <div key={i}>
                          <div className="personal">
                            {data.firstname} {data.lastname}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="personal">Empty</div>
                    </>
                  )}
                </div>
              )}
            </>
          }
          handleClose={MainPopup}
          isOpen={memberListPopup}
          handleSubmit={addMemberPopup}
          showSubmitButton={true}
          headerText={<b>Members</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Members
                  </label>
                  <div
                    onClick={() => {
                      setIsMemebrsModalOpen(true);
                      setIsOpen3(false);
                    }}
                    className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                  >
                    {addMember.length
                      ? addMember
                          .map((val) => {
                            const foundOption = options.find(
                              (o) => String(o.value) === String(val)
                            );
                            return foundOption ? foundOption.label : val;
                          })
                          .join(", ")
                      : "Add Member"}
                  </div>{" "}
                  {errors.signMembers && (
                    <div className="errorInputLabel">{errors.signMembers}</div>
                  )}
                </div>
              </div>
            </>
          }
          handleClose={addMemberPopupClose}
          isOpen={isOpen3}
          handleSubmit={handleNewMemberSubmit}
          showSubmitButton={true}
          headerText={<b>Add Members</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
          loading={loading}
        />
      )}

      <SelectionModal
        isOpen={isMemebrsModalOpen}
        onClose={() => {
          setIsMemebrsModalOpen(!isMemebrsModalOpen);
      //    setMemberListPopup(!memberListPopup);

          //   setIsOpen3(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          handleNewMemberSubmit(tags);
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Member"
        multiple
        showClearButton={false} // <-- Hides the Clear button
        defaultValue={addMember}
      />

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="mb-6 font-bold">{deleteMemberHolder}</div>
            </>
          }
          handleClose={deleteMemberPopup}
          isOpen={isOpen1}
          handleSubmit={handleDeleteMemberSubmit}
          showSubmitButton={true}
          headerText={<b>Remove Member</b>}
          submitButtonText={loading ? "Loading..." : "Remove"}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TrainingSignIn;
