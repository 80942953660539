import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PopupWeb from "../Popup/PopupNew";
import { useAuth } from "../../context/AuthContext";
import TrainingFinder from "../../apis/TrainingFinder";
import { TrainingContext } from "../../context/TrainingContext";
import TrainingSignIn from "./TrainingSignIn";
import TrainingItems from "./TrainingItems";
import TrainingPhotos from "./TrainingPhotos";
import ClipLoader from "react-spinners/ClipLoader";
import { MdArrowBackIosNew } from "react-icons/md";

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";

import { usePageTitle } from "../Navbar/PageTitleContext";
import SelectionModal from "../Popup/SelectionModal";
import { useTrainingData } from "./fetchEventInfoData";

const TrainingHeader = () => {
  const { id } = useParams();
  const { setPageTitle } = usePageTitle();
  const { currentUserSiteId } = useAuth();
  const navigate = useNavigate();
  const { data, refetch } = useTrainingData(currentUserSiteId, id);
  const [loading, setLoading] = useState(false);

  // State for training data – stored as an array for consistency
  const [training, setTraining] = useState([]);
  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");
  const [trainingStatus, setTrainingStatus] = useState("");
  const [trainingAddress, setTrainingAddress] = useState("");
  const [date, setDate] = useState("");

  // Other states
  const [category, setCategory] = useState("");
  const [eventNotesList, setEventNotesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [errors, setErrors] = useState({});

  // Context states
  const { trainingId, setTrainingId } = useContext(TrainingContext);
  const {
    trainingMembers,
    setTrainingMembers,
    setMemberList,
    setApparatusList,
    memberAttendingCount,
    setMemberAttendingCount,
    setAddPhoto,
    setTrainingItems,
  } = useContext(TrainingContext);
  const [photoCount, setPhotoCount] = useState(0);
  const [trainingItemsCount, setTrainingItemsCount] = useState(0);
  const [noteList, setNoteList] = useState([]);
  const [noteCount, setNoteCount] = useState(0);

  // Additional UI states
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isOpenDetailsForm, setIsOpenDetailsForm] = useState(false);
  const [isCategoryModalOpenNew, setIsCategoryModalOpenNew] = useState(false);
  const [isActiveModalOpenNew, setIsActiveModalOpenNew] = useState(false);

  const [isNoteCategoryModalOpen, setIsNoteCategoryModalOpen] = useState(false);
  const [isNoteMemberModalOpenNew, setIsNoteMemberModalOpen] = useState(false);

  const [deletePopup, setDeletePopup] = useState(false);
  const [endPopup, setEndPopup] = useState(false);
  const [trainingNewNoteId, setTrainingNewNoteId] = useState("");
  const [trainingNewNoteSubjectId, setTrainingNewNoteSubjectId] = useState("");
  const [trainingNewNote, setTrainingNewNote] = useState("");
  const [trainingNewMembers, setTrainingNewMembers] = useState([]);
  const [trainingNoteSubject, seTrainingNoteSubject] = useState("");
  const [addNoteMember, setAddNoteMember] = useState([]);

  const {
    memberPopupOpen,
    setMemberPopupOpen,
    itemPopupOpen,
    setItemPopupOpen,
    photoPopupOpen,
    setPhotoPopupOpen,
  } = useContext(TrainingContext);

  // For note deletion popup
  const [isOpenNoteRemove, setIsOpenNoteRemove] = useState(false);

  // Validation rules and helper functions
  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      date: "Please select a date",
    },
    noteForm: {
      trainingNewNoteSubjectId: "Please select a category",
    },
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;
    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });
    setErrors(newErrors);
    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }
    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
  };

  // Data fetching: store training info as an array
  useEffect(() => {
    if (data) {
      const trainingInfo = data?.data?.data?.trainingInfo || [];
      // Update training info only if we have at least one event
      if (trainingInfo.length > 0) {
        setTraining(trainingInfo);
        setPageTitle(`Event - ${trainingInfo[0].training_name}`);
        setTrainingName(trainingInfo[0].training_name);
        setTrainingDesc(trainingInfo[0].description);
        setTrainingAddress(trainingInfo[0].address);
        setTrainingStatus(trainingInfo[0].status);
        setTrainingId(trainingInfo[0].id);
        setCategory(trainingInfo[0].categoryid);
        setDate(
          trainingInfo[0].date
            ? new Date(trainingInfo[0].date).toISOString().substring(0, 10)
            : ""
        );
      }
      // Update other lists and counts
      setCategoryList(data?.data?.data?.categoryList || []);
      setEventNotesList(data?.data?.data?.eventNoteList || []);
      setTrainingMembers(data?.data?.data?.trainingMembers || []);
      setMemberAttendingCount(data?.data?.data?.trainingMembers?.length || 0);
      setAddPhoto(data?.data?.data?.trainingPhotos || []);
      setPhotoCount(data?.data?.data?.trainingPhotos?.length || 0);
      setTrainingItems(data?.data?.data?.trainingItems || []);
      setTrainingItemsCount(data?.data?.data?.trainingItems?.length || 0);
      setMemberList(data?.data?.data?.memberNames || []);
      setApparatusList(data?.data?.data?.apparatusList || []);
      setNoteList(data?.data?.data?.trainingnotes || []);
      setNoteCount(data?.data?.data?.trainingnotes?.length || 0);
    }
  }, [
    data,
    setPageTitle,
    setTrainingId,
    setTrainingMembers,
    setMemberList,
    setApparatusList,
  ]);

  // Popup toggles
  const togglePopup = () => {
    setIsOpen1(!isOpen1);
  };

  const trainingDetailsPopupClose = () => {
    setIsOpen2(!isOpen2);
  };

  const trainingDetailsPopup = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(!isOpen1);
  };

  const detailsPopup = () => {
    setIsOpenDetails(!isOpenDetails);
  };

  const detailsFormPopup = () => {
    setIsOpenDetails(!isOpenDetails);
    setIsOpenDetailsForm(!isOpenDetailsForm);
    setTrainingNewNoteId("");
    setTrainingNewNoteSubjectId("");
    setTrainingNewNote("");
    setTrainingNewMembers([]);
    setAddNoteMember([]);
    seTrainingNoteSubject("");
  };

  // Handlers for edit, complete, delete, and notes
  const handleEditTrainingSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = { trainingName, category, date };
    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }
    setLoading(true);
    try {
      const response = await TrainingFinder.post("/updatetrainingdetails", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainingAddress: trainingAddress,
        trainer: [], // Trainer remains as an empty array if not used
        categoryId: category,
        eventType: "", // eventType can be added if needed
        date: date === "" ? null : date,
        trainingId: id,
        trainingStatus,
        currentUserSiteId,
      });
      // Update training state based on returned data (assumed array)
      refetch();

      trainingDetailsPopupClose();
      clearForm();
      notify("Event updated successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to update event. Please try again.", "error");
    }
  };

  const handleCompletedTrainingSubmit = async (e) => {
    if (trainingId === undefined) return;
    e.preventDefault();
    try {
      await TrainingFinder.post("/completed", {
        trainingId,
        currentUserSiteId,
      });
      notify("Event moved to completed successfully!", "success");
      navigate("/upcomingtrainings");
    } catch (err) {
      notify("Failed to update event. Please try again.", "error");
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await TrainingFinder.post("/deleteTraining", {
        trainingId,
        currentUserSiteId,
      });
      navigate("/events");
      notify("Event deleted successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to delete event. Please try again.", "error");
    }
  };

  const toggleDeletePopup = () => {
    setIsOpen1(!isOpen1);
    setDeletePopup(!deletePopup);
  };
  const toggleDeletePopup2 = () => {
    setDeletePopup(!deletePopup);
  };

  const toggleEndPopup = () => {
    setIsOpen1(!isOpen1);
    setEndPopup(!endPopup);
  };

  const handleMemberPopup = () => {
    setMemberPopupOpen(!memberPopupOpen);
  };

  const handleItemPopup = () => {
    setItemPopupOpen(!itemPopupOpen);
  };

  const handlePhotoPopup = () => {
    setPhotoPopupOpen(!photoPopupOpen);
  };

  const editSubject = async (
    trainingnote_id,
    subject,
    note,
    members,
    categoryId
  ) => {
    try {
      setAddNoteMember(
        members.map((data2) => ({
          value: data2.user_id,
          label: data2.firstname + " " + data2.lastname,
        }))
      );
      setTrainingNewMembers(
        members.map((data2) => ({
          value: data2.user_id,
          label: data2.firstname + " " + data2.lastname,
        }))
      );
      setTrainingNewNoteId(trainingnote_id);
      seTrainingNoteSubject(subject);
      setTrainingNewNoteSubjectId(categoryId);
      setTrainingNewNote(note);
      setIsOpenDetails(!isOpenDetails);
      setIsOpenDetailsForm(!isOpenDetailsForm);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const formValues = { trainingNewNote, trainingNewNoteSubjectId };
    if (!validateForm(formValues, validationRules.noteForm)) {
      return;
    }
    setLoading(true);
    try {
      if (trainingNewNoteId === "") {
        const response = await TrainingFinder.post("/addNote", {
          trainingId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        setNoteList(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      } else {
        const response = await TrainingFinder.post("/updateNote", {
          trainingId,
          trainingNewNoteId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        setNoteList(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      }
      setLoading(false);
      detailsFormPopup();
      notify("Saved note successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to save note. Please try again.", "error");
    }
  };

  const handleNoteDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await TrainingFinder.post("/deleteNote", {
        trainingId,
        trainingNewNoteId,
        currentUserSiteId,
      });
      setLoading(false);
      setNoteList(response.data.data.noteList);
      toggleNoteRemove();
      notify("Removed note successfully!", "success");
    } catch (err) {
      setLoading(false);
      notify("Failed to remove note. Please try again.", "error");
    }
  };

  const toggleNoteRemove = (trainingnote_id) => {
    setTrainingNewNoteId(trainingnote_id);
    setIsOpenNoteRemove(!isOpenNoteRemove);
    setIsOpenDetails(!isOpenDetails);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const options = categoryList.map((data) => ({
    value: data.categoryid,
    label: data.name,
    id: data.categoryid,
  }));

  const eventNoteOptions = eventNotesList.map((data) => ({
    value: data.categoryid,
    label: data.name,
  }));

  const eventNoteMemberList = trainingMembers.map((t) => ({
    value: t.user_id,
    label: t.firstname + " " + t.lastname,
  }));
  const optionstainingMemebrsTags = trainingMembers.map((t) => ({
    value: t.user_id,
    label: t.firstname + " " + t.lastname,
  }));

  const defaultNoteMebersSelection = trainingNewMembers.map(
    (item) => item.value
  );

  const correctedMebersTags = Array.isArray(addNoteMember)
    ? addNoteMember
        // Keep only non-null values
        .filter((tag) => {
          if (typeof tag === "object") {
            // Keep only if tag.value is non-null
            return tag.value != null;
          }
          // If it's a primitive, keep only if non-null
          return tag != null;
        })
        // Convert objects to their value, else keep the primitive
        .map((tag) => (typeof tag === "object" ? tag.value : tag))
    : [];

  const trailingActions2 = (trainingnote_id) => (
    <TrailingActions>
      <SwipeAction onClick={() => toggleNoteRemove(trainingnote_id)}>
        <div className="unassignDiv2">
          <b>Remove</b>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <>
      <div className="filter-search-toggle-container">
        <div className="filter-search-container">
          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={(evt) => {
              evt.stopPropagation();
              navigate(`/events`);
            }}
          >
            Go Back
          </button>

          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={(evt) => {
              evt.stopPropagation();
              navigate(`/eventinfo/${id}/edit`);
            }}
          >
            <b>Edit</b>
          </button>
        </div>
      </div>

      {/* Header: Show training info if available */}
      {training.length > 0 ? (
        <div className="trainingPageSideRightContainer2">
          <div className="itemPageAmountContainer">
            <div className="itemPageAmountName">
              <b>Date</b>
            </div>
            <div className="itemPageAmountNumber22">
              <b>{training[0].date}</b>
            </div>
          </div>
          <div className="itemPageAmountContainer">
            <div className="itemPageAmountName">
              <b>Category</b>
            </div>
            <div className="itemPageAmountNumber22">
              <b>{training[0].category}</b>
            </div>
          </div>
          <div className="itemPageAmountContainer">
            <div className="itemPageAmountName">
              <b>Description</b>
            </div>
            <div className="itemPageAmountNumber22">
              <b>{training[0].description}</b>
            </div>
          </div>
          <div className="itemPageAmountContainer">
            <div className="itemPageAmountName">
              <b>Address</b>
            </div>
            <div className="itemPageAmountNumber22">
              <b>{training[0].address}</b>
            </div>
          </div>
          <div className="itemPageAmountContainer2">
            <div className="itemPageAmountName">
              <b>Status</b>
            </div>
            <div className="itemPageAmountNumber22">
              <b>{training[0].status}</b>
            </div>
          </div>
        </div>
      ) : null}

      {/* Summary counts (rendered if training exists) */}
      {training.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 p-4 bg-white rounded-lg shadow-md">
          <div
            className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
            onClick={handleMemberPopup}
          >
            <div className="text-base font-semibold text-gray-700 sm:text-lg">
              Members
            </div>
            <div className="text-xl font-bold text-black sm:text-2xl">
              {memberAttendingCount}
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
            onClick={handleItemPopup}
          >
            <div className="text-base font-semibold text-gray-700 sm:text-lg">
              Items
            </div>
            <div className="text-xl font-bold text-black sm:text-2xl">
              {trainingItemsCount}
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
            onClick={detailsPopup}
          >
            <div className="text-base font-semibold text-gray-700 sm:text-lg">
              Notes
            </div>
            <div className="text-xl font-bold text-black sm:text-2xl">
              {noteCount}
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
            onClick={handlePhotoPopup}
          >
            <div className="text-base font-semibold text-gray-700 sm:text-lg">
              Photos
            </div>
            <div className="text-xl font-bold text-black sm:text-2xl">
              {photoCount}
            </div>
          </div>
        </div>
      ) : null}

      <div className="trainingHolder"></div>

      {/* Edit/Delete Popup trigger */}
      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={trainingDetailsPopup}>
                    <b>Edit Event Information</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={toggleDeletePopup}>
                    <b>Delete Event</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={<b>Event Information</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {/* Edit Event Popup */}
      {isOpen2 && (
        <PopupWeb
          content={
            <>
              {training.length > 0 ? (
                <form onSubmit={handleEditTrainingSubmit}>
                  <div className="popupContainer">
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Event Name
                        </label>
                        <TextareaAutosize
                          onChange={(e) => setTrainingName(e.target.value)}
                          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                          maxRows={10}
                          type="text"
                          placeholder=""
                          value={trainingName}
                          required
                        />
                        {errors.trainingName && (
                          <div className="errorInputLabel">
                            {errors.trainingName}
                          </div>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Event Description
                        </label>
                        <TextareaAutosize
                          onChange={(e) => setTrainingDesc(e.target.value)}
                          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                          maxRows={10}
                          type="text"
                          placeholder=""
                          value={trainingDesc}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Address
                        </label>
                        <TextareaAutosize
                          onChange={(e) => setTrainingAddress(e.target.value)}
                          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                          maxRows={10}
                          type="text"
                          placeholder=""
                          value={trainingAddress}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Category
                        </label>
                        <div
                          onClick={() => {
                            setIsCategoryModalOpenNew(true);
                            setIsOpen2(false);
                          }}
                          className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                        >
                          {category
                            ? options.find((opt) => opt.value === category)
                                ?.label || "Add Category"
                            : "Add Category"}
                        </div>
                        {errors.category && (
                          <div className="errorInputLabel">
                            {errors.category}
                          </div>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Status
                        </label>
                        <div
                          onClick={() => {
                            setIsActiveModalOpenNew(true);
                            setIsOpen2(false);
                          }}
                          className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                        >
                          {trainingStatus
                            ? StatusOptions.find(
                                (opt) => opt.value === trainingStatus
                              )?.label || "Add Status"
                            : "Add Status"}
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Date
                        </label>
                        <input
                          type="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          placeholder="Select date"
                          className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                        />
                        {errors.newDate && (
                          <div className="errorInputLabel">
                            {errors.newDate}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <>Select an event first</>
              )}
            </>
          }
          handleClose={trainingDetailsPopupClose}
          isOpen={isOpen2}
          handleSubmit={handleEditTrainingSubmit}
          showSubmitButton={true}
          headerText={<b>Edit Event</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
          loading={loading}
        />
      )}

      <SelectionModal
        isOpen={isCategoryModalOpenNew}
        onClose={() => {
          setIsCategoryModalOpenNew(false);
          setIsOpen2(true); // Reopen parent popup
        }}
        onConfirm={(tags) => {
          setCategory(tags);
          setIsCategoryModalOpenNew(false);
          //  setIsOpen2(true);
        }}
        options={options.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Category"
        defaultValue={category}
      />

      <SelectionModal
        isOpen={isActiveModalOpenNew}
        onClose={() => {
          setIsActiveModalOpenNew(false);
          setIsOpen2(true);
        }}
        onConfirm={(tags) => {
          setTrainingStatus(tags);
          setIsActiveModalOpenNew(false);
          //  setIsOpen2(true);
        }}
        options={StatusOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Category"
        defaultValue={trainingStatus}
      />

      {deletePopup && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                <div className="deleteInfoApparatusContainer">
                  <b>This event will no longer be available</b>
                </div>
                <div className="BottomFixPosition">
                  <div className="PopupBottomHolder">
                    <button
                      className="button-39 padding-right two-button"
                      onClick={toggleDeletePopup}
                    >
                      Cancel
                    </button>
                    <button
                      className="button-39 DeletePopupButton two-button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={toggleDeletePopup2}
          isOpen={deletePopup}
          showSubmitButton={false}
          headerText={<b>Delete - {trainingName}</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {endPopup && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>Complete this event</b>
              </div>
              <div className="editTrainingBtnContainer">
                <div
                  className="confirmTrainingBtn"
                  onClick={handleCompletedTrainingSubmit}
                >
                  <b>Confirm</b>
                </div>
              </div>
              <div className="editTrainingBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleEndPopup}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleEndPopup}
          isOpen={endPopup}
          showSubmitButton={false}
          headerText={<b>Complete - {trainingName}</b>}
        />
      )}

      {isOpenDetails && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                {noteList.length > 0
                  ? noteList.map((data, i) => (
                      <div key={i}>
                        <SwipeableList>
                          <SwipeableListItem
                            trailingActions={trailingActions2(
                              data.trainingnote_id
                            )}
                          >
                            <div className="noteListHolder">
                              <div className="noteListSubjectHolder">
                                <div className="noteListSubject">
                                  <b>{data.subject}</b>
                                </div>
                                <div
                                  className="noteSubmitItemBtn"
                                  onClick={() =>
                                    editSubject(
                                      data.trainingnote_id,
                                      data.subject,
                                      data.note,
                                      data.trainingnotemembers,
                                      data.categoryid
                                    )
                                  }
                                >
                                  <b>Edit</b>
                                </div>
                              </div>
                              {data.trainingnotemembers[0].firstname ===
                              null ? null : (
                                <div className="noteListNote">
                                  <div className="notelistMembersTitle">
                                    <b>Members</b>
                                  </div>
                                  <div
                                    className="notelistMembers"
                                    id={data.trainingnote_id}
                                  >
                                    {data.trainingnotemembers.map(
                                      (data2, i) => (
                                        <div key={i}>
                                          {data2.firstname} {data2.lastname}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="noteListNote">
                                <div className="notelistMembersTitle">
                                  <b>Notes</b>
                                </div>
                                <div className="notelistMembers">
                                  {data.note}
                                </div>
                              </div>
                            </div>
                          </SwipeableListItem>
                        </SwipeableList>
                      </div>
                    ))
                  : null}
              </div>
            </>
          }
          handleClose={detailsPopup}
          isOpen={isOpenDetails}
          handleSubmit={detailsFormPopup}
          showSubmitButton={true}
          headerText={<b>Event Notes</b>}
          submitButtonText={loading ? "Loading..." : "New Note"}
        />
      )}

      {isOpenDetailsForm && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNoteSubmit}>
                <div className="popupContainer space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Category
                    </label>
                    <div
                      onClick={() => {
                        setIsNoteCategoryModalOpen(true);
                        setIsOpenDetailsForm(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {trainingNewNoteSubjectId
                        ? eventNoteOptions.find(
                            (opt) => opt.value === trainingNewNoteSubjectId
                          )?.label || "Add Category"
                        : "Add Category"}
                    </div>{" "}
                    {errors.trainingNewNoteSubjectId && (
                      <div className="errorInputLabel">
                        {errors.trainingNewNoteSubjectId}
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Select Members Optional
                    </label>
                    <div
                      onClick={() => {
                        setIsNoteMemberModalOpen(true);
                        setIsOpenDetailsForm(false);
                      }}
                      className="mt-1 w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm text-gray-700 focus:ring-2 focus:ring-custom-blue"
                    >
                      {correctedMebersTags.length
                        ? correctedMebersTags
                            .map((val) => {
                              const foundOption = eventNoteMemberList.find(
                                (o) => String(o.value) === String(val)
                              );
                              // If we found an option, show its label; else show val or ""
                              return foundOption ? foundOption.label : "";
                            })
                            .join(", ")
                        : "Add Member"}
                    </div>{" "}
                    {errors.signMembers && (
                      <div className="errorInputLabel">
                        {errors.signMembers}
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Text
                    </label>
                    <TextareaAutosize
                      onChange={(e) => setTrainingNewNote(e.target.value)}
                      className="mt-1 w-full min-h-[40px] rounded-md border border-gray-300 p-2 text-sm focus:ring-2 focus:ring-custom-blue appearance-none"
                      maxRows={10}
                      type="text"
                      value={trainingNewNote}
                      placeholder=""
                    />
                  </div>
                  {errors.trainingNewNote && (
                    <div className="errorInputLabel">
                      {errors.trainingNewNote}
                    </div>
                  )}
                </div>
              </form>
            </>
          }
          handleClose={detailsFormPopup}
          isOpen={isOpenDetailsForm}
          handleSubmit={handleNoteSubmit}
          showSubmitButton={true}
          headerText={<b>Event Note</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      <SelectionModal
        isOpen={isNoteMemberModalOpenNew}
        onClose={() => {
          setIsNoteMemberModalOpen(false);
          setIsOpenDetailsForm(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          const cleanTags = Array.isArray(tags)
            ? tags.filter((tag) => tag != null)
            : tags;
          setAddNoteMember(cleanTags);

          setIsNoteMemberModalOpen(false);
          //   setIsOpenDetailsForm(true); // Reopen the parent popup
        }}
        options={eventNoteMemberList.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Note Member"
        multiple
        defaultValue={defaultNoteMebersSelection}
      />

      <SelectionModal
        isOpen={isNoteCategoryModalOpen}
        onClose={() => {
          setIsNoteCategoryModalOpen(false);
          setIsOpenDetailsForm(true); // Reopen the parent popup
        }}
        onConfirm={(tags) => {
          setTrainingNewNoteSubjectId(tags);

          setIsNoteCategoryModalOpen(false);
          //    setIsOpenDetailsForm(true); // Reopen the parent popup
        }}
        options={eventNoteOptions.map((o) =>
          typeof o === "string" ? o : { label: o.label, value: o.value }
        )}
        label="Select Category"
        defaultValue={trainingNewNoteSubjectId}
      />

      {isOpenNoteRemove && (
        <PopupWeb
          content={
            <>
              <div className="emptySpace22"></div>
              <div className="deleteInfoApparatusContainer">
                <b>This will delete the current note</b>
              </div>
            </>
          }
          handleClose={toggleNoteRemove}
          isOpen={isOpenNoteRemove}
          showSubmitButton={true}
          headerText={<b>Delete Note</b>}
          handleSubmit={handleNoteDelete}
          submitButtonText={loading ? "Loading..." : "Delete Note"}
        />
      )}

      {/** Render other components */}
      {memberPopupOpen && <TrainingSignIn />}
      {itemPopupOpen && <TrainingItems />}
      {photoPopupOpen && <TrainingPhotos />}

      <ToastNotifier />
    </>
  );
};

export default TrainingHeader;
